/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/**
 * Manages the PouchDB store, which holds information that's ready to submit to the GraphQL service.
 * Can save form data to the store, and populate form data using the contents of the store.
 * The format of data in the store is essentially a set of GraphQL query parameters.
 */
import PouchDB from 'pouchdb';

class LocalDbService {
  constructor() {
    this.db = new PouchDB('sma');
  }

  // eslint-disable-next-line class-methods-use-this
  init() {
    // eslint-disable-next-line no-console
    console.log('Initializing PouchDB Service');
  }

  saveSubmittable(doc) {
    if (!doc.submittable_type || !doc.name || !doc.date_time) throw new Error('Submittable is missing name or submittable_type');

    doc._id = `${doc.name}__${new Date().toISOString()}`;
    // var formVals = getFormValues(formKey)(store.getState());
    return this.db.put({
      _id: doc._id,
      ...doc,
    });
  }

  saveWithRev(doc) {
    if (!doc.submittable_type || !doc.name || !doc.date_time) throw new Error('Submittable is missing name or submittable_type');

    return this.db.put({
      _id: doc._id,
      _rev: doc._rev,
      ...doc,
    });
  }

  editSubmittable(doc) {
    if (!doc.submittable_type || !doc.name || !doc.date_time) throw new Error('Submittable is missing name or submittable_type');

    doc.edit_time = new Date().toISOString();

    return this.db.put({
      _id: doc._id,
      _rev: doc._rev,
      ...doc,
    });
  }

  get(id) {
    return this.db.get(id);
  }

  delete(id) {
    return this.db.get(id).then((doc) => this.db.remove(doc));
  }

  // do bulk operations on a set of documents.
  // read pouchDB docs for more info:
  // https://pouchdb.com/api.html#batch_create
  bulk(array) {
    return this.db.bulkDocs(array).then((result) => result).catch((err) => err);
  }

  list() {
    return this.db.allDocs({ include_docs: true, descending: true }).then((result) => result);
  }
}

export default new LocalDbService();
