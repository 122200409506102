import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DbCacheContext = createContext({
  getDbCache: () => 'context not defined',
  cacheOffline: () => 'context not defined',
  changeSyncInfo: () => 'context not defined',
});

function DbCacheProvider({ children }) {
  // eslint-disable-next-line no-undef
  const lastSync = localStorage.getItem('smaLastSync');
  const [dbCache, setDbCache] = useState({ lastSync: lastSync || new Date().toLocaleString() });

  const getDbCache = () => dbCache;
  const cacheOffline = (payload) => {
    const actionState = dbCache[payload.key]
      ? dbCache[payload.key].data
      : null;
    setDbCache({
      ...dbCache,
      [payload.key]: {
        data: payload.value
          ? payload.value
          : actionState,
        loadingState: payload.loadingState,
      },
    });
  };

  const changeSyncInfo = (payload) => {
    setDbCache({
      ...dbCache,
      lastSync: payload.lastSync !== undefined
        ? payload.lastSync
        : dbCache.lastSync,
      loadingState: payload.loadingState !== undefined
        ? payload.loadingState : dbCache.loadingState,
    });
  };

  return (
    <DbCacheContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getDbCache,
        cacheOffline,
        changeSyncInfo,
      }}
    >
      {children}
    </DbCacheContext.Provider>
  );
}

DbCacheProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DbCacheProvider;
