import React, { useState } from 'react';
// import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import './atComments.css';
import useFormProvider from '../../../hooks/useFormProvider';

// This component is a button with a popup modal.
function NewAtComment() {
  const { getFormValue, changeForm } = useFormProvider();
  const [show, setShow] = useState(false);
  const [atCommentsText, setAtComments] = useState('');
  const [atCommentDepth, setAtCommentDepth] = useState('');

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setAtComments('');
    setAtCommentDepth('');
    setShow(true);
  };

  const addAtComment = () => {
    const newAtComments = getFormValue('atComments');
    newAtComments.push({
      depth: atCommentDepth,
      comment: atCommentsText,
    });
    changeForm('atComments', newAtComments);
    setShow(false);
  };

  const disabled = atCommentDepth.length === 0 || atCommentsText.length === 0;

  return (
    <div>
      <button
        className="addAtComment"
        onClick={handleShow}
        type="button"
      >
        Add @ Comment
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add @ Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="addAtCommentArea">
          <TextField
            id="depth-filled-basic"
            label="Depth"
            variant="filled"
            type="number"
            value={atCommentDepth}
            className="atCommentInput"
            onChange={(event) => setAtCommentDepth(event.target.value)}
          />
          <TextField
            id="Comment-filled-basic"
            label="Comment"
            variant="filled"
            type="number"
            multiline
            className="atCommentInput"
            value={atCommentsText}
            onChange={(event) => setAtComments(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            className="atCommentCancelButton"
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
          >
            Cancel
          </Button>
          <Button
            bsStyle="primary"
            disabled={disabled}
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
            onClick={addAtComment}
            className="AtCommentButton"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewAtComment;
