export default (interval, smaSubId) => ({
  smaSubmissionId: smaSubId,
  intervalTop: parseFloat(interval.intervalTop),
  intervalBottom: parseFloat(interval.intervalBottom),
  intervalComment: interval.intervalComment,
  plasticity: interval.plasticity,
  moisture: interval.moisture,
  color: interval.colour,
  color1: interval.colour1,
  color2: interval.colour2,
  color3: interval.colour3,
  consistence: interval.consistence,
  class_schema: interval.class_schema,
  fragmentShape: interval.fragmentShape,
  fragmentModifier: interval.fragmentModifier,
  fragmentFragment: interval.fragmentFragment,
  fragmentAdjective: interval.fragmentAdjective,
  fragmentTexture: interval.fragmentTexture,
  soilHorizon: interval.soilHorizon,
  mottleAbundance: interval.mottleAbundance,
  mottleSize: interval.mottleSize,
  mottleColor: interval.mottleColour,
  mottleContrast: interval.mottleContrast,
  mottleType: interval.mottleType,
  deposition: interval.deposition,
  primaryConstituent: interval.primaryConstituent,
  primarySize: interval.primarySize,
  secondaryConstituent: interval.secondaryConstituent,
  secondaryProportion: interval.secondaryProportion,
  tertiaryConstituent: interval.tertiaryConstituent,
  tertiaryProportion: interval.tertiaryProportion,
  grading: interval.grading,
  angularity: interval.angularity,
  odour: interval.odour,
  primarySoil: interval.primarySoil,
  observation: interval.observation,
  geneticTerms: interval.geneticTerms,
  texture: interval.texture,
  structure: interval.structure,
  mottleSelect: interval.mottleSelect,
  soilFractures: interval.soilFractures,
  soilFragments: interval.soilFragments,
  soilFragments_modifier: interval.soilFragmentsModifier,
  soilDisturbances: interval.soilDisturbances,
});
