import React from 'react';
import LoginIcon from '@mui/icons-material/Login';

import useAuthProvider from '../hooks/useAuthProvider';
import useWebService from '../hooks/useWebService';

import './Logout.css';

function Logout() {
  const { logout } = useWebService();
  const { getUserInfo } = useAuthProvider();
  const auth = getUserInfo();

  return (auth?.authenticated
    ? (
      <button type="button" onClick={logout} className="logoutButton">
        {`Logout ${auth.user.name} `}
        <LoginIcon />
      </button>
    )
    : <a href="/login">Login</a>);
}

export default Logout;
