import gql from 'graphql-tag';

export const getSiteAndData = gql`
  query getClientSiteProjectInfo {
    getClients {
      id
      name
    }
    getProjects {
      id
      name
      clientId
    }
    getSites {
      id
      uwi
      projectId
      name
      manual
      siteStatus
      provinceId
      wellLicense
      comments
      latitude
      longitude
    }
  }
`;

export const updateSite = gql`
  mutation updateSiteMutation(
    $projectId: ID,
    $name: String!,
    $manual: Boolean,
    $latitude: Float,
    $longitude: Float,
    $provinceId: Int,
    $wellLicense: String,
    $uwi: String,
    $comments: String,
    $siteStatus: String,
    $id: ID!,
    ) {
    updateSite(
      site: {
        projectId: $projectId
        name: $name,
        manual: $manual,
        siteStatus: $siteStatus,
        wellLicense: $wellLicense,
        provinceId: $provinceId,
        comments: $comments,
        latitude: $latitude,
        longitude: $longitude,
        uwi: $uwi
      }
      id: $id,
    )
  }
`;

export const createSiteQuery = gql`
  mutation createSiteMutation(
    $projectId: ID,
    $name: String!,
    $manual: Boolean,
    $latitude: Float,
    $longitude: Float,
    $provinceId: Int,
    $wellLicense: String,
    $uwi: String,
    $siteStatus: String,
    $comments: String,
    ) {
    createSite(
      site: {
        projectId: $projectId
        name: $name,
        siteStatus: $siteStatus,
        manual: $manual,
        wellLicense: $wellLicense,
        provinceId: $provinceId,
        comments: $comments,
        latitude: $latitude,
        longitude: $longitude,
        uwi: $uwi
      }
    )
  }
`;
