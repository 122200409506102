import gql from 'graphql-tag';

export const getClientsQuery = gql`
  query getClientsQuery{
    getClients {
      id
      name
    }
  }
`;

export const getSitesQuery = gql`
  query getSitesQuery{
    getSites {
      id
      uwi
      projectId
      name
      manual
      siteStatus
      provinceId
      wellLicense
      comments
      latitude
      longitude
    }
  }
`;

export const getProjectsQuery = gql`
  query getProjectsQuery{
    getProjects {
      id
      name
      clientId
    }
  }
`;

export const getAPECsQuery = gql`
  query getAPECsQuery {
    getApecsBy (manual: false) {
      id
      name
      manual
    }
  }
`;
