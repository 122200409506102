import gql from 'graphql-tag';

export const getUsersAndData = gql`
  query getSmaUsersAndData {
    getSmaUsers {
      id
      email
      roleId
      consultantId
      clientId
    }
    getConsultants {
      id
      name
    }
    getClients {
      id
      name
    }
  }
`;

export const createUserQuery = gql`
  mutation createUserMutation (
    $email: String!,
    $password: String!,
    $role_id: Int!,
    $consultant_id: ID,
    $client_id: ID,
  ) {
    createSmaUser(
      user: {
        email: $email,
        password: $password,
        role_id: $role_id,
        consultant_id: $consultant_id,
        client_id: $client_id,
      }
    )
  }
`;
