import { useContext } from 'react';
import { OfflineServiceContext } from '../Providers/offlineServiceProvider';

export default () => {
  const {
    cache,
    cacheUserSites,
    cacheSitesAndProjects,
    cacheAll,
    cacheAllThrottled,
    stopSyncInterval,
    startSyncInterval,
    saveLoginInformation,
    saveOrUpdateSubmittable,
    getValue,
    getUserSites,
  } = useContext(OfflineServiceContext);
  return {
    cache,
    cacheUserSites,
    cacheSitesAndProjects,
    cacheAll,
    cacheAllThrottled,
    stopSyncInterval,
    startSyncInterval,
    saveLoginInformation,
    saveOrUpdateSubmittable,
    getValue,
    getUserSites,
  };
};
