import React from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import ActiveTabProvider from './Providers/activeTabProvider';
import AuthProvider from './Providers/authProvider';
import BoreholeListProvider from './Providers/boreholeListProvider';
import DbCacheProvider from './Providers/dbCacheProvider';
import EditFormProvider from './Providers/editFormProvider';
import EditIntervalProvider from './Providers/editIntervalProvider';
import FormProvider from './Providers/formProvider';
import HashProvider from './Providers/hashProvider';
import LoadingProvider from './Providers/loadingProvider';
import LocationProvider from './Providers/locationProvider';
import ModalProvider from './Providers/modalProvider';
import ReviewSiteProvider from './Providers/reviewSiteProvider';
import SidePanelOpenProvider from './Providers/sidePanelOpenProvider';
import SiteForReviewProvider from './Providers/siteForReviewProvider';
import WebserviceProvider from './Providers/webserviceProvider';
import OfflineServiceProvider from './Providers/offlineServiceProvider';
import IntervalDescriptionForm from './Providers/intervalDescriptionFormProvider';
import ErrorsProvider from './Providers/errorsProvider';

function Providers({ children }) {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <ActiveTabProvider>
          <DbCacheProvider>
            <OfflineServiceProvider>
              <BoreholeListProvider>
                <AuthProvider>
                  <EditFormProvider>
                    <EditIntervalProvider>
                      <FormProvider>
                        <HashProvider>
                          <LoadingProvider>
                            <LocationProvider>
                              <ModalProvider>
                                <ReviewSiteProvider>
                                  <SidePanelOpenProvider>
                                    <SiteForReviewProvider>
                                      <WebserviceProvider>
                                        <IntervalDescriptionForm>
                                          <ErrorsProvider>
                                            {children}
                                          </ErrorsProvider>
                                        </IntervalDescriptionForm>
                                      </WebserviceProvider>
                                    </SiteForReviewProvider>
                                  </SidePanelOpenProvider>
                                </ReviewSiteProvider>
                              </ModalProvider>
                            </LocationProvider>
                          </LoadingProvider>
                        </HashProvider>
                      </FormProvider>
                    </EditIntervalProvider>
                  </EditFormProvider>
                </AuthProvider>
              </BoreholeListProvider>
            </OfflineServiceProvider>
          </DbCacheProvider>
        </ActiveTabProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
