import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Delete } from '@mui/icons-material';
import useFormProvider from '../../../hooks/useFormProvider';
import './FieldGrid.css';

function FieldSampleItem({
  item,
  index,
}) {
  const { getFormValue, changeForm } = useFormProvider();
  const [sampleRow, setSample] = useState(getFormValue('sampleCollection')[index]);

  const onFieldChange = (field, value) => {
    const newSampleCollection = [...getFormValue('sampleCollection')];
    newSampleCollection[index][field] = value;
    setSample(newSampleCollection[index]);
    changeForm('sampleCollection', newSampleCollection);
  };

  const onDelete = () => {
    const newSampleCollection = [...getFormValue('sampleCollection')];
    newSampleCollection.splice(index, 1);
    changeForm('sampleCollection', newSampleCollection);
  };

  return (
    <div className="fieldSampleItem">
      <div className="depthRangeArea">
        <span>{`Depth Range for ${sampleRow.sample}`}</span>
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`depth-top-label-${index}`}
            label="Top"
            name={`${item}.depthTop`}
            type="number"
            value={sampleRow.depthTop}
            onChange={(event) => onFieldChange('depthTop', event.target.value)}
            required
          />
        </FormControl>
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`depth-bottom-label-${index}`}
            label="Bottom"
            name={`${item}.depthBottom`}
            type="number"
            value={sampleRow.depthBottom}
            onChange={(event) => onFieldChange('depthBottom', event.target.value)}
          />
        </FormControl>
      </div>
      <div className="fieldMeasurementsArea">
        <FormControl className="formControlSampleItem">
          <TextField
            labelId="ec-label"
            name={`${item}.ec`}
            label="EC (dS/m)"
            type="number"
            value={sampleRow.ec}
            onChange={(event) => onFieldChange('ec', event.target.value)}
          />
        </FormControl>
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`ova-label-${index}`}
            label="VOC (ppm)"
            name={`${item}.ova`}
            type="number"
            value={sampleRow.ova}
            onChange={(event) => onFieldChange('ova', event.target.value)}
          />
        </FormControl>
      </div>
      <div className="sampleJarsArea">
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`bag-label-${index}`}
            label="Bag"
            name={`${item}.bag`}
            type="number"
            value={sampleRow.bag}
            onChange={(event) => onFieldChange('bag', event.target.value)}
          />
        </FormControl>
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`jar-label-${index}`}
            label="Jar"
            name={`${item}.jar`}
            type="number"
            value={sampleRow.jar}
            onChange={(event) => onFieldChange('jar', event.target.value)}
          />
        </FormControl>
      </div>
      <div className="sampleJarsArea2">
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`vial-label-${index}`}
            label="Vial"
            name={`${item}.vial`}
            type="number"
            value={sampleRow.vial}
            onChange={(event) => onFieldChange('vial', event.target.value)}
          />
        </FormControl>
        <FormControl className="formControlSampleItem">
          <span
            id={`lab-label-${index}`}
            className="labLabel"
          >
            Lab
          </span>
          <Checkbox
            labelId={`lab-label-${index}`}
            label="Lab"
            checked={sampleRow.lab}
            name={`${item}.lab`}
            onChange={(event) => {
              onFieldChange('lab', event.target.checked);
            }}
          />
        </FormControl>
      </div>
      <div className="commentArea">
        <Delete
          onClick={() => { onDelete(); }}
          style={{ color: 'black', cursor: 'pointer', 'margin-left': 'calc(100% - 30px)' }}
        />
        <FormControl className="formControlSampleItem">
          <TextField
            labelId={`comment-label-${index}`}
            label="Comment"
            name={`${item}.comment`}
            type="text"
            value={sampleRow.comment}
            multiline
            onChange={(event) => onFieldChange('comment', event.target.value)}
          />
        </FormControl>
      </div>
    </div>
  );
}

FieldSampleItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({}).isRequired,
};

export default FieldSampleItem;
