/* eslint-disable no-await-in-loop */
export default async ({
  sampleLocations,
  locationId,
  mutate,
  isSubmissionEdit,
  deleteFieldSamplesNotInList,
  updateFieldSampleQuery,
  fieldSampleQuery,
}) => {
  // Submit Field Samples.
  const fieldSampleIds = [];

  for (let i = 0; i < sampleLocations.length; i += 1) {
    const fieldsampleVar = {
      sampleid: sampleLocations[i].sample.toString(),
      sampleLocationId: locationId,
      comment: sampleLocations[i].comment,
      depthTop: parseFloat(sampleLocations[i].depthTop),
      depthBottom: parseFloat(sampleLocations[i].depthBottom),
      ec: parseFloat(sampleLocations[i].ec),
      ova: parseFloat(sampleLocations[i].ova),
      bag: parseInt(sampleLocations[i].bag, 10),
      jar: parseInt(sampleLocations[i].jar, 10),
      vial: parseInt(sampleLocations[i].vial, 10),
      lab: sampleLocations[i].lab || false,
    };
    // Add the sample.
    const fieldSampleResponse = isSubmissionEdit && sampleLocations[i].id
      ? await mutate(updateFieldSampleQuery, { ...fieldsampleVar, id: sampleLocations[i].id })
      : await mutate(fieldSampleQuery, fieldsampleVar);

    if (!fieldSampleResponse.data) {
      throw new Error('Field Sample submission failed');
    }
    if (isSubmissionEdit && sampleLocations[i].id) {
      fieldSampleIds.push(fieldSampleResponse.data.updateFieldSample);
    } else {
      fieldSampleIds.push(fieldSampleResponse.data.createFieldSample);
    }
  }

  if (fieldSampleIds.length !== sampleLocations.length) {
    throw new Error('Field Sample submissions don\'t match the number of samples submitted');
  }

  await mutate(
    deleteFieldSamplesNotInList,
    { ids: fieldSampleIds, sampleLocationId: locationId },
  );
};
