/* eslint-disable no-param-reassign */
import React, { createContext, useState, useEffect } from 'react';
import UtmLatlng from 'utm-latlng';
import PropTypes from 'prop-types';

import { BoreholeUtility } from '../logic/BoreholeUtility';

export const FormContext = createContext({
  getForm: () => 'context not defined',
  changeForm: () => 'context not defined',
  getFormValue: () => 'context not defined',
  clearForm: () => 'context not defined',
  validateDescriptionForm: () => 'context not defined',
  runWarnings: () => 'context not defined',
  validateFields: () => 'context not defined',
  getErrors: () => 'context not defined',
  changeFormMultiple: () => 'context not defined',
  initialize: () => 'context not defined',
  duplicate: () => 'context not defined',
});

function FormProvider({ children }) {
  const defaultForm = {
    classSchema: 'schema_cssc',
    atComments: [],
    latitude: 0,
    longitude: 0,
    utmeasting: 0,
    utmnorthing: 0,
    zone: 0,
    gpsAccuracy: 0,
    gpsCount: 0,
    gpsUsedInFix: 0,
    gpsSource: 0,
    sampleLocDatetime: '',
    userSite: '',
    siteName: '',
    site: '',
    intervals: [],
    apecName: '',
    userApec: '',
    apec: '',
    logger: '',
    project: '',
    client: '',
    drillingMethod: '',
    drillingName: '',
    workContextComment: '',
    borehole: BoreholeUtility.defaultBoreholeName(),
    impacted: false,
    windDirection: '',
    boreholeComment: '',
    weatherDescription: '',
    windSpeed: '',
    temperature: '',
    precipitation: '',
    sampleCollection: [],
  };

  const saveInProgress = async (form) => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('inProgress', JSON.stringify(form));
  };

  // eslint-disable-next-line no-undef
  const getInProgress = async () => JSON.parse(localStorage.getItem('inProgress'));

  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const clearForm = () => setForm(defaultForm);
  const getForm = () => form;
  const getFormValue = (key) => form[key];

  const validateIntervals = (newErrors, newForm) => {
    newForm.intervals.forEach((interval) => {
      if (!interval.intervalTop || interval.intervalTop === undefined) {
        if (interval.intervalTop === 0) {
          return;
        }
        newErrors.intervalTop = 'Interval Top';
        return;
      }
      if (!interval.intervalBottom || interval.intervalBottom === undefined) {
        newErrors.intervalBottom = 'Interval Top';
        return;
      }
      if (!interval.fragmentTexture || interval.fragmentTexture === undefined) {
        newErrors.fragmentTexture = 'Texture';
      }
      if (interval.intervalComment) {
        delete newErrors.fragmentTexture;
      }
    });
  };

  const validateFieldSamplesGrid = (newForm) => {
    const errorsLocal = [];
    const warnings = [];
    newForm.sampleCollection.forEach((value, idx) => {
      const sampleCollection = {};
      if ((!value.sample || value.sample === undefined)
        && (!value.number || value.number === undefined)
        && (!value.id || value.id === undefined)) {
        sampleCollection.sampleId = 'Field Sample Id(s)';
        errorsLocal[idx] = sampleCollection;
      }

      if (!value.sample || value.sample === undefined) {
        sampleCollection.sample = 'Field Sample Name(s)';
        errorsLocal[idx] = sampleCollection;
      }

      const depth = parseFloat(value.depthTop);
      if (!depth || depth === undefined) {
        if (depth !== 0) {
          sampleCollection.depthTop = 'Field Sample Depth Top(s)';
          errorsLocal[idx] = sampleCollection;
        }
      }
    });
    return [errorsLocal, warnings];
  };

  const intervalGapValidation = (newForm) => {
    let errorMessage = '';

    const data = [];
    if (newForm.intervals.length > 0) {
      // create tuple
      newForm.intervals.forEach((val, idx) => {
        data[idx] = [parseFloat(val.intervalTop), parseFloat(val.intervalBottom)];
      });
      // sort array
      data.sort((a, b) => (a[1] > b[1] ? 1 : -1));
      // get gaps
      const gaps = [];
      for (let i = 0; i < data.length - 1; i += 1) {
        gaps[i] = data[i + 1][0] - data[i][1];
      }
      // check to make sure interval list starts at 0
      if (data[0][0] !== 0) {
        errorMessage += 'Interval List needs to start at 0m';
      }
      // remove duplicates
      const noDuplicates = new Set(gaps);

      // back to array
      const noDupsArray = [...noDuplicates];

      if (noDupsArray.length === 1) {
        if (noDupsArray[0] !== 0) {
          errorMessage += `${errorMessage.length !== 0 ? ', ' : ''}Interval List has gaps or overlapping values`;
        }
      }
      if (noDupsArray.length > 1) {
        errorMessage += `${errorMessage.length !== 0 ? ', ' : ''}Interval List has gaps or overlapping values`;
      }
    } else {
      // If no intervals have been added
      errorMessage = 'Interval missing (please create at least one soil interval)';
    }
    return errorMessage;
  };

  const validateFields = (isReview = false, formToCheck = form) => {
    const newErrors = {};

    Object.keys(formToCheck).forEach((key) => {
      if (formToCheck[key] && formToCheck[key]?.length > 254) {
        newErrors[key] = `The length of ${key.replaceAll('_', ' ')} is too long. You need to limit it to 255 characters.`;
      }
    });
    if (!formToCheck.logger || formToCheck.logger === undefined) newErrors.logger = 'Logger';
    if (!formToCheck.client || formToCheck.client === undefined) newErrors.client = 'Client';
    if (!formToCheck.project || formToCheck.project === undefined) newErrors.project = 'Project';
    if (!formToCheck.site || formToCheck.site === undefined) newErrors.site = 'Site';
    if (!formToCheck.drillingMethod || formToCheck.drillingMethod === undefined) newErrors.drillingMethod = 'Drilling Method';
    if (!formToCheck.drillingName || formToCheck.drillingName === undefined) newErrors.drillingName = 'Drilling Company Name';
    if (!formToCheck.userSite || formToCheck.userSite === undefined) {
      newErrors.userSite = 'User Generated Site';
    }

    if (formToCheck.site) delete newErrors.userSite;
    if (formToCheck.userSite) delete newErrors.site;
    if (!formToCheck.borehole || formToCheck.borehole === undefined) newErrors.borehole = 'Borehole is required';
    if (!formToCheck.latitude || formToCheck.latitude === undefined) newErrors.latitude = 'Latitude is required';
    if (!formToCheck.longitude || formToCheck.longitude === undefined) newErrors.longitude = 'Longitude is required';

    if ((formToCheck.latitude && parseFloat(formToCheck.latitude) < 48)
    || (formToCheck.latitude && parseFloat(formToCheck.latitude) > 60)) {
      newErrors.latitude = 'Latitude between 48 and 60 degrees required';
    }

    if ((formToCheck.longitude && parseFloat(formToCheck.longitude) < -126)
      || (formToCheck.longitude && parseFloat(formToCheck.longitude) > -100)) {
      newErrors.longitude = 'Longitude between -126 and -100 degrees required';
    }

    if (formToCheck?.intervals !== undefined) {
      const gaptemp = intervalGapValidation(formToCheck);
      if (gaptemp !== '') {
        newErrors.intervalGaps = gaptemp;
      }
    }

    if (formToCheck.intervals?.length === 0) {
      newErrors.intervalDescriptionForm = 'Interval Description Form Requires at least one interval';
    }

    if (isReview) {
      if (formToCheck.intervals !== undefined) {
        if (formToCheck.intervals?.length > 0) {
          const intervalErrors = formToCheck.intervals.map(() => '');
          newErrors.intervals = intervalErrors;
          validateIntervals(intervalErrors, formToCheck);
        }
        if (newErrors.intervals?.length !== 0) {
          newErrors.intervalMessage = 'Interval List Data Missing';
        }
      }
    }

    let sampleCollectionErrors = {}; let
      sampleCollectionWarnings = {};
    if (formToCheck.sampleCollection) {
      if (Object.keys(formToCheck.sampleCollection).length > 0) {
        // eslint-disable-next-line no-unused-vars
        [sampleCollectionErrors, sampleCollectionWarnings] = validateFieldSamplesGrid(formToCheck);
      }

      if (Object.keys(sampleCollectionErrors).length !== 0) {
        newErrors.sampleCollection = sampleCollectionErrors;
        newErrors.sampleCollectionMessage = ' on the Sampling Grid, a sample interval has a blank Top Depth Top depth to have been filled out';
      }
    }
    setErrors(newErrors);
  };

  const getErrors = () => errors;

  const runWarnings = ({ allValues, rowNumber }) => {
    const warnings = 'Should have at least one of EC, VOC, Bag, Jar, Vial';
    if (!allValues) return warnings;
    const rowInfo = allValues.sampleCollection[rowNumber];
    if (!rowInfo) return null;

    if (!rowInfo?.ec
      && !rowInfo?.ova
      && !rowInfo?.bag
      && !rowInfo?.jar
      && !rowInfo?.vial) {
      return warnings;
    }
    return null;
  };

  const changeFormMultiple = (formUpdates) => {
    let newForm = {};
    newForm = {
      ...form,
    };
    if (formUpdates.intervals) {
      newForm.intervals = formUpdates.intervals;
    }
    formUpdates.forEach((update) => {
      newForm[update.field] = update.newVal;
    });
    setForm(newForm);
    saveInProgress(newForm);
    validateFields(false, newForm);
  };

  const changeForm = (field, newVal) => {
    let newForm = {};
    if (field === 'latitude' || field === 'longitude') {
      const utmObj = new UtmLatlng();
      const utmGenerated = utmObj.convertLatLngToUtm(
        field === 'latitude' ? newVal : form.latitude,
        field === 'longitude' ? newVal : form.longitude,
        22,
      );
      const utmContent = {
        utmeasting: utmGenerated.Easting,
        utmnorthing: utmGenerated.Northing,
        zone: utmGenerated.ZoneNumber,
        gpsAccuracy: 22,
      };
      newForm = {
        ...utmContent,
      };
    }
    newForm = {
      ...form,
      ...newForm,
    };
    newForm[field] = newVal;
    setForm(newForm);
    saveInProgress(newForm);
    validateFields(false, newForm);
  };

  const fillUtm = (lat, lon) => {
    if (lat === 0 || lon === 0) return {};
    const utmObj = new UtmLatlng();
    const utmGenerated = utmObj.convertLatLngToUtm(lat, lon, 22);
    return {
      utmeasting: utmGenerated.Easting,
      utmnorthing: utmGenerated.Northing,
      zone: utmGenerated.ZoneNumber,
      gpsAccuracy: 22,
    };
  };

  const initialize = (newForm) => {
    let formToBePut = {};
    if (newForm) {
      formToBePut = JSON.parse(JSON.stringify(newForm));
    } else {
      formToBePut = { ...defaultForm };
    }
    // eslint-disable-next-line no-unused-vars
    const utmObj = fillUtm(formToBePut.latitude, formToBePut.longitude);
    formToBePut = {
      ...formToBePut,
      ...utmObj,
    };
    setForm(formToBePut);
    saveInProgress(formToBePut);
    validateFields(false, formToBePut);
  };

  useEffect(async () => {
    getInProgress().then((inProgress) => {
      if (inProgress) {
        setForm(inProgress);
        validateFields(false, inProgress);
      }
    });
  }, []);

  const duplicate = (oldSubmissionObject) => {
    const duplicateObject = JSON.parse(JSON.stringify(oldSubmissionObject));
    duplicateObject.borehole = BoreholeUtility.defaultBoreholeName();
    duplicateObject.boreholeComment = '';
    duplicateObject.atComments = [];

    delete duplicateObject.latitude;
    delete duplicateObject.longitude;
    delete duplicateObject.sampleLocationId;
    delete duplicateObject.isSubmissionEdit;

    // eslint-disable-next-line no-underscore-dangle
    duplicateObject._id = '';
    // eslint-disable-next-line no-underscore-dangle
    duplicateObject._rev = '';
    duplicateObject.sampleLocDatetime = '';
    duplicateObject.utmeasting = '';
    duplicateObject.utmnorthing = '';
    duplicateObject.zone = '';
    duplicateObject.gpsAccuracy = '';
    duplicateObject.gpsSource = '';
    duplicateObject.gpsCount = '';
    duplicateObject.gpsUsedInFix = '';
    duplicateObject.editTime = '';
    duplicateObject.dateTime = '';
    duplicateObject.submitted = '';
    duplicateObject.intervalDepthForm = {};
    duplicateObject.intervalDescriptionForm = {};
    duplicateObject.atComments = [];
    duplicateObject.submissionId = undefined;

    for (let i = 0; i < duplicateObject.intervals.length; i += 1) {
      duplicateObject.intervals[i].intervalComment = '';
      duplicateObject.intervals[i].id = undefined;
      duplicateObject.intervals[i].tempId = Math.random().toString().slice(2, 15);
      duplicateObject.intervals[i].smaSubmissionId = undefined;
      duplicateObject.intervals[i].sma_submission_id = undefined;
    }
    for (let i = 0; i < duplicateObject.sampleCollection.length; i += 1) {
      duplicateObject.sampleCollection[i].id = undefined;
      duplicateObject.sampleCollection[i].ec = null;
      duplicateObject.sampleCollection[i].ova = null;
      duplicateObject.sampleCollection[i].bag = null;
      duplicateObject.sampleCollection[i].jar = null;
      duplicateObject.sampleCollection[i].vial = null;
      duplicateObject.sampleCollection[i].lab = null;
      duplicateObject.sampleCollection[i].comment = null;
    }
    initialize(duplicateObject);
  };

  return (
    <FormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getForm,
        changeForm,
        getFormValue,
        clearForm,
        runWarnings,
        validateFields,
        getErrors,
        changeFormMultiple,
        initialize,
        duplicate,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormProvider;
