import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useFormProvider from '../../hooks/useFormProvider';

function UserSiteInput({
  userSites,
}) {
  const {
    getFormValue,
    getErrors,
    changeFormMultiple,
  } = useFormProvider();
  const errors = getErrors();

  return (
    <div className="UserSiteInput">
      <FormControl error={errors.site} fullWidth marginNormal classes="formControl">
        <InputLabel id="userSite-select-label">User Site</InputLabel>
        <Select
          labelId="userSite-select-label"
          id="userSite-filled-basic"
          label="User Site"
          required
          className="selectFullWidth"
          value={getFormValue('userSite')}
          onChange={(event) => {
            if (!event.target.value) {
              changeFormMultiple([
                { field: 'userSite', newVal: event.target.value },
                { field: 'siteName', newVal: '' },
                { field: 'site', newVal: '' },
              ]);
            } else {
              changeFormMultiple([
                { field: 'userSite', newVal: event.target.value },
                { field: 'siteName', newVal: event.target.value },
                { field: 'site', newVal: '' },
              ]);
            }
          }}
        >
          <MenuItem value="">SELECT</MenuItem>
          {userSites.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((site, optionIdx) => (
              <MenuItem key={site.id ? site.id : optionIdx} value={site.name}>
                {site.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

UserSiteInput.propTypes = {
  userSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default UserSiteInput;
