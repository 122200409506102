/**
 * Contains code that applies to the base layout of the page.
 * Global stylesheets and stuff.
 * Serves as an entry point into the app's code.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import Providers from './Providers';
import App from './App';

// Stylesheets
import 'bootstrap/dist/css/bootstrap.css';
import './assets/index.css';

// eslint-disable-next-line no-undef
const root = createRoot(document.getElementById('root'));
root.render(
  <div className="Main">
    <Providers>
      <App />
    </Providers>
  </div>,
);
