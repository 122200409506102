import { useContext } from 'react';
import { IntervalDescriptionFormContext } from '../Providers/intervalDescriptionFormProvider';

export default () => {
  const {
    clearForm,
    getForm,
    changeForm,
    getFormValue,
    setIntervalForm,
    validateDescriptionForm,
    isInAnotherRange,
    getErrors,
  } = useContext(IntervalDescriptionFormContext);
  return {
    clearForm,
    getForm,
    changeForm,
    getFormValue,
    setIntervalForm,
    validateDescriptionForm,
    isInAnotherRange,
    getErrors,
  };
};
