import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SidePanelOpenContext = createContext({
  getSidePanelOpen: () => 'context not defined',
  toggleSidePanel: () => 'context not defined',
});

function SidePanelOpenProvider({ children }) {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const getSidePanelOpen = () => sidePanelOpen;
  const toggleSidePanel = () => setSidePanelOpen(!sidePanelOpen);

  return (
    <SidePanelOpenContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getSidePanelOpen,
        toggleSidePanel,
      }}
    >
      {children}
    </SidePanelOpenContext.Provider>
  );
}

SidePanelOpenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidePanelOpenProvider;
