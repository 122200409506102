import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ReviewSiteContext = createContext({
  getReviewSite: () => 'context not defined',
  toggleReview: () => 'context not defined',
});

function ReviewSiteProvider({ children }) {
  const [reviewSite, setReviewSite] = useState(false);

  const getReviewSite = () => reviewSite;
  const toggleReview = () => setReviewSite(!reviewSite);

  return (
    <ReviewSiteContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getReviewSite,
        toggleReview,
      }}
    >
      {children}
    </ReviewSiteContext.Provider>
  );
}

ReviewSiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReviewSiteProvider;
