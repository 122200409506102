// An all-purpose loading throbber.
// Taken from spinkit: http://tobiasahlin.com/spinkit/
import React from 'react';
import '../../assets/LoadingSpinner.css';
import PropTypes from 'prop-types';

// eslint-disable-next-line
const LoadingThrobber = (props) => {
  const { centered } = props;
  return (
    <div className={`sk-fading-circle${
      centered
        ? ' LoadingSpinnerCenter'
        : ''
    }`}
    >
      <div className="sk-circle1 sk-circle" />
      <div className="sk-circle2 sk-circle" />
      <div className="sk-circle3 sk-circle" />
      <div className="sk-circle4 sk-circle" />
      <div className="sk-circle5 sk-circle" />
      <div className="sk-circle6 sk-circle" />
      <div className="sk-circle7 sk-circle" />
      <div className="sk-circle8 sk-circle" />
      <div className="sk-circle9 sk-circle" />
      <div className="sk-circle10 sk-circle" />
      <div className="sk-circle11 sk-circle" />
      <div className="sk-circle12 sk-circle" />
    </div>
  );
};

LoadingThrobber.propTypes = {
  centered: PropTypes.bool,
};

LoadingThrobber.defaultProps = {
  centered: false,
};

export default LoadingThrobber;
