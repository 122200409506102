import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { useCookies } from 'react-cookie';

export const AuthContext = createContext({
  getUserInfo: () => 'context not defined',
  getHashInfo: () => 'context not defined',
  handleOfflineUserLogin: () => 'context not defined',
  handleOfflineUserLogout: () => 'context not defined',
});

function AuthProvider({ children }) {
  const [auth, setAuthCookie] = useCookies(['user']);
  const [hash, setHashCookie] = useCookies(['hash']);

  const getUserInfo = () => auth.user;
  const getHashInfo = () => hash.hash;

  // used to handle user
  // logging in while they have no service
  const handleOfflineUserLogin = (user, hashInfo) => {
    const userAuth = {
      user,
      authenticated: true,
      adminAuthenticated: user.roles === 1,
    };
    setAuthCookie('user', userAuth, { path: '/' });
    setHashCookie('hash', hashInfo, { path: '/' });
  };

  // used to handle user
  // logging out while they have no service
  const handleOfflineUserLogout = () => {
    setAuthCookie('user', { user: {}, authenticated: false, adminAuthenticated: false }, { path: '/' });
    setHashCookie('hash', '', { path: '/' });
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getUserInfo,
        getHashInfo,
        handleOfflineUserLogin,
        handleOfflineUserLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
