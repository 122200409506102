import React, { useState } from 'react';
import PreviousSubmissions from '../PreviousSubmissions/PreviousSubmissions';
import UserAdminForm from '../UserAdminForm/UserAdminForm';
import SiteAdminForm from '../SiteAdminForm';
import ProjectAdminForm from '../ProjectAdminForm';
import './AdminPage.css';

const Tabs = {
  Sites: 'Sites',
  Users: 'Users',
  PreviousSubmissions: 'PreviousSubmissions',
  Projects: 'Projects',
};

// function Tab({
//   label,
//   onClick,
// }) {
//   return (
//     <div
//       className="TabButton"
//       onClick={() => onClick(label)}
//     >
//       <span>{label}</span>
//     </div>
//   );
// }

function AdminPage() {
  const [currentTab, setCurrentTab] = useState(Tabs.PreviousSubmissions);

  const onDuplicateEntity = () => {

  };

  const editSubmittedEntity = () => {

  };

  return (
    <div className="AdminPage">
      <span className="AdminPageTitle">ADMIN PAGE</span>
      <div className="TabArea">
        {Object.values(Tabs).map((tab) => (
          <>
            {/* eslint-disable-next-line */ }
            <div
              className={`TabButton ${tab === currentTab ? 'selected' : ''}`}
              onClick={() => setCurrentTab(tab)}
            >
              <span>{tab}</span>
            </div>
          </>
        ))}
      </div>
      <div className="TabContent">
        {currentTab === Tabs.Users && (
          <UserAdminForm />
        )}
        {currentTab === Tabs.Sites && (
          <SiteAdminForm />
        )}
        {currentTab === Tabs.PreviousSubmissions && (
          <div className="Submissions">
            <PreviousSubmissions
              duplicateEntity={onDuplicateEntity}
              editEntity={editSubmittedEntity}
              subPage={{}}
              isAdmin
            />
          </div>
        )}
        {currentTab === Tabs.Projects && (
          <ProjectAdminForm />
        )}
      </div>
    </div>
  );
}

export default AdminPage;
