/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { createQuery, updateQuery, getQuery } from './queries';
import useWebService from '../../hooks/useWebService';

function ProjectAdminForm() {
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [currentProj, setCurrentProj] = useState('create');
  const [clientId, setClientId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreateMode, setIsCreateMode] = useState(true);

  const { getGqlClient } = useWebService();
  const webservice = getGqlClient();

  const fetchCreationData = async () => {
    const queryData = await webservice.query(
      getQuery,
      {},
      { fetchPolicy: 'no-cache' },
    );
    setData(queryData);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onClientChange = (event) => {
    setClientId(event.target.value);
  };

  const clearData = () => {
    setName('');
    setClientId('');
    setErrorMessage('');
    setCurrentProj('');
  };

  const onSetProjectActive = (event) => {
    const newProject = event.target.value;
    if (newProject === 'create') {
      setIsCreateMode(true);
      clearData();
      return;
    }
    setCurrentProj(newProject);
    const project = data.data?.getProjects.find((proj) => proj.id === newProject);
    setIsCreateMode(false);
    setName(project.name);
    setClientId(project.clientId);
  };

  const onSubmitForm = async () => {
    let response;
    if (isCreateMode) {
      response = await webservice.mutate(
        createQuery,
        {
          name,
          clientId,
        },
      );
    } else {
      response = await webservice.mutate(
        updateQuery,
        {
          id: currentProj,
          name,
          clientId,
        },
      );
    }
    if (!response) {
      setErrorMessage('Failed to create/update site');
    } else {
      if (isCreateMode) clearData();
      fetchCreationData();
    }
  };

  useEffect(() => {
    fetchCreationData();
  }, []);

  return (
    <div className="ProjectAdminForm">
      <div className="existingProjectArea">
        <span className="existingProjectLabel">Form use:</span>
        <select className="existingProjectList" onChange={onSetProjectActive} defaultValue={currentProj}>
          <option value="create">Create</option>
          {data.data?.getProjects?.map((project) => (
            <option key={project.id} value={project.id}>{project.name}</option>))}
        </select>
      </div>
      <span className="Header">Project Form</span>
      <table>
        <thead>
          <tr>
            <th className="labelCol">Label</th>
            <th className="inputCol">Input</th>
          </tr>
        </thead>
        <tbody>
          <tr className="clientArea">
            <td>
              <span className="clientLabel">Select Project Client: </span>
            </td>
            <td>
              <select className="clientSelect" value={clientId} onChange={onClientChange}>
                <option value="NONE">Unselected</option>
                {data.data?.getClients?.map((client) => (
                  <option key={client.id} value={client.id}>{client.name}</option>))}
              </select>
            </td>
          </tr>
          <tr className="nameArea">
            <td>
              <span className="nameLabel">Project Name:</span>
            </td>
            <td>
              <input className="nameInput" value={name} onChange={onNameChange} />
            </td>
          </tr>
        </tbody>
      </table>
      <button aria-label="Save" className="submitButtonSiteAdminForm" type="button" onClick={onSubmitForm}>{isCreateMode ? 'Create' : 'Save'}</button>
      <span>{errorMessage}</span>
    </div>
  );
}

export default ProjectAdminForm;
