import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

function AtCommentList({
  atCommentList,
  deleteFunc,
  onEdit,
}) {
  return (
    <ListGroup style={{ maxHeight: 300, overflowY: 'auto' }}>
      {atCommentList.map((item, idx) => (
        <ListGroupItem key={item} style={{ cursor: 'default' }} value={idx}>
          <b>
            {item.depth}
            m:
          </b>
          {' '}
          &quot;
          {item.comment}
          &quot;
          <div style={{ float: 'right' }} hidden={!deleteFunc}>
            <div style={{ marginLeft: '10px' }}>
              <CreateIcon onClick={() => { onEdit(idx); }} style={{ color: 'black', cursor: 'pointer', 'margin-right': '30px' }} />
              <DeleteIcon onClick={() => { deleteFunc(idx); }} style={{ color: 'black', cursor: 'pointer' }} />
            </div>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

AtCommentList.propTypes = {
  atCommentList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteFunc: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};

AtCommentList.defaultProps = {
  deleteFunc: null,
};

export default AtCommentList;
