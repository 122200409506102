/**
 * Webservice definition.
 * Wrapper for any API interactions, including authentication and graphql queries.
 */
import { ApolloClient, InMemoryCache } from '@apollo/client';

class WebService {
  constructor(changeUser, clearUser) {
    // GraphQL Client
    this.gqlClient = new ApolloClient({
      uri: `${process.env.REACT_APP_API_ENDPOINT || ''}/graphql`,
      cache: new InMemoryCache(),
    });
    this.changeUser = changeUser;
    this.clearUser = clearUser;
  }

  // For URL-encoding.
  static queryParams(params) {
    if (!params) return '';
    return Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
  }

  // Handles the result of an API call.
  // Gives a promise returning {response: <data>, body: <data>}
  static handleResult(response) {
    const retVal = { response, body: {} };

    // TODO: Actually do something meaningful with a surprise 401.
    if (response.status === 401 || response.url.indexOf('/api/logout') !== -1) {
      this.clearUser();
    }

    return response.json()
      .then((val) => {
        retVal.body = val;

        // If we're logging in, update the user.
        if (response.status === 200 && response.url.indexOf('/api/login') !== -1) {
          this.changeUser(val);
        }

        return retVal;
      })
      .catch(() => retVal);
  }

  // GQL Query
  query(query, variables, options) {
    return this.gqlClient.query({
      query,
      variables,
      fetchPolicy: options.fetchPolicy,
    }).then((result) => result).catch((error) => error);
  }

  mutate(mutation, variables) {
    return this.gqlClient.mutate({
      mutation,
      variables,
    }).then((result) => result).catch((error) => error);
  }

  // Generic GET. Probably shouldn't be used.
  get(url, params) {
    let urlValue = url;
    if (params) {
      urlValue += (urlValue.indexOf('?') === -1 ? '?' : '&') + this.queryParams(params);
    }

    return fetch(urlValue, {
      method: 'GET',
      credentials: 'include',
    }).then(this.handleResult).catch((err) => err);
  }

  // Generic POST. Probably shouldn't be used.
  post(url, body, params) {
    let urlValue = url;
    if (params) {
      urlValue += (urlValue.indexOf('?') === -1 ? '?' : '&') + this.queryParams(params);
    }

    return fetch(urlValue, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }).then(this.handleResult).catch((err) => err);
  }
}

export default new WebService();
