import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IntervalControls from './intervalControls';
import IntervalChart from './intervals/IntervalChart';
import BoreholeDescription from './BoreholeDescription';
import IntervalForm from './IntervalForm';
import useEditIntervalProvider from '../../hooks/useEditIntervalProvider';
import useIntervalDescriptionFormProvider from '../../hooks/useIntervalDescriptionFormProvider';
import useFormProvider from '../../hooks/useFormProvider';

function CollectionPage(props) {
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditID] = useState(null);
  const [createMode, setCreateMode] = useState(false);
  const { toggleIntervalEdit } = useEditIntervalProvider();
  const { getFormValue, changeForm } = useFormProvider();
  const {
    setIntervalForm,
    clearForm,
  } = useIntervalDescriptionFormProvider();
  const intervalList = getFormValue('intervals');
  const atCommentList = getFormValue('atComments');

  const {
    descriptionSubform,
    depthSubform,
    selectedProject,
    class_schema: classSchema,
  } = props;

  const deleteAllAtComments = () => {
    changeForm('atComments', []);
  };

  const deleteAtComment = (index) => {
    const newAtComments = [...getFormValue('atComments')];
    newAtComments.splice(index, 1);
    changeForm('atComments', newAtComments);
  };

  const deleteAllIntervals = () => {
    changeForm('intervals', []);
    setEditMode(false);
  };

  const enableIntervalEditModeGraph = (item) => {
    setIntervalForm(item);
    setEditID(item.id || item.tempId);
    setEditMode(true);
    setCreateMode(false);
    toggleIntervalEdit(true);
  };

  const enableIntervalEditMode = (id) => {
    const interval = intervalList.find((intervalLocal) => {
      if (intervalLocal.id) {
        return intervalLocal.id === id;
      }
      return intervalLocal.tempId === id;
    });
    setIntervalForm(interval);
    setEditID(id);
    setEditMode(true);
    setCreateMode(false);
    toggleIntervalEdit(true);
  };

  const duplicateInterval = (idx) => {
    setCreateMode(true);
    const intervalsFromForm = getFormValue('intervals');
    const intervalToDuplicate = intervalsFromForm
      .find((interval) => interval.tempId === idx || interval.id === idx);
    const newInterval = { ...intervalToDuplicate };
    newInterval.comment = undefined;
    newInterval.mottles = JSON.parse(JSON.stringify(newInterval.mottles));
    newInterval.intervalTop = undefined;
    newInterval.intervalBottom = undefined;

    setIntervalForm(newInterval);
  };

  const exitEdit = () => {
    setCreateMode(false);
    setEditID(null);
    setEditMode(false);
    toggleIntervalEdit();
  };

  const onNewIntervalForm = () => {
    clearForm();
    setCreateMode(true);
    setEditMode(false);
  };

  const genHexString = (len) => {
    const hex = '0123456789ABCDEF';
    let output = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; ++i) {
      output += hex.charAt(Math.floor(Math.random() * hex.length));
    }
    return output;
  };

  const showForm = editMode || createMode;

  const deleteInterval = (id) => {
    const index = intervalList.findIndex((interval) => interval.id === id
      || interval.tempId === id);
    const newIntervalList = [...intervalList];
    newIntervalList.splice(index, 1);
    changeForm('intervals', newIntervalList);
  };

  const sortedIntervalList = intervalList
    ? intervalList?.sort((a, b) => a.intervalTop - b.intervalTop) : [];

  return (
    <div className="Collection">
      <BoreholeDescription
        selectedProject={selectedProject}
      />
      <Grid className="nested-grid">
        <Row>
          <IntervalControls
            intervalList={sortedIntervalList}
            classSchema={classSchema}
            deleteAllIntervals={deleteAllIntervals}
            enableIntervalEditMode={enableIntervalEditMode}
            duplicateInterval={duplicateInterval}
            editMode={editMode}
            editIndex={editId}
            onDeleteInterval={deleteInterval}
            onDeleteAtComments={deleteAtComment}
            atCommentList={atCommentList}
            deleteAllComments={deleteAllAtComments}
            onNewIntervalForm={onNewIntervalForm}
            showForm={showForm}
            genHexString={genHexString}
          />
          <Col>
            <IntervalChart
              intervalList={sortedIntervalList.map((item) => ({
                ...item,
                top: -parseFloat(item.intervalTop),
                bottom: -parseFloat(item.intervalBottom),
                texture: item.fragmentTexture,
                value: [-parseFloat(item.intervalTop), -parseFloat(item.intervalBottom)],
              }))}
              editIndex={editId}
              minimize={showForm}
              onAreaClick={enableIntervalEditModeGraph}
            />
          </Col>
          {showForm && (
            <IntervalForm
              isEdit={editMode}
              depthSubform={depthSubform}
              descriptionSubform={descriptionSubform}
              classSchema={classSchema}
              exitEdit={exitEdit}
              intervalId={editId}
            />
          )}
        </Row>
      </Grid>
    </div>
  );
}

CollectionPage.propTypes = {
  class_schema: PropTypes.string.isRequired,
  depthSubform: PropTypes.shape({
    intervalTop: PropTypes.number,
    intervalBottom: PropTypes.number,
  }).isRequired,
  descriptionSubform: PropTypes.shape({}).isRequired,
  data: PropTypes.PropTypes.shape({}).isRequired,
  selectedClient: PropTypes.shape({}).isRequired,
  selectedProject: PropTypes.shape({}).isRequired,
};

export default CollectionPage;
