import React from 'react';
import { Button } from 'react-bootstrap';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import PropTypes from 'prop-types';
import './SubmissionItem.css';

function SubmissionItem(props) {
  const {
    submission,
    readOnly,
    duplicateEntity,
    subPage,
    editEntity,
  } = props;

  return (
    <div className="SubmissionItem">
      <Accordion>
        <AccordionSummary style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="AccordianSummary">
            <div className="SubmittedSubmissionDescription">
              <h5>{submission.sampleLocation.sampleLocName}</h5>
              <h6>
                {new Date(parseInt(submission.sampleLocation.sampleLocDatetime, 10))
                  .toLocaleString()}
              </h6>
              {submission.submission.createdAt
                && (
                  <p style={{ fontSize: '10px', color: 'green' }}>
                    Submitted On
                    {' '}
                    {new Date(parseInt(submission.submission.createdAt, 10)).toLocaleString()}
                  </p>
                )}
              {submission.submission.updatedAt
                && (
                  <p style={{ fontSize: '10px', color: 'green' }}>
                    {`Edited On ${new Date(parseInt(submission.submission.updatedAt, 10)).toLocaleString()}`}
                  </p>
                )}
            </div>
            {!readOnly && (
              <div className="submissionItemButtonArea">
                <Button
                  className="SubmissionItemButton"
                  onClick={() => duplicateEntity(subPage, submission)}
                >
                  Duplicate
                </Button>
                {(submission.updatedAt === undefined || submission.updatedAt === '')
                  && (
                    <Button
                      className="SubmissionItemButton"
                      bsStyle="info"
                      onClick={() => editEntity(submission, 4)}
                    >
                      Edit
                    </Button>
                  )}
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <pre>
            {JSON.stringify(submission, null, 2)}
          </pre>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

SubmissionItem.propTypes = {
  submission: PropTypes.shape({
    sampleLocation: PropTypes.string,
    updatedAt: PropTypes.string,
    submission: PropTypes.shape({
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  duplicateEntity: PropTypes.bool.isRequired,
  subPage: PropTypes.elementType.isRequired,
  editEntity: PropTypes.func.isRequired,
};

export default SubmissionItem;
