import gql from 'graphql-tag';

export const getQuery = gql`
  query getQuery{
    getProjects {
      id
      name
      clientId
    }
    getClients {
      id
      name
    }
  }
`;

export const updateQuery = gql`
  mutation updateQuery (
    $clientId: ID!,
    $name: String!,
    $id: ID!,
    ){
      updateProject(
        project: {
          clientId: $clientId,
          name: $name,
        }
        id: $id,
      )
    }
`;

export const createQuery = gql`
  mutation createQuery (
    $clientId: ID!,
    $name: String!,
    ) {
      createProject(
        project: {
          clientId: $clientId,
          name: $name,
        }
      )
    }
`;
