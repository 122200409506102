import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import { Link } from 'react-router-dom';
import { getRole } from './query';
import image from './pira_logo.png';
import useSidePanelOpenProvider from '../hooks/useSidePanelOpenProvider';
import useWebService from '../hooks/useWebService';

import './Navigation.css';

function Navigation() {
  const { toggleSidePanel } = useSidePanelOpenProvider();
  const [userRole, setUserRole] = useState(0);

  const { getGqlClient } = useWebService();
  const webservice = getGqlClient();

  const fetchContent = async () => {
    const role = await webservice.query(
      getRole,
      {},
      { fetchPolicy: 'no-cache' },
    );
    setUserRole(role?.data?.getSmaUserRole || 3);
  };

  useEffect(() => {
    if (!userRole) {
      fetchContent();
    }
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="piraAppBar">
        <Toolbar className="piraToolBar">
          <div className="LogoAndTitle" style={{ display: 'flex', height: '60px', justifyConent: 'space-between' }}>
            <img src={image} alt="ERROR" style={{ marginRight: '5px' }} />
            {
              userRole === 3 && (
                <Link to="/" style={{ marginTop: '5px' }}><span>Sample Management App</span></Link>
              )
            }
          </div>
          <IconButton
            className="Navigation-Hamburger"
            onClick={toggleSidePanel}
            style={{ marginTop: '2px' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navigation;
