import { useContext } from 'react';
import { SiteForReviewContext } from '../Providers/siteForReviewProvider';

export default () => {
  const {
    getSiteForReview,
    setSiteForReview,
    clearSiteForReview,
  } = useContext(SiteForReviewContext);
  return {
    getSiteForReview,
    setSiteForReview,
    clearSiteForReview,
  };
};
