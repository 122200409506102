import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({
  getModal: () => 'context not defined',
  changeModal: () => 'context not defined',
});

function ModalProvider({ children }) {
  const [reviewSite, setReviewSite] = useState({ open: false });

  const getModal = () => reviewSite;
  const changeModal = (newModal) => setReviewSite(newModal);

  return (
    <ModalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getModal,
        changeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
