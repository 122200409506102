/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button,
} from 'react-bootstrap';
import {
  Grid,
  Accordion,
  AccordionSummary,
} from '@mui/material';
import Slider from 'react-slick';
import ReviewIntervalChart from './ReviewIntervalChart';
import ReviewSampleChart from './ReviewSampleChart';

import '../../../assets/slick.css';
import '../../../assets/slick-theme.css';

function PrevArrow({ onClick }) {
  return (
    <Button
      style={{
        height: '1079px',
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '-40px',
      }}
      className="slick-arrow"
      onClick={onClick}
    >
      <img
        src="../../icons8-arrow-left-30.png"
        alt="Next"
      />
    </Button>
  );
}

PrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function NextArrow({ onClick }) {
  return (
    <Button
      style={{
        height: '1079px',
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '-40px',
      }}
      className="slick-arrow"
      onClick={onClick}
    >
      <img
        src="../../icons8-arrow-right-30.png"
        alt="Next"
      />
    </Button>
  );
}

NextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function BoreholeList({
  list,
  editBorehole,
}) {
  // If we don't have data, don't render. Easy.
  if (!list || list.length < 1) return (<div />);

  let maxDepthScale = 0;
  list.forEach((item) => {
    const depths = item.intervals.map((interval) => interval.interval_bottom);
    const maxDepth = Math.max(...depths);

    if (maxDepth > maxDepthScale) {
      maxDepthScale = maxDepth;
    }
  });

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const CardList = list.map((card) => (
    <Accordion>
      <AccordionSummary style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <div>
          <h5>{card.borehole}</h5>
          <h6>{new Date(card.date_time).toLocaleString()}</h6>
        </div>
        <div style={{ order: 1 }}>
          {(card.submitted === undefined || card.submitted === '')
              && (
                <Button bsStyle="info" onClick={() => { editBorehole(card._id); }}>
                  Edit
                </Button>
              )}
          {card.submitted
              && (
                <p style={{ fontSize: '10px', color: 'green' }}>
                  Submitted On
                  {new Date(card.submitted).toLocaleString()}
                </p>
              )}
        </div>
      </AccordionSummary>
      <Grid className="nested-grid">
        <Col xs={12} sm={3} md={3}>
          <div onClick={() => {
            if (card.submitted === undefined || card.submitted === '') {
              editBorehole(card._id, 5);
            }
          }}
          >
            <ReviewIntervalChart
              maxDepth={-parseInt(maxDepthScale, 10)}
              intervalList={card.intervals.map((item) => ({
                top: -parseFloat(item.intervalTop),
                bottom: -parseFloat(item.intervalBottom),
                texture: item.fragment_texture,
                value: [-parseFloat(item.intervalTop), -parseFloat(item.intervalBottom)],
              }))}
            />
          </div>
        </Col>
        <Col xs={12} sm={9} md={9}>
          <div onClick={() => {
            if (card.submitted === undefined || card.submitted === '') {
              editBorehole(card._id, 6);
            }
          }}
          >
            <ReviewSampleChart
              maxDepth={-parseInt(maxDepthScale, 10)}
              sampleGridList={card.sample_collection.map((item) => ({
                top: -parseFloat(item.depth_top),
                lab: item.lab,
                ec: item.ec,
                ova: item.ova,
                value: [-parseFloat(item.depth_top), -parseFloat(item.depth_top)],
              }))}
            />
          </div>
        </Col>
      </Grid>
    </Accordion>
  ));

  return (
    <div>
      <style>
        {`
        .slider: {
          display: 'block',
        },
        .slickSlider: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        .slickSlider div: {
          flexGrow: 1,
        },
        .slick-slide > div :focus { outline: none !important; }
      `}
      </style>
      <Slider {...settings}>
        {CardList}
      </Slider>
    </div>
  );
}

BoreholeList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editBorehole: PropTypes.func.isRequired,

};

export default BoreholeList;
