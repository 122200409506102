import { useState } from 'react';
import {
  getClientsQuery,
  getSitesQuery,
  getProjectsQuery,
  getAPECsQuery,
} from '../hookQueries';
import useWebService from './useWebService';
import useDbCacheProvider from './useDbCacheProvider';

const useGetDropDownOptions = () => {
  const {
    // getDbCache,
    cacheOffline,
  } = useDbCacheProvider();
  const { getGqlClient } = useWebService();
  const webservice = getGqlClient();
  const [clients, setClients] = useState(null);
  const [projects, setProjects] = useState(null);
  const [sites, setSites] = useState(null);
  const [apecs, setApecs] = useState(null);

  const getClientsOptions = (navigator) => {
    if (clients) return clients;
    if (navigator.onLine) {
      webservice.query(
        getClientsQuery,
        {},
        { fetchPolicy: 'no-cache' },
      ).then((response) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('smaClients', JSON.stringify(response.data.getClients));
        // cacheOffline({ key: 'clients', value: response.data.getClients, loadingState: false });
        setClients(response.data.getClients);
      });
      return clients;
    }
    // const cacheClients = getDbCache().clients;
    // eslint-disable-next-line no-undef
    const localStorageClients = JSON.parse(localStorage.getItem('smaClients'));
    if (localStorageClients) {
      setClients(localStorageClients);
    }
    return clients;
  };

  const getProjectsOptions = (navigator) => {
    if (projects) return projects;
    if (navigator.onLine) {
      webservice.query(
        getProjectsQuery,
        {},
        { fetchPolicy: 'no-cache' },
      ).then((response) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('smaProjects', JSON.stringify(response.data.getProjects));
        cacheOffline({ key: 'projects', value: response.data.getProjects, loadingState: false });
        setProjects(response.data.getProjects);
      });
      return projects;
    }
    // const cacheProjects = getDbCache().projects;
    // eslint-disable-next-line no-undef
    const localStorageProjects = JSON.parse(localStorage.getItem('smaProjects'));
    if (localStorageProjects) {
      setProjects(localStorageProjects);
    }
    return projects;
  };

  const getSitesOptions = (navigator) => {
    if (sites) return sites;
    if (navigator.onLine) {
      webservice.query(
        getSitesQuery,
        {},
        { fetchPolicy: 'no-cache' },
      ).then((response) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('smaSites', JSON.stringify(response.data.getSites));
        cacheOffline({ key: 'sites', value: response.data.getSites, loadingState: false });
        setSites(response.data.getSites);
      });
      return sites;
    }
    // const cacheSites = getDbCache().sites || [];
    // eslint-disable-next-line no-undef
    const localStorageSites = JSON.parse(localStorage.getItem('smaSites'));
    if (localStorageSites) {
      setSites(localStorageSites);
    }
    return sites;
  };

  const getAPECsOptions = (navigator) => {
    if (apecs) return apecs;
    if (navigator.onLine) {
      webservice.query(
        getAPECsQuery,
        {},
        { fetchPolicy: 'no-cache' },
      ).then((response) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('smaApec', JSON.stringify(response.data.getApecsBy));
        cacheOffline({ key: 'apec', value: response.data.getApecsBy, loadingState: false });
        setApecs(response.data.getApecsBy);
      });
      return apecs;
    }
    // const cacheAPECS = getDbCache().apec || [];
    // eslint-disable-next-line no-undef
    const localStorageApec = JSON.parse(localStorage.getItem('smaApec'));
    if (localStorageApec) {
      setApecs(localStorageApec);
    }
    return apecs;
  };

  return {
    getClientsOptions,
    getProjectsOptions,
    getSitesOptions,
    getAPECsOptions,
  };
};

export default useGetDropDownOptions;
