import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

function EditAtComment({
  currentAtComment,
  setEditedAtComment,
  atCommentList,
}) {
  const [atCommentDepth, setAtCommentDepth] = useState(atCommentList[currentAtComment]?.depth);
  const [atCommentText, setAtCommentText] = useState(atCommentList[currentAtComment]?.comment);

  const handleClose = () => {
    setEditedAtComment(null);
  };

  const editAtComment = () => {
    // eslint-disable-next-line no-param-reassign
    atCommentList[currentAtComment] = {
      depth: atCommentDepth,
      comment: atCommentText,
    };

    handleClose();
  };

  const allowEdit = () => atCommentDepth && atCommentText;

  return (
    <div>
      <Modal show={currentAtComment !== null} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit @ Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="atCommentDepth"
            type="text"
            placeholder="Depth"
            value={atCommentDepth}
            onChange={(event) => setAtCommentDepth(event.target.value)}
          />
          <input
            className="atCommentText"
            type="text"
            placeholder="Text"
            value={atCommentText}
            onChange={(event) => setAtCommentText(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
          >
            Cancel
          </Button>
          <Button
            bsStyle="primary"
            disabled={!allowEdit()}
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
            onClick={editAtComment}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

EditAtComment.propTypes = {
  currentAtComment: PropTypes.number,
  setEditedAtComment: PropTypes.func.isRequired,
  atCommentList: PropTypes.arrayOf(PropTypes.shape({
    depth: PropTypes.number,
    comment: PropTypes.string,
  })),
};

EditAtComment.defaultProps = {
  currentAtComment: null,
  atCommentList: [],
};

export default EditAtComment;
