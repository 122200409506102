/* eslint-disable no-tabs */
/**
 * Big list o' queries for graphql
 */
import gql from 'graphql-tag';

export const lastUpdateQuery = gql`
	query getLastUpdate {
		lastUpdate
	}
`;

export const sitesMetadataQuery = gql`
	query getClientSiteProjectInfo {
	getClients {
		id
		name
	}
	getProjects {
		id
		name
		clientId
	}
	getSites {
		id
		uwi
		projectId
		name
		manual
	}
	}
`;

export const getLabParamsQuery = gql`
	query getLabParams {
		getLabParameters {
			id
			commonName
			matrix
			lab
			labDesignation
		}
	}
`;

export const getFieldParamsQuery = gql`
	query getFieldParams {
		getFieldParameters {
			id
			name
			unit
		}
	}
`;

export const getContainerTypesQuery = gql`
	query getContainerTypes {
		getContainers {
			id
			name
			material
			preservative
			lab
			type
		}
	}
`;

export const getApecsByQuery = gql`
	query getApecsBy {
		getApecsBy (manual: false) {
			id
			name
			manual
		}
	}
`;

export const apecQuery = gql`
	query ($id: ID!) {
  	getApec (id: $id) {
  		id
  		name
			manual
  	}
}
`;
