import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import './AddSiteComponent.css';

function AddSite(props) {
  const {
    addFunc,
  } = props;
  const [name, setName] = useState('');
  const [uwi, setUwi] = useState('');

  return (
    <div>
      <TextField
        className="siteNameInput"
        id="filled-basic"
        label="New Site Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        className="siteNameInput"
        id="filled-basic"
        label="New Site UWI"
        value={uwi}
        onChange={(event) => setUwi(event.target.value)}
      />
      <Button
        className="addSiteButton"
        bsStyle="primary"
        disabled={name === '' || uwi === ''}
        onClick={() => {
          addFunc(name, uwi);
          setName('');
          setUwi('');
        }}
      >
        Add
      </Button>
    </div>
  );
}

AddSite.propTypes = {
  addFunc: PropTypes.func.isRequired,
};

export default AddSite;
