import React, { useState } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import IntervalDepthSelector from '../intervals/IntervalDepthSelector';
import IntervalDescriptionForm from '../intervals/IntervalDescriptionForm';
import useIntervalDescriptionFormProvider from '../../../hooks/useIntervalDescriptionFormProvider';
import useFormProvider from '../../../hooks/useFormProvider';
import './intervalForm.css';

const intervalForm = ({
  isEdit,
  classSchema,
  intervalId,
  exitEdit,
}) => {
  const [topValid, setTopValid] = useState(true);
  const [bottomValid, setBottomValid] = useState(true);

  const {
    changeForm: changeIntervalForm,
    getFormValue,
    getForm,
    clearForm,
  } = useIntervalDescriptionFormProvider();
  const intervalFormValues = getForm();
  const { changeForm, getFormValue: getMainFormValue } = useFormProvider();

  const onAddInterval = () => {
    const newIntervalList = [...getMainFormValue('intervals')];
    intervalFormValues.tempId = Math.random().toString().slice(2, 15);
    newIntervalList.push(intervalFormValues);

    changeForm('intervals', newIntervalList);
    exitEdit();
  };

  const onEditInterval = () => {
    const intervalIndex = getMainFormValue('intervals').findIndex((interval) => {
      if (interval.id) {
        return interval.id === intervalFormValues.id;
      }
      return interval.tempId === intervalFormValues.tempId;
    });
    const newIntervalList = [...getMainFormValue('intervals')];
    newIntervalList[intervalIndex] = intervalFormValues;
    changeForm('intervals', newIntervalList);
    exitEdit();
  };

  const onCancelForm = () => {
    clearForm();
    exitEdit();
  };

  const headerText = isEdit ? `Interval ${intervalFormValues?.intervalTop}-${intervalFormValues.intervalBottom}` : 'New Interval';

  const isValidInterval = ((parseFloat(intervalFormValues.intervalTop)
  < parseFloat(intervalFormValues.intervalBottom))
    && (intervalFormValues.fragmentTexture
      || intervalFormValues.primaryConstituent
      || intervalFormValues.texture
      || intervalFormValues.Comment));

  const renderSubmitArea = () => (
    <div className="submitArea">
      {isEdit
        ? (
          <>
            <button type="button" className="intervalFormSaveButton" disabled={!isValidInterval} onClick={onEditInterval}>
              Save Edited Interval
            </button>
            <button
              type="button"
              className="intervalFormCancelButton"
              onClick={onCancelForm}
            >
              Cancel
            </button>
          </>
        )
        : (
          <>
            <button
              type="button"
              className="intervalFormSaveButton"
              disabled={!isValidInterval}
              onClick={onAddInterval}
            >
              Save New Interval
            </button>
            <button
              className="intervalFormCancelButton"
              onClick={onCancelForm}
              type="button"
            >
              Cancel
            </button>
          </>
        )}
    </div>
  );

  return (
    <div className="intervalForm">
      <span className="intervalHeader">{headerText}</span>
      {renderSubmitArea()}
      <IntervalDepthSelector
        intervalIndex={intervalId}
        isEdit={isEdit}
        topValid={topValid}
        setTopValid={setTopValid}
        bottomValid={bottomValid}
        setBottomValid={setBottomValid}
      />
      <IntervalDescriptionForm
        schemaType={classSchema}
      />
      <div>
        <Row>
          <hr />
          <Col xs={12} sm={3} md={3}>Interval Comment</Col>
          <Col xs={12} sm={9} md={9}>
            <TextField
              type="textarea"
              className="textarea"
              name="intervalComment"
              value={getFormValue('intervalComment')}
              onChange={(event) => changeIntervalForm('intervalComment', event.target.value)}
              rows="2"
              fullWidth
              placeholder="Logger Created Interval Comment"
            />
          </Col>
        </Row>
      </div>
      {!isValidInterval
        && (
          <Alert bsStyle="danger" className="intervalAlertArea">
            {classSchema === 'schema_cssc' && 'Top interval > Bottom interval or \'Texture\' is missing, or this interval spans another'}
            {classSchema === 'schema_uscs' && 'Top interval > Bottom interval or \'Primary Constituent\' is missing, or this interval spans another'}
            {(classSchema === 'schema_custom1' || !classSchema) && 'Top interval > Bottom interval or \'Texture\' is missing, or this interval spans another'}
          </Alert>
        )}
      {renderSubmitArea()}
    </div>
  );
};

export default intervalForm;
