/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';

import PropTypes from 'prop-types';
import UtmObj from 'utm-latlng';
import {
  Modal,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Alert,
} from 'react-bootstrap';

import UserSiteInput from './userSiteInput';
import Navigation from '../Navigation';
import LoadingSpinner from '../common/LoadingSpinner';
import {
  DrillMethods,
  WeatherDescriptions,
  WindDirections,
  WindSpeeds,
  PrecipitationValues,
  TemperatureValues,
} from '../../assets/static/models';
import CollectionPage from './Collection';
import FieldGrid from './grid/FieldGrid';
import AddSiteComponent from './sites/AddSiteComponent';
import '../../assets/Spin.css';
import MapPage from './map/Map';
import BoreholeDescription from './BoreholeDescription';
import { BoreholeUtility } from '../../logic/BoreholeUtility';
import LocalDbService from '../../logic/LocalDbService';
import useOfflineServiceProvider from '../../hooks/useOfflineServiceProvider';
import useEditFormProvider from '../../hooks/useEditFormProvider';
import useBoreholeListProvider from '../../hooks/useBoreholeListProvider';
import useActiveTabProvider from '../../hooks/useActiveTabProvider';
import useFormProvider from '../../hooks/useFormProvider';
import useGetDropDownOptions from '../../hooks/useGetDropDownOptions';
import './FieldMeta.css';

function FieldMeta(props) {
  const {
    editForm,
    location,
    data,
  } = props;

  const {
    getFormValue,
    changeForm,
    getForm,
    getErrors,
    changeFormMultiple,
    initialize,
  } = useFormProvider();
  const errors = getErrors();
  const formState = getForm();

  const {
    getClientsOptions,
    getProjectsOptions,
    getSitesOptions,
    getAPECsOptions,
  } = useGetDropDownOptions();
  // eslint-disable-next-line no-undef
  const clientsOptions = getClientsOptions(navigator) || [];
  // eslint-disable-next-line no-undef
  const projectOptions = getProjectsOptions(navigator) || [];
  // eslint-disable-next-line no-undef
  const siteOptions = getSitesOptions(navigator, getFormValue('project')) || [];
  // eslint-disable-next-line no-undef
  const apecsOptions = getAPECsOptions(navigator) || [];

  const selectedClient = clientsOptions.find((x) => x.id === formState.client);
  const selectedProject = projectOptions.find((x) => x.id === formState.project);

  const {
    selectedSiteId,
    boreholeName,
    userSite,
  } = formState;
  const sampleLocDatetime = formState.sample_loc_datetime || '';
  const navigate = useNavigate();
  const {
    setBoreholeList,
    getBoreholeList: getBoreholeListFromProvider,
  } = useBoreholeListProvider();
  const boreholeList = getBoreholeListFromProvider();
  const { toggleEdit } = useEditFormProvider();
  const { getActiveTab, setActiveTab } = useActiveTabProvider();
  const activeTab = getActiveTab();
  const { cacheUserSites, getUserSites } = useOfflineServiceProvider();
  const [userSites, setUserSites] = useState(getUserSites());
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [loadingGPS, setLoadingGPS] = useState(false);
  const [GPSErr, setGPSErr] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentTab, setCurrentTab] = useState(1);
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [accuracy, setAccuracy] = useState('');
  const [GPSSame, setGPSSame] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [utm, setUtm] = useState(new UtmObj());
  // eslint-disable-next-line no-unused-vars

  const formatErrorMessage = () => {
    const errorsLocal = Object.values(errors).map((value) => {
      if (value === 'Latitude is required') return 'Latitude';
      if (value === 'Longitude is required') return 'Longitude';
      if (value.toString().includes('Latitude between')) return '';
      if (value.toString().includes('Longitude between')) return '';
      if (typeof value === 'object') { // handle form sections (depth form and description form)
        return ''; // dont want interval errors in the message list
      }
      return value;
    })?.filter((value) => value !== ''); // remove empties

    if (errorsLocal?.length > 0) {
      return errorsLocal.join(', ');
    }
    return null; // return nothing so the alert doesnt show up
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleEditShow = () => {
    setEditShow(!editShow);
  };

  const getBoreholeList = () => {
    LocalDbService.list().then((results) => {
      setBoreholeList(results.rows);
    });
  };

  const handleDelete = () => {
    initialize({
      classSchema: 'schema_cssc',
      units: 'metric',
      intervals: [],
      intervalDepthForm: {
        intervalDepthUnits: 'interval_m',
      },
      intervalDescriptionForm: {},
      samples: [],
      atComments: [],
      fieldMeasurements: [],
      sampleCollection: [],
      apecName: 'Unknown APEC',
      apec: '7b08a054-0017-4ead-b887-92d040a702d5',
      borehole: BoreholeUtility.defaultBoreholeName(),
    });

    getBoreholeList();

    setShow(false);

    setActiveTab(1);

    if (editForm) {
      toggleEdit(false);
      setEditShow(!editShow);
      navigate('/submission');
    }
  };

  const onGeolocationSuccess = (pos) => {
    const temp = utm.convertLatLngToUtm(
      pos.coords.latitude?.toFixed(6),
      pos.coords.longitude?.toFixed(6),
      1,
    );
    changeFormMultiple([
      { field: 'latitude', newVal: pos.coords.latitude?.toFixed(6) },
      { field: 'longitude', newVal: pos.coords.longitude?.toFixed(6) },
      { field: 'gpsAccuracy', newVal: pos.coords.accuracy?.toFixed(2) },
      { field: 'gpsSource', newVal: 'geolocation-api' },
      { field: 'utmeasting', newVal: temp.Easting },
      { field: 'utmnorthing', newVal: temp.Northing },
      { field: 'zone', newVal: temp.ZoneNumber },
      { field: 'sample_loc_datetime', newVal: new Date().toJSON() },
    ]);
    setLoadingGPS(false);
    setGPSErr(false);
  };

  const onGeolocationFailure = (error) => {
    // eslint-disable-next-line no-console
    console.warn(`ERROR(${error.code}): ${error.message}`);
  };

  const getLocation = async () => {
    // Source from native GPS if available
    if (location.usedInFix > 0) {
      const {
        latitude: locationLatitude,
        longitude: locationLongitude,
        accuracy: locationAccuracy,
        count,
        usedInFix,
      } = location;
      const temp = utm.convertLatLngToUtm(
        locationLatitude?.toFixed(6),
        locationLongitude?.toFixed(6),
        1,
      );
      setUtm(temp);
      changeFormMultiple([
        { field: 'latitude', newVal: locationLatitude?.toFixed(6) },
        { field: 'longitude', newVal: locationLongitude?.toFixed(6) },
        { field: 'gpsAccuracy', newVal: locationAccuracy },
        { field: 'gpsCount', newVal: count },
        { field: 'gpsUsedInFix', newVal: usedInFix },
        { field: 'gpsSource', newVal: 'native' },
        { field: 'zone', newVal: temp.ZoneNumber },
        { field: 'utmeasting', newVal: temp.Easting },
        { field: 'utmnorthing', newVal: temp.Northing },
      ]);
      setLoadingGPS(false);
      setGPSErr(false);
    // eslint-disable-next-line no-undef
    } else if (navigator.geolocation) {
      setLoadingGPS(true);
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition(onGeolocationSuccess, onGeolocationFailure, options);
    }
  };

  const getLocationUpdates = () => {
    // Source from native GPS if available
    if (location.usedInFix > 0) {
      const { latitude: locationLatitude, longitude: locationLongitude } = location;
      setGPSSame(lat === locationLatitude?.toFixed(6) && long === locationLongitude?.toFixed(6));
      setGPSErr(false);
    // eslint-disable-next-line no-undef
    } else if (navigator.geolocation) {
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition((pos) => {
        const GPSSameLocal = (lat === pos.coords.locationLatitude?.toFixed(6)
        && long === pos.coords.locationLongitude?.toFixed(6));
        setLat(pos.coords.locationLatitude?.toFixed(6));
        setLong(pos.coords.locationLongitude?.toFixed(6));
        setAccuracy(pos.coords.accuracy?.toFixed(2));
        setGPSSame(GPSSameLocal);
        setGPSErr(false);
      }, (error) => {
        if (error.code === 1) {
          setGPSErr('Check and ensure that tablet location services is enabled.');
          setLoadingGPS(false);
        }
        if (error.code === 3) {
          setGPSErr('Position is static');
          setLoadingGPS(false);
        }
      }, { maximumAge: 0, timeout: 10000, enableHighAccuracy: true });
    } else {
      setGPSErr('Your device does not support the GPS');
      setLoadingGPS(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { intervalId, setIntervalId } = useState(getLocationUpdates(5000));

  const clearLocation = () => {
    changeFormMultiple([
      { field: 'latitude', newVal: 0 },
      { field: 'longitude', newVal: 0 },
      { field: 'gpsAccuracy', newVal: 0 },
      { field: 'gpsCount', newVal: 0 },
      { field: 'gpsUsedInFix', newVal: 0 },
      { field: 'gpsSource', newVal: 0 },
      { field: 'zone', newVal: 0 },
      { field: 'utmeasting', newVal: 0 },
      { field: 'utmnorthing', newVal: 0 },
    ]);
  };

  const setTab = (item) => {
    setCurrentTab(item);
  };

  const addNewSite = (name, uwi) => {
    const site = {
      name,
      uwi,
    };
    const newSites = getUserSites();
    newSites.push(site);
    setUserSites(newSites);
    cacheUserSites(newSites);

    changeFormMultiple([
      { field: 'userSite', newVal: site.name },
      { field: 'siteName', newVal: site.name },
      { field: 'site', newVal: '' },
    ]);
  };

  const boreholeNameValidation = (value) => {
    const duplicates = boreholeList?.filter((item) => (
      item.doc.site === selectedSiteId && item.doc.borehole === value));
    if (duplicates?.length > 0) {
      return 'This borehole name already exists for this site.';
    }
    return '';
  };

  useEffect(() => {
    getBoreholeList();
  }, []);

  return (
    <div className="FieldMeta">
      <Navigation />
      <div className="formArea">
        {Object.keys(data)?.length === 0 && data.constructor === Object
          ? (
            <Alert bsStyle="danger">
              Log in and Sync to Remote Database before trying to create a log.
              Open up the side menu using the
              {' '}
              <MenuIcon />
              {' '}
              {'on the top right, make sure you are \'Currently Online\' and click the \'Remote Database\' button'}
              {' '}
              <RefreshIcon />
              . Refresh the page after sync. If you are not online you will have to
              collect samples with pen and paper.
            </Alert>
          )
          : (
            <div>
              <Modal show={show} onHide={handleShow}>
                <Modal.Body>
                  Are you sure you want to delete all input data and start fresh?
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleShow}>Close</Button>
                  <Button bsStyle="primary" onClick={handleDelete}>Delete Entry</Button>
                </Modal.Footer>
              </Modal>
              <Modal show={editShow} onHide={handleEditShow}>
                <Modal.Body>
                  <p>Are you sure you want to cancel editing this form?</p>
                  <p>
                    Your previous form will stay the same and you will start with a new fresh form.
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleEditShow}>Close</Button>
                  <Button bsStyle="primary" onClick={handleDelete}>Cancel Current Edit</Button>
                </Modal.Footer>
              </Modal>
              <Grid>
                <Row className="navigationButtons">
                  <Col xs={4} span={6}>
                    <Button
                      onClick={() => navigate('/submission')}
                      className="NavigationButton"
                    >
                      Submission Page
                    </Button>
                  </Col>
                  <Col xs={4} span={6}>
                    {editForm && (
                      <Button
                        onClick={handleEditShow}
                        className="NavigationButton"
                      >
                        Cancel Current Edit
                      </Button>
                    )}
                    {!editForm && (
                      <Button
                        onClick={() => {
                          handleDelete();
                          toggleEdit(false);
                          handleShow();
                          navigate('/submission');
                        }}
                        className="DeleteCurrentEntryButton"
                      >
                        Delete Current Entry
                      </Button>
                    )}
                  </Col>
                  <Col xs={4} span={6}>
                    <Button
                      onClick={() => navigate('/field/review')}
                      className="NavigationButton"
                    >
                      Review
                    </Button>
                  </Col>
                </Row>
                {editForm && (
                <>
                  <h2>CURRENTLY EDITING PREVIOUS FORM:</h2>
                  <h5>
                    {selectedClient?.name}
                    {' '}
                    -
                    {selectedProject?.name}
                    {' '}
                    -
                    {boreholeName}
                    {' '}
                    -
                    {new Date(sampleLocDatetime).toLocaleString()}
                  </h5>
                </>
                )}
                {!editForm
            && <h3>Soil Logging Information</h3>}
                {formatErrorMessage()
            && (
            <div className="missingField">
              Missing the following:
              {' '}
              {formatErrorMessage()}
            </div>
            )}
                <Tabs
                  activeKey={activeTab}
                  onSelect={(item) => {
                    setTab(item);
                    setActiveTab(item);
                  }}
                  id="field-metadata-tabs"
                >
                  <Tab eventKey={1} title="Work Context" className="tabWrapper">
                    <BoreholeDescription
                      selectedProject={selectedProject}
                    />
                    <Box className="WorkContext">
                      <h2>
                        <b>Work Context</b>
                      </h2>
                      <div className="PanelBody">
                        <div className="inputFieldWrapperFull">
                          <TextField
                            id="filled-basic"
                            label="Logger"
                            error={errors.logger}
                            className="workContextInputFull"
                            required
                            value={getFormValue('logger')}
                            onChange={(newValue) => changeForm('logger', newValue.target.value)}
                          />
                        </div>
                        <FormControl error={errors.client} fullWidth marginNormal classes="formControl">
                          <InputLabel id="client-select-label">Client</InputLabel>
                          <Select
                            labelId="client-select-label"
                            id="client-select"
                            label="Client"
                            value={getFormValue('client')}
                            className="selectFullWidth"
                            required
                            onChange={(event) => {
                              changeFormMultiple([
                                { field: 'client', newVal: event.target.value },
                                { field: 'project', newVal: '' },
                                { field: 'site', newVal: '' },
                              ]);
                            }}
                          >
                            <MenuItem value="">SELECT</MenuItem>
                            {clientsOptions?.map((client) => (
                              <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl error={errors.project} fullWidth marginNormal classes="formControl">
                          <InputLabel id="project-select-label">Project</InputLabel>
                          <Select
                            labelId="project-select-label"
                            id="project-select"
                            label="Project"
                            className="selectFullWidth"
                            value={getFormValue('project')}
                            disabled={!getFormValue('client')}
                            required
                            onChange={(event) => changeFormMultiple([
                              { field: 'project', newVal: event.target.value },
                              { field: 'site', newVal: '' },
                            ])}
                          >
                            <MenuItem value="">SELECT</MenuItem>
                            {projectOptions?.filter(
                              (project) => project.clientId === selectedClient?.id,
                            ).map((project) => (
                              <MenuItem key={project.id} value={project.id}>
                                {project.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <br />
                        <FormControl error={errors.site} fullWidth marginNormal classes="formControl">
                          <InputLabel id="site-select-label">Site</InputLabel>
                          <Select
                            labelId="site-select-label"
                            id="site-select"
                            label="Site"
                            className="selectFullWidth"
                            value={getFormValue('site')}
                            required
                            onChange={(event) => {
                              changeFormMultiple([
                                { field: 'site', newVal: event.target.value },
                                { field: 'siteName', newVal: siteOptions?.find((site) => site.id === event.target.value)?.name },
                              ]);
                            }}
                            disabled={getFormValue('project') === ''}
                          >
                            <MenuItem value="">SELECT</MenuItem>
                            {siteOptions?.filter((site) => site.projectId === selectedProject?.id)
                              .map((site) => (
                                <MenuItem
                                  key={site.id}
                                  value={site.id}
                                >
                                  {site.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Row>
                          <Col xs={6} sm={6}>
                            <FormControl error={errors.drillingMethod} fullWidth marginNormal classes="formControl">
                              <InputLabel id="drilling-method-select-label">Drilling Method*</InputLabel>
                              <Select
                                labelId="drilling-method-select-label"
                                id="drilling-method-select"
                                className="selectFullWidth"
                                label="Drilling Method"
                                value={getFormValue('drillingMethod')}
                                required
                                onChange={(event) => changeForm('drillingMethod', event.target.value)}
                              >
                                <MenuItem value="">Do Not Specify</MenuItem>
                                {DrillMethods?.map((drillMethod) => (
                                  <MenuItem key={drillMethod} value={drillMethod}>
                                    {drillMethod}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col xs={6} sm={6}>
                            <TextField
                              id="drilling-name-filled-basic"
                              className="workContextInputFull"
                              error={errors.drillingName}
                              required
                              label="Drilling Company Name"
                              value={getFormValue('drillingName')}
                              onChange={(event) => changeForm('drillingName', event.target.value)}
                            />
                          </Col>
                        </Row>
                        <TextField
                          id="workcontext_comment-filled-basic"
                          label="Work Context Comment"
                          className="workContextInputFull"
                          multiline
                          value={getFormValue('workContextComment')}
                          onChange={(event) => changeForm('workContextComment', event.target.value)}
                        />
                      </div>
                      <hr />
                      <div className="PanelBody">
                        <UserSiteInput userSites={userSites} />
                        <AddSiteComponent
                          addFunc={addNewSite}
                          change={changeForm}
                        />
                      </div>
                    </Box>
                  </Tab>
                  <Tab eventKey={2} title="Borehole" className="tabWrapper">
                    <BoreholeDescription
                      selectedProject={selectedProject}
                    />
                    <Box>
                      <h2>
                        <b>Borehole Information</b>
                      </h2>
                      <div className="PanelBody">
                        <div className="inputFieldWrapper">
                          <TextField
                            id="borehole-filled-basic"
                            label="Borehole"
                            error={errors.borehole}
                            className="workContextInputFull"
                            required
                            value={getFormValue('borehole')}
                            onChange={(event) => changeForm('borehole', event.target.value)}
                            helperText={boreholeNameValidation(getFormValue('borehole'))}
                          />
                        </div>
                        <div className="inputFieldWrapper">
                          <TextField
                            id="borehole_comment-filled-basic"
                            label="Borehole Comment"
                            className="workContextInputFull"
                            multiline
                            value={getFormValue('boreholeComment')}
                            onChange={(event) => changeForm('boreholeComment', event.target.value)}
                          />
                        </div>
                        <FormControlLabel
                          control={<Checkbox value={getFormValue('imapcted')} onChange={(event) => changeForm('impacted', event.target.value)} />}
                          label="Impacted"
                        />
                      </div>
                    </Box>
                    <Box>
                      <h2>
                        <b>Areas of Potential Environmental Concern</b>
                      </h2>
                      <div className="PanelBody">
                        <Row>
                          <Col xs={6} sm={6}>
                            <FormControl error={errors.apec} fullWidth marginNormal classes="formControl">
                              <InputLabel id="apec-select-label">APEC</InputLabel>
                              <Select
                                labelId="apec-select-label"
                                id="apec-filled-basic"
                                label="APEC"
                                required
                                className="selectFullWidth"
                                value={getFormValue('apec')}
                                onChange={(event) => {
                                  const apec = apecsOptions
                                    .find((option) => option.id === event.target.value);
                                  changeFormMultiple([
                                    { field: 'apec', newVal: apec.id },
                                    { field: 'apecName', newVal: apec.text },
                                    { field: 'userApec', newVal: '' },
                                  ]);
                                }}
                              >
                                <MenuItem value="">SELECT</MenuItem>
                                {apecsOptions?.map((apec) => (
                                  <MenuItem key={apec.id} value={apec.id}>
                                    {apec.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col xs={6} sm={6}>
                            <TextField
                              id="userApec-filled-basic"
                              label="User Apec"
                              multiline
                              error={errors.apec}
                              className="workContextInputFull"
                              value={getFormValue('userApec')}
                              onChange={(event) => {
                                changeFormMultiple([
                                  { field: 'userApec', newVal: event.currentTarget.value },
                                  { field: 'apecName', newVal: '' },
                                  { field: 'apecid', newVal: '' },
                                  { field: 'apec', newVal: '' },
                                ]);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Box>
                    <Box bsStyle={GPSSame ? 'danger' : 'info'}>
                      <h2>
                        <div>
                          <span className="glyphicon glyphicon-off spin" />
  &nbsp;
                          {location.usedInFix
                            ? (
                              <b>
                                Current Borehole Location
                                { GPSSame && (' (Same as Previous)') }
                                :
                                {location.latitude}
                                {' '}
                                {location.longitude}
                                {' '}
  &nbsp;Accuracy:
                                {' '}
                                {location.accuracy}
                                {' '}
                                Satellites:
                                {' '}
                                {location.usedInFix}
                                /
                                {location.count}
                              </b>
                            )
                            : (
                              <b>
                                Current Borehole Location
                                { GPSSame && (' (Same as Previous)') }
                                :
                                {lat}
                                {' '}
                                {long}
                                {' '}
  &nbsp;Accuracy:
                                {' '}
                                {accuracy}
                              </b>
                            )}
                        </div>
                      </h2>
                      <div className="PanelBody">
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <Button
                            block
                            bsStyle="primary"
                            onClick={getLocation}
                            className="gpsButton"
                          >
                            Get GPS Coordinates
                          </Button>
                          <Button block onClick={clearLocation} className="gpsButton">Clear</Button>
                        </div>
                        <Row>
                          <Col xs={6} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                type="number"
                                id="latitude-filled-basic"
                                label="Latitude"
                                error={errors.latitude}
                                className="workContextInput"
                                required
                                value={getFormValue('latitude') ? getFormValue('latitude') : ''}
                                onChange={(event) => {
                                  changeFormMultiple([
                                    { field: 'latitude', newVal: event.target.value },
                                    { field: 'sample_loc_datetime', newVal: new Date().toJSON() },
                                  ]);
                                }}
                              />
                              <Button
                                onClick={() => {
                                  if (!Number.isNaN(Number(getFormValue('latitude')))) {
                                    const localLat = getFormValue('latitude') * -1;
                                    changeForm('latitude', localLat.toString());
                                  }
                                }}
                                className="LatLonButton"
                              >
                                -
                              </Button>
                            </div>
                            <div className="inputFieldWrapper">
                              <TextField
                                type="number"
                                id="longitude-filled-basic"
                                className="workContextInput"
                                label="Longitude"
                                error={errors.longitude}
                                required
                                value={getFormValue('longitude') ? getFormValue('longitude') : ''}
                                onChange={(event) => {
                                  if (sampleLocDatetime === null
                                    || sampleLocDatetime === undefined
                                    || sampleLocDatetime === '') {
                                    changeFormMultiple([
                                      { field: 'longitude', newVal: event.target.value },
                                      { field: 'sample_loc_datetime', newVal: new Date().toJSON() },
                                    ]);
                                  } else {
                                    changeForm('longitude', event.target.value);
                                  }
                                }}
                              />
                              <Button
                                onClick={() => {
                                  if (!Number.isNaN(Number(getFormValue('longitude')))) {
                                    const longLocal = Number(getFormValue('longitude')) * -1;
                                    changeForm('longitude', longLocal.toString());
                                  }
                                }}
                                className="LatLonButton"
                              >
                                -
                              </Button>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="utmeasting-filled-basic"
                                className="workContextInputFull"
                                label="UTM Easting"
                                readOnly
                                type="number"
                                value={getFormValue('utmeasting')}
                                onChange={(event) => {
                                  changeForm('utmeasting', event.target.value);
                                }}
                              />
                            </div>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="utmnorthing-filled-basic"
                                className="workContextInputFull"
                                label="UTM Northing"
                                readOnly
                                disabled
                                type="number"
                                value={getFormValue('utmnorthing')}
                                onChange={(event) => {
                                  changeForm('utmnorthing', event.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="zone-filled-basic"
                                className="workContextInputFull"
                                label="Zone Number"
                                readOnly
                                disabled
                                type="number"
                                value={getFormValue('zone') ? getFormValue('zone') : ''}
                                onChange={(event) => {
                                  changeForm('zone', event.target.value);
                                }}
                              />
                            </div>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="gpsAccuracy-filled-basic"
                                className="workContextInputFull"
                                label="Accuracy"
                                readOnly
                                disabled
                                type="number"
                                value={getFormValue('gpsAccuracy') ? getFormValue('gpsAccuracy') : ''}
                                onChange={(event) => {
                                  changeForm('gpsAccuracy', event.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="gpsSource-filled-basic"
                                className="workContextInputFull"
                                label="GPS Source"
                                readOnly
                                disabled
                                value={getFormValue('gpsSource') ? getFormValue('gpsSource') : ''}
                                onChange={(event) => {
                                  changeForm('gpsSource', event.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={2}>Satellites</Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="gpsCount-filled-basic"
                                className="workContextInputFull"
                                readonly
                                disabled
                                type="number"
                                label="Count"
                                value={getFormValue('gpsCount')}
                                onChange={(event) => {
                                  changeForm('gpsCount', event.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={6}>
                            <div className="inputFieldWrapper">
                              <TextField
                                id="gpsCount-filled-basic"
                                className="workContextInputFull"
                                readonly
                                disabled
                                type="number"
                                label="Used in fix"
                                value={getFormValue('gpsCount')}
                                onChange={(event) => {
                                  changeForm('gpsCount', event.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        {loadingGPS
                      && <LoadingSpinner centered />}
                        {GPSErr
                      && <Alert bsStyle="danger" style={{ marginTop: 10 }}>{GPSErr}</Alert>}
                        <hr />
                        <MapPage siteid={selectedSiteId || userSite} currentTab={activeTab} />
                      </div>
                    </Box>
                  </Tab>
                  <Tab eventKey={3} title="Lithology" className="tabWrapper">
                    <CollectionPage
                      selectedProject={selectedProject}
                    />
                  </Tab>
                  <Tab eventKey={4} title="Sampling Grid" unmountOnExit className="tabWrapper">
                    <FieldGrid
                      selectedProject={selectedProject}
                    />
                  </Tab>
                  <Tab
                    eventKey={5}
                    className="tabWrapper"
                    title={(
                      <span>
                        Weather
                        <sup>(Optional)</sup>
                      </span>
                    )}
                  >
                    <BoreholeDescription
                      selectedProject={selectedProject}
                    />
                    <Box>
                      <h2>
                        <b>Weather Information (Optional)</b>
                      </h2>
                      <div className="PanelBody">
                        <InputLabel id="weather_description-select-label">Weather Description</InputLabel>
                        <Select
                          labelId="weather_description-select-label"
                          id="weather_description-filled-basic"
                          required
                          className="selectFullWidth"
                          value={getFormValue('weatherDescription')}
                          onChange={(event) => {
                            changeForm('weatherDescription', event.target.value);
                          }}
                        >
                          <MenuItem value="">DO NOT SPECIFY</MenuItem>
                          {WeatherDescriptions?.map((methodName) => (
                            <MenuItem key={methodName} value={methodName}>
                              {methodName}
                            </MenuItem>
                          ))}
                        </Select>
                        <InputLabel id="wind_direction-select-label">Weather Direction</InputLabel>
                        <Select
                          labelId="wind_direction-select-label"
                          id="wind_direction-filled-basic"
                          required
                          className="selectFullWidth"
                          value={getFormValue('windDirection')}
                          onChange={(event) => {
                            changeForm('windDirection', event.target.value);
                          }}
                        >
                          <MenuItem value="">DO NOT SPECIFY</MenuItem>
                          {WindDirections?.map((methodName) => (
                            <MenuItem key={methodName} value={methodName}>
                              {methodName}
                            </MenuItem>
                          ))}
                        </Select>
                        <InputLabel id="wind_speed-filled-basic">Wind Speed (km/h)</InputLabel>
                        <Select
                          labelId="wind_speed-select-label"
                          id="wind_speed-filled-basic"
                          required
                          className="selectFullWidth"
                          value={getFormValue('windSpeed')}
                          onChange={(event) => {
                            changeForm('windSpeed', event.target.value);
                          }}
                        >
                          <MenuItem value="">DO NOT SPECIFY</MenuItem>
                          {WindSpeeds?.map((methodName) => (
                            <MenuItem key={methodName} value={methodName}>
                              {methodName}
                            </MenuItem>
                          ))}
                        </Select>
                        <InputLabel id="precipitation-select-label">Precipitation (mm)</InputLabel>
                        <Select
                          labelId="precipitation-select-label"
                          id="precipitation-filled-basic"
                          required
                          className="selectFullWidth"
                          value={getFormValue('precipitation')}
                          onChange={(event) => {
                            changeForm('precipitation', event.target.value);
                          }}
                        >
                          <MenuItem value="">DO NOT SPECIFY</MenuItem>
                          {PrecipitationValues?.map((methodName) => (
                            <MenuItem key={methodName} value={methodName}>
                              {methodName}
                            </MenuItem>
                          ))}
                        </Select>
                        <InputLabel id="precipitation-select-label">Temperature (&deg;C)</InputLabel>
                        <Select
                          labelId="temperature-select-label"
                          id="temperature-filled-basic"
                          required
                          className="selectFullWidth"
                          value={getFormValue('temperature')}
                          onChange={(event) => {
                            changeForm('temperature', event.target.value);
                          }}
                        >
                          <MenuItem value="">DO NOT SPECIFY</MenuItem>
                          {TemperatureValues?.map((methodName) => (
                            <MenuItem key={methodName} value={methodName}>
                              {methodName}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Box>
                  </Tab>
                </Tabs>
              </Grid>
            </div>
          )}
      </div>
    </div>
  );
}

FieldMeta.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    accuracy: PropTypes.number,
    count: PropTypes.number,
    usedInFix: PropTypes.bool,
  }),
  data: PropTypes.shape({
    clients: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  editForm: PropTypes.bool,
};

FieldMeta.defaultProps = {
  location: {
    latitude: 0,
    longitude: 0,
    accuracy: 0,
    count: 0,
    usedInFix: false,
  },
  data: {
    clients: [],
  },
  editForm: false,
};

export default FieldMeta;
