import { useContext } from 'react';
import { AuthContext } from '../Providers/authProvider';

export default () => {
  const {
    getUserInfo,
    getHashInfo,
    handleOfflineUserLogin,
    handleOfflineUserLogout,
  } = useContext(AuthContext);
  return {
    getUserInfo,
    getHashInfo,
    handleOfflineUserLogin,
    handleOfflineUserLogout,
  };
};
