import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BoreholeListContext = createContext({
  getBoreholeList: () => 'context not defined',
  setBoreholeList: () => 'context not defined',
  clearBoreholeList: () => 'context not defined',
});

function BoreholeListProvider({ children }) {
  const [boreholeList, setBHL] = useState([]);

  const getBoreholeList = () => boreholeList;
  const setBoreholeList = (newBHL) => setBHL(newBHL);
  const clearBoreholeList = () => setBHL([]);

  return (
    <BoreholeListContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getBoreholeList,
        setBoreholeList,
        clearBoreholeList,
      }}
    >
      {children}
    </BoreholeListContext.Provider>
  );
}

BoreholeListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoreholeListProvider;
