import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const EditIntervalContext = createContext({
  getIsEditInterval: () => 'context not defined',
  toggleIntervalEdit: () => 'context not defined',
});

function EditIntervalProvider({ children }) {
  const [isEdit, setIsEdit] = useState(false);

  const getIsEditInterval = () => isEdit;
  const toggleIntervalEdit = (isEditFlag) => setIsEdit(isEditFlag);

  return (
    <EditIntervalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getIsEditInterval,
        toggleIntervalEdit,
      }}
    >
      {children}
    </EditIntervalContext.Provider>
  );
}

EditIntervalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditIntervalProvider;
