/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// A list that shows our interval depths.
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { FragmentTextureColors, SchemaType } from '../../../assets/static/models';
import useIntervalDescriptionFormProvider from '../../../hooks/useIntervalDescriptionFormProvider';
import './MottleListCompontent.css';

function MottleList({
  schemaType,
  editIndex,
}) {
  const {
    changeForm,
    getFormValue,
  } = useIntervalDescriptionFormProvider();

  const mottleList = getFormValue('mottles');

  const onRemove = (idx) => {
    const newList = [...getFormValue('mottles')];
    newList.splice(idx, 1);
    changeForm('mottles', newList);
  };

  const getMottleDesc = (item) => {
    const mottleItems = [
      item.abundance,
      item.size,
      item.colour,
      item.contrast,
      (item.type ? `${item.type}s` : ''),
    ].filter((itemLocal) => !!itemLocal);

    return (
      <span style={{ color: FragmentTextureColors[item.fragment_texture], textOverflow: 'wrap' }}>
        <b>
          {mottleItems.join(' ')}
        </b>
      </span>
    );
  };

  return (
    <ListGroup style={{ maxHeight: 300, overflowY: 'auto' }}>
      {mottleList?.map((item, idx) => (
        <ListGroupItem
          key={item}
          bsStyle={idx === editIndex ? 'warning' : null}
          style={{ cursor: 'default' }}
          value={idx}
          className="mottleItem"
        >
          <div style={{ float: 'right' }}>
            <div style={{ marginLeft: '10px' }}>
              <IconButton
                className="Navigation-Hamburger"
                onClick={() => onRemove(idx)}
                style={{ marginTop: '2px' }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          {schemaType === 'schema_cssc' && getMottleDesc(item)}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

MottleList.propTypes = {
  schemaType: PropTypes.oneOf(SchemaType),
  editIndex: PropTypes.number.isRequired,
};

MottleList.defaultProps = {
  schemaType: 'schema_cssc',
};

export default MottleList;
