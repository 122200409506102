/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Marker,
  ScaleControl,
  Popup,
  MapContainer,
} from 'react-leaflet';
import L from 'leaflet';

import LocalDbService from '../../../logic/LocalDbService';
import LoadingSpinner from '../../common/LoadingSpinner';

// LEAFLET
import 'leaflet/dist/leaflet.css';
import impactedBorehole from './targetIcon.png';

const current = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAABnRSTlMAELDAYNBhtfG6AAAAoklEQVR42u2WMQ7AMAjEmoTe/5/cMUuRB6/ciuwhIsAzmUx+s/apvlpnL+DfJNXySd6FfFItfw0t3xsqIcNOrqHjk90KTq6h53NaQeWmqMQGKrCBeTIQzwbi2UA8G4hnA/BsAB4MwIMBeDIQzwbi2eB4Lyj/BMpQvg3AIFpZfib/nf1A8SPND1U/1vVi0atNL1e93vWBYU8ce2TpM28ymfzlA8Z7Em116rQDAAAAAElFTkSuQmCC';
const borehole = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAMFBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzOGvfDjAAAAEHRSTlMAMHCw4P8QgPBQkMDQQCBgKpKGVAAAARxJREFUeNrtVkEShCAMW6GooOL/f7s7goZRQZwc1oM5OULTNFbo58WLh6NRWswC0aq5G92qJRgQ1d4J73pzQN9VU1gxpxBbF+9MFq5G/mAKGNrL+BG7R2enH6xL310xbPl75/HWu37TUFm/DuGg0FU+2DX9fFybVxG2YIDE+Oa0NSOD5G3oEF9i6LIC4oZVvw/2j5udc0yQk6CifzB0b1x0UmUIJPB7NMT+8/ugUTIlIhviwZCqakoV+L3+lNeXatAhFTYCIA7CdMECdyoACwUT0j6DA3Ah7dUCwYRnAEFTnqD9AwFRAm0i/RnpRqJbmf6Z2N+ZPlDoI409VOljnb1Y2KuNvVzp650dMMgRhx2y2DGPHDTJUffFi2fjC+fBG02nv0YaAAAAAElFTkSuQmCC';

function MapPage({
  siteid,
  currentTab,
}) {
  const defaultLocation = [51.03616, -114.06541];

  const [loading, setLoading] = useState(true);
  const [hasLocation, setHasLocation] = useState(true);
  const mapRef = useRef();
  const [currentLocation, setCurrentLocation] = useState(defaultLocation);
  const [center, setCenter] = useState(defaultLocation);
  const [GPSErr, setGPS] = useState('');
  const [once, setOnce] = useState(true);
  const [latlng, setLatLng] = useState(defaultLocation);
  const [boreholes, setBoreholes] = useState([]);
  const [radius, setRadius] = useState(0);
  const [bounds, setBounds] = useState(null);

  // componentWillUnmount() {
  // this.mapRef.current.leafletElement.stopLocate();
  // }

  const handleSize = () => {
    const map = mapRef?.current;
    if (map !== null) {
      // map.leafletElement.invalidateSize();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentTab === '2') {
      setTimeout(() => {
        handleSize();
      }, 5000);
    } else if (mapRef.current) {
      mapRef.current.leafletElement?.stopLocate();
    }
  }, [currentTab]);

  const handleLocationFound = (e) => {
    setHasLocation(true);
    setLatLng(e.latlng);
    // eslint-disable-next-line react/no-unused-state
    setRadius(e.accuracy / 2);
    // eslint-disable-next-line react/no-unused-state
    setBounds(e.bounds);
    if (once) {
      const map = mapRef.current;
      if (map != null && latlng) {
        map.leafletElement.panTo(latlng);
        setOnce(false);
      }
    }
  };

  const handleLocationError = (e) => {
    setGPS(e.message);
  };

  const initialLocation = () => {
    const map = mapRef.current;
    if (map !== null) {
      map.leafletElement.locate({
        maxZoom: 16, watch: true, maximumAge: 0, enableHighAccuracy: true,
      });
    }
  };

  const updateBoreholeLogList = () => {
    LocalDbService.list().then((result) => {
      // if (result.total_rows !== 0) {
      //   const temp = result.rows
      //     .filter((row) => !siteid || row.doc.siteid === siteid || row.doc.user_site === siteid)
      //     .map((row) => ({
      //       name: row.doc.borehole,
      //       impacted: row.doc.impacted,
      //       position: [parseFloat(row.doc.latitude), parseFloat(row.doc.longitude)],
      //     }));
      //   setBoreholes(temp);
      // }
    });
  };

  // eslint-disable-next-line new-cap
  const myIcon = new L.icon({
    iconUrl: borehole,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, 0],
  });

  // eslint-disable-next-line new-cap
  const myIconImpacted = new L.icon({
    iconUrl: impactedBorehole,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, 0],
  });

  // eslint-disable-next-line new-cap
  const myCurrentIcon = new L.icon({
    iconUrl: current,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, 0],
  });

  useEffect(() => {
    updateBoreholeLogList(siteid);
    initialLocation();
  }, []);

  useEffect(() => {
    updateBoreholeLogList(siteid);
  }, [siteid]);

  const iconValue = (boreholeForIcon) => (boreholeForIcon.impacted ? myIconImpacted : myIcon);

  const currentmarker = hasLocation ? (
    <Marker position={latlng} icon={myCurrentIcon} key="current-location">
      <Popup>
        You are here
        {' '}
        {latlng.toString()}
      </Popup>
    </Marker>
  ) : null;

  return (
    <>
      {loading && <LoadingSpinner centered />}
      {true && (
        <MapContainer
          style={{ width: '100%', height: '400px' }}
          center={center}
          onLocationfound={handleLocationFound}
          onLocationerror={handleLocationError}
          zoom={16}
          ref={mapRef}
          preferCanvas={false}
          scrollWheelZoom={false}
        >
          {boreholes.map((boreholeFromList, idx) => {
            return (
            // eslint-disable-next-line react/self-closing-comp
              <Marker
                icon={iconValue(boreholeFromList)}
                key={boreholeFromList.name}
                position={boreholeFromList.position}
              >
                <Popup>
                  <span>{boreholeFromList.impacted ? 'Impacted' : ''}</span>
                  <span>{` - ${boreholeFromList.name}`}</span>
                  <span>{` - ${boreholeFromList.position?.toString()}`}</span>
                </Popup>
              </Marker>
            );
          })}
          {currentmarker}
          <ScaleControl />
        </MapContainer>
      )}
    </>
  );
}

MapPage.propTypes = {
  siteid: PropTypes.string.isRequired,
  currentTab: PropTypes.number.isRequired,
};

export default MapPage;
