import React, { Component } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

class OnlineNotifier extends Component {
  constructor(props) {
    super(props);

    // When we change whether we're online or offline, re-render the component.
    this.updateOnline = () => { this.forceUpdate(); };
    // eslint-disable-next-line no-undef
    window.addEventListener('online', this.updateOnline);
    // eslint-disable-next-line no-undef
    window.addEventListener('offline', this.updateOnline);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    window.removeEventListener('online', this.updateOnline);
    // eslint-disable-next-line no-undef
    window.removeEventListener('offline', this.updateOnline);
  }

  render() {
    return (
      // eslint-disable-next-line no-undef
      <p style={{ color: navigator.onLine ? 'green' : 'darkred' }}>
        {/* eslint-disable-next-line no-undef */}
        {navigator.onLine ? <CheckCircleIcon /> : <RemoveCircleIcon />}
        {/* eslint-disable-next-line no-undef */}
        {navigator.onLine ? ' Currently Online' : ' Currently Offline'}
      </p>
    );
  }
}

export default OnlineNotifier;
