import { useContext } from 'react';
import { ActiveTabContext } from '../Providers/activeTabProvider';

export default () => {
  const {
    getActiveTab,
    setActiveTab,
  } = useContext(ActiveTabContext);
  return {
    getActiveTab,
    setActiveTab,
  };
};
