import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Alert,
} from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import bcrypt from 'bcryptjs-react';
import LoadingSpinner from './common/LoadingSpinner';
import Navigation from './Navigation';

import useWebService from '../hooks/useWebService';
import useAuthProvider from '../hooks/useAuthProvider';

// eslint-disable-next-line react/function-component-definition
const LoginPage = () => {
  const { login } = useWebService();
  const { handleOfflineUserLogin, getHashInfo, getUserInfo } = useAuthProvider();
  const auth = getUserInfo();

  const [loading, setLoading] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.authenticated === true) {
      // don't need to be on login page if logged in
      navigate('/');
    }
  });

  const loginFunc = () => {
    setLoading(true);
    setErrorMsg('');
    // if a user logs out while they're offline, store a hash when they last logged in and
    // compare to it to allow them to log in again.
    // eslint-disable-next-line no-undef
    if (!navigator.onLine) {
      const hashcheck = getHashInfo() === undefined ? '' : getHashInfo();
      if (bcrypt.compareSync(`${username}:${password}`, hashcheck)) {
        navigate('/');
      }
    } else {
      login(username, password).then((val) => {
        setLoading(false);
        if (val?.response?.status !== 200) {
          setErrorMsg('Unable to log in');
          return false;
        }
        const newHash = bcrypt.hashSync(`${username}:${password}`, 10);
        handleOfflineUserLogin(val.body, newHash);
        navigate('/');
        return true;
      }).catch((err) => {
        setLoading(false);
        setErrorMsg(err.message);
        return false;
      });
    }
  };

  const onUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="LoginPage">
      <Navigation />
      <Grid>
        <Row>
          <Col md={3} />
          {' '}
          <Col md={6}>
            <Box>
              <span>
                Login
              </span>
              <div className="formArea">
                <TextField
                  id="standard-basic"
                  label="username"
                  variant="standard"
                  type="email"
                  required
                  onChange={onUserNameChange}
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="standard"
                  type="password"
                  required
                  onChange={onPasswordChange}
                />
                <Button type="button" onClick={loginFunc}>
                  Log In
                </Button>
              </div>
              {loading && <LoadingSpinner centered />}
              {errorMsg && (
                <Alert bsStyle="danger">
                  {errorMsg}
                </Alert>
              )}
            </Box>
          </Col>
          <Col md={3} />
          {' '}
        </Row>
      </Grid>
    </div>
  );
};

export default LoginPage;
