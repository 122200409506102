import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ActiveTabContext = createContext({
  getActiveTab: () => 'context not defined',
  setActiveTab: () => 'context not defined',
});

function ActiveTabProvider({ children }) {
  const [activeTab, setAT] = useState(1);

  const getActiveTab = () => activeTab;
  const setActiveTab = (newActiveTab) => setAT(newActiveTab);

  return (
    <ActiveTabContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getActiveTab,
        setActiveTab,
      }}
    >
      {children}
    </ActiveTabContext.Provider>
  );
}

ActiveTabProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActiveTabProvider;
