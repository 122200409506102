/* eslint-disable react/jsx-props-no-spreading */
/**
 * User can pick the depth of their next shits.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

import './IntervalDepthSelector.css';

import useIntervalDescriptionFormProvider from '../../../hooks/useIntervalDescriptionFormProvider';
import useFormProvider from '../../../hooks/useFormProvider';

function IntervalDepthSelector({
  topValid,
  setTopValid,
  bottomValid,
  setBottomValid,
  intervalIndex,
  isEdit,
}) {
  const {
    changeForm,
    getFormValue,
    isInAnotherRange,
  } = useIntervalDescriptionFormProvider();

  const {
    getFormValue: getMainFormValue,
  } = useFormProvider();

  return (
    <Grid className="DepthSelector">
      <Row>
        <Col xs={3}>
          <span>Units M</span>
          <div>
            <Checkbox
              onChange={(event) => changeForm('units', event.target.checked ? 'metric' : 'imperial')}
              checked={getFormValue('units') === 'metric'}
            />
          </div>
        </Col>
        <Col xs={4}>
          <InputLabel id="client-select-label">Top*</InputLabel>
          <TextField
            id="intervalTop"
            error={!topValid}
            value={getFormValue('intervalTop')}
            onChange={(event) => {
              setTopValid(!isInAnotherRange(getMainFormValue('intervals'), intervalIndex, event.target.value, isEdit));
              changeForm('intervalTop', Number(event.target.value));
            }}
            type="number"
            name="intervalTop"
            required
          />
        </Col>
        <Col xs={4}>
          <InputLabel id="client-select-label">Bottom*</InputLabel>
          <TextField
            type="number"
            error={!bottomValid}
            name="interval_bottom"
            step="any"
            value={getFormValue('intervalBottom')}
            onChange={(event) => {
              setBottomValid(!isInAnotherRange(getMainFormValue('intervals'), intervalIndex, event.target.value, isEdit));
              changeForm('intervalBottom', Number(event.target.value));
            }}
            required
          />
        </Col>
      </Row>
    </Grid>
  );
}

IntervalDepthSelector.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  intervalIndex: PropTypes.number.isRequired,
  topValid: PropTypes.bool.isRequired,
  setTopValid: PropTypes.func.isRequired,
  bottomValid: PropTypes.bool.isRequired,
  setBottomValid: PropTypes.func.isRequired,
};

export default IntervalDepthSelector;
