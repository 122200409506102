import { useContext } from 'react';
import { DbCacheContext } from '../Providers/dbCacheProvider';

export default () => {
  const {
    getDbCache,
    cacheOffline,
    changeSyncInfo,
  } = useContext(DbCacheContext);
  return {
    getDbCache,
    cacheOffline,
    changeSyncInfo,
  };
};
