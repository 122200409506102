import { useContext } from 'react';
import { FormContext } from '../Providers/formProvider';

export default () => {
  const {
    getForm,
    changeForm,
    getFormValue,
    clearForm,
    runWarnings,
    validateFields,
    getErrors,
    changeFormMultiple,
    initialize,
    duplicate,
  } = useContext(FormContext);
  return {
    getForm,
    changeForm,
    getFormValue,
    clearForm,
    runWarnings,
    validateFields,
    getErrors,
    changeFormMultiple,
    initialize,
    duplicate,
  };
};
