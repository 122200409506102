import React from 'react';
import propTypes from 'prop-types';
import './SubmissionFilterControls.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterControlsTypes from './FilterControlsTypes';

function SubmissionFilterControls({
  sortedBy,
  setSortedBy,
  sortedDirection,
  setSortedDirection,
  searchValue,
  setSearchValue,
  onSearch,
}) {
  const onSearchKeyDown = (event) => {
    if (event.key === 'Enter') onSearch();
  };

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onSearchSubmit = () => {
    onSearch();
  };

  const onSortChange = (event) => {
    if (event.target.value !== sortedBy) {
      setSortedBy(event.target.value);
    }
  };

  const onArrowClick = (setDown) => {
    setSortedDirection(setDown
      ? FilterControlsTypes.DIRECTION_DOWN
      : FilterControlsTypes.DIRECTION_UP);
  };

  return (
    <div className="submissionFilterControls">
      <div className="submissionSortByArea">
        <span className="searchLabel">Sort By</span>
        <select value={sortedBy} className="sortBySelect" onChange={onSortChange}>
          <option value={FilterControlsTypes.ALPHABETICAL}>Alphabetical</option>
          <option value={FilterControlsTypes.DATE}>Date</option>
        </select>
        {sortedDirection === FilterControlsTypes.DIRECTION_DOWN
          ? (
            <ArrowDownwardIcon
              className="direction"
              onClick={() => onArrowClick(false)}
            />
          )
          : (
            <ArrowUpwardIcon
              className="direction"
              onClick={() => onArrowClick(true)}
            />
          )}
      </div>
      <div className="submissionSearchArea">
        <span className="searchLabel">Search Sites</span>
        <div className="searchInputArea">
          <input
            onKeyDown={onSearchKeyDown}
            value={searchValue}
            className="searchInput"
            onChange={onSearchChange}
            placeholder="Search previous submissions"
          />
          <button
            type="button"
            onClick={onSearchSubmit}
            className="searchButton"
            value={searchValue}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

SubmissionFilterControls.propTypes = {
  sortedBy: propTypes.oneOf([
    FilterControlsTypes.DATE, FilterControlsTypes.ALPHABETICAL]).isRequired,
  setSortedBy: propTypes.func.isRequired,
  sortedDirection: propTypes.oneOf([
    FilterControlsTypes.DIRECTION_DOWN, FilterControlsTypes.DIRECTION_UP]).isRequired,
  setSortedDirection: propTypes.func.isRequired,
  searchValue: propTypes.string.isRequired,
  onSearch: propTypes.func.isRequired,
  setSearchValue: propTypes.func.isRequired,
};

export default SubmissionFilterControls;
