import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart, Area, Label, ReferenceLine, YAxis,
} from 'recharts';

class ReviewSampleChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // If we don't have data, don't render. Easy.
    const {
      sampleGridList,
      maxDepth,
    } = this.props;
    if (!sampleGridList || sampleGridList.length < 1) return (<div />);

    /**
     * The data we have is constructed as a single object rather than array of them.
     * This is the only way that stacked bar charts are supported.
     * So, we're feeding the BarChart a list with one object and a bunch of index-like keys.
     */
    const data = {};
    const refs = [];
    sampleGridList.forEach((val, idx) => {
      data[idx] = val.value;
      refs.push({
        top: val.top, ec: val.ec, lab: val.lab, ova: val.ova,
      });
    });

    const areas = sampleGridList.map((item, idx) => {
      const idkey = Math.random();
      // Create the area.
      return (
        <Area
          key={idkey}
          name={item.top}
          dataKey={idx}
          dot={false}
          baseLine={8}
          isAnimationActive={false}
        />
      );
    });

    const refLines = refs.map((val) => {
      const idkey = Math.random();
      const labelText = `${val.ec ? `EC (dS/m): ${val.ec}` : ''}
      ${val.ova ? `VOC (ppm): ${val.ova}` : ''}
      ${val.lab ? `Lab: ${val.lab}` : ''}`;
      // var labelText = 'EC: ' + val.ec + 'OVA: ' + val.ova + 'Lab: ' + val.lab
      return (
        <ReferenceLine key={idkey} y={val.top} stokeWidth={1} stroke="red">
          <Label value={labelText} fill="red" fontSize={12} position="bottom" />
        </ReferenceLine>
      );
    });

    return (
      <div>
        {/*   Have to duplicate data in order to fill the colour of the intervals */}
        <AreaChart
          width={240}
          height={1000}
          data={[data, data]}
          margin={{
            top: 20, right: 0, bottom: 20, left: 0,
          }}
        >
          {maxDepth !== 0
          && (
            <YAxis
              width={0}
              padding={{
                top: 0, bottom: 0, left: 0, right: 0,
              }}
              tick={false}
              domain={[maxDepth, 0]}
            />
          )}
          {areas}
          {refLines}
        </AreaChart>
      </div>
    );
  }
}

ReviewSampleChart.propTypes = {
  sampleGridList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxDepth: PropTypes.number,
};

ReviewSampleChart.defaultProps = {
  maxDepth: 0,
};

export default ReviewSampleChart;
