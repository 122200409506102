/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'; // Table
import BoreholeDescription from '../BoreholeDescription';
// import FieldGridRow from './FieldGridRow';
import useFormProvider from '../../../hooks/useFormProvider';
import './FieldGrid.css';
import FieldSampleItem from './FieldSampleItem';

/**
 * The list of requirements
 * @param       {Object} props
 * @constructor
 */
function FieldSamplesComponent(props) {
  const {
    scrollToBottom,
    messageRef,
  } = props;
  const { getFormValue, changeForm } = useFormProvider();
  const sampleCollection = getFormValue('sampleCollection');

  const onAdd = () => {
    const newSampleCollection = [...getFormValue('sampleCollection')];
    newSampleCollection.push({
      sample: newSampleCollection.length + 1,
      depthTop: 0,
      depthBottom: 0,
      ec: '',
      ova: '',
      bag: '',
      jar: '',
      vial: '',
      lab: false,
      comment: '',
    });
    changeForm('sampleCollection', newSampleCollection);
  };

  const onDeleteAll = () => {
    changeForm('sampleCollection', []);
  };

  return (
    <>
      <div className="rem-buttons">
        <style>
          {`
        .rem-buttons {
          background: #fff;
          width: 100%;
          position:sticky;
          position: -webkit-sticky;
          top:0;
          z-index: 10 !important;
        }
        .rem-buttons * {
          z-index: 10 !important;
          margin-top: 10px;
          margin-right: 10px;
        }
      `}
        </style>
        <Button className="addMoreSamplesButton" bsStyle="primary" onClick={() => { onAdd(); }} style={{ height: '60px' }}>Add More Samples</Button>
        <Button className="scrollToBottomButton" onClick={() => { scrollToBottom(); }} style={{ height: '60px' }}>Scroll to Bottom</Button>
        <Button className="deleteAllSamplesButton" onClick={() => onDeleteAll()} style={{ float: 'right' }}>Delete All</Button>
        <hr />
      </div>

      <div className="rem-table">
        {sampleCollection.map((item, idx) => (
          <FieldSampleItem item={item} index={idx} key={item} />
        ))}
        <div ref={messageRef} />
      </div>
    </>
  );
}

function FieldGrid(props) {
  const { selectedProject } = props;
  const messagesEnd = useRef();

  const scrollToBottom = () => {
    messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <BoreholeDescription
        selectedProject={selectedProject}
      />
      <FieldSamplesComponent
        scrollToBottom={scrollToBottom}
        messageRef={messagesEnd}
      />
    </div>
  );
}

FieldGrid.propTypes = {
  selectedProject: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

FieldSamplesComponent.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
  messageRef: PropTypes.string.isRequired,
};

export default FieldGrid;
