export default (submission, duplicate) => ({
  atComments: submission.atComments,
  borehole: submission.sampleLocation?.sampleLocName,
  boreholeComment: submission.sampleLocation?.boreholeComment,
  userApec: submission.apec?.manual ? submission.apec?.name : '',
  workContextComment: submission.sampleLocation?.workContextComment,
  apec: !submission.apec?.manual ? submission.apec?.id : '',
  apecName: !submission.apec?.manual ? submission.apec?.name : '',
  classSchema: 'schema_cssc',
  id: !duplicate && submission.submission.id,
  submitted: !duplicate && !!submission.submission.id,
  client: submission.submission?.clientId,
  clientid: submission.submission?.clientId,
  dateTime: new Date(parseInt(submission.sampleLocation.createdAt, 10)),
  sample_loc_datetime: new Date(parseInt(submission.sampleLocation.sampleLocDatetime, 10)),
  drillingName: submission.sampleLocation?.drillingName,
  impacted: submission.sampleLocation?.impacted,
  drillingMethod: submission.sampleLocation?.drillingMethod,
  fieldMeasurements: [],
  intervalGaps: '',
  // Above interval information comes from where?
  intervals: submission.intervals.map((interval) => ({
    ...interval,
    sma_submission_id: interval.smaSubmissionId,
    intervalTop: interval.intervalTop,
    intervalBottom: interval.intervalBottom,
    plasticity: interval.plasticity,
    moisture: interval.moisture,
    colour: interval.color,
    colour1: interval.color1,
    colour2: interval.color2,
    colour3: interval.color3,
    consistence: interval.consistence,
    intervalComment: interval.intervalComment,
    classSchema: interval.classSchema,
    fragmentShape: interval.fragmentShape,
    fragmentModifier: interval.fragmentModifier,
    fragmentFragment: interval.fragmentFragment,
    fragmentAdjective: interval.fragmentAdjective,
    fragmentTexture: interval.fragmentTexture,
    soilHorizon: interval.soilHorizon,
    mottleAbundance: interval.mottleAbundance,
    mottleSize: interval.mottleSize,
    mottles: submission.mottles.filter((mottle) => mottle.intervalId === interval.id),
    deposition: interval.deposition,
    primaryConstituent: interval.primaryConstituent,
    primaryProportion: interval.primaryProportion,
    secondaryConstituent: interval.secondaryConstituent,
    secondaryProportion: interval.secondaryProportion,
    tertiaryConstituent: interval.tertiaryConstituent,
    tertiaryProportion: interval.tertiaryProportion,
    grading: interval.grading,
    angularity: interval.angularity,
    odour: interval.odour,
    primarySoil: interval.primarySoil,
    observation: interval.observation,
    geneticTerms: interval.geneticTerms,
    texture: interval.texture,
    structure: interval.structure,
    soilFractures: interval.soilFractures,
    soilFragments: interval.soilFragments,
    soilFragments_modifier: interval.soilFragmentsModifier,
    soilDisturbance: interval.soilDisturbance,
    mottleDescription: interval.mottleDescription,
  })),
  latitude: submission.sampleLocation.latitude.toString(),
  longitude: submission.sampleLocation.longitude.toString(),
  logger: submission.sampleLocation?.logger.toString(),
  manual: true,
  manualSites: [],
  name: submission.sampleLocation.sampleLocName,
  precipitation: submission.sampleLocation.precipitation,
  project: submission.submission.projectId,
  projectId: submission.submission.projectId,
  sampleCollection: submission.fieldSamples.map((sample) => (duplicate
    ? ({
      id: sample.id,
      sample: sample.sampleId,
      number: sample.number,
      depthTop: sample.depth_top,
      depthBottom: sample.depth_bottom,
    })
    : {
      ...sample,
      sample: sample.sampleId,
      depthTop: sample.depth_top,
      depthBottom: sample.depth_bottom,
    })),
  samples: [], // Depricated
  site: submission.site.manual ? '' : submission.submission?.siteId,
  siteName: submission.site.name,
  temperature: submission.sampleLocation?.temperature,
  units: 'metric', // Stay hard coded
  // user_Site: submission.site.manual ? submission.site.name : '',
  userSite: submission.site.manual ? submission.site.name : '',
  weatherDescription: submission.sampleLocation?.weatherDescription,
  windDirection: submission.sampleLocation?.windDirection,
  windSpeed: submission.sampleLocation?.windSpeed,
  submissionId: duplicate ? '' : submission.submission?.id,
  sampleLocationId: submission.sampleLocation?.id,
});
