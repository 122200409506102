import React, { createContext, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import PropTypes from 'prop-types';

import useAuthProvider from '../hooks/useAuthProvider';
import WebService from '../logic/WebService';

export const WebserviceContext = createContext({
  getWebService: () => 'context not defined',
  getGqlClient: () => 'context not defined',
  mutate: () => 'context not defined',
  post: () => 'context not defined',
  get: () => 'context not defined',
  login: () => 'context not defined',
  logout: () => 'context not defined',
  query: () => 'context not defined',
});

function WebserviceProvider({ children }) {
  const { handleOfflineUserLogout } = useAuthProvider();
  const [gqlClient] = useState(new ApolloClient({
    uri: `${process.env.REACT_APP_API_ENDPOINT || ''}/graphql`,
    cache: new InMemoryCache(),
  }));
  const [webservice] = useState(WebService);

  // For URL-encoding.
  const queryParams = (params) => {
    if (!params) return '';
    return Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
  };

  // Handles the result of an API call.
  // Gives a promise returning {response: <data>, body: <data>}
  const handleResult = (response) => {
    const retVal = { response, body: {} };
    return response.json()
      .then((val) => {
        retVal.body = val;

        return retVal;
      })
      .catch(() => retVal);
  };

  const mutate = (mutation, variables) => gqlClient.mutate({
    mutation,
    variables,
  }).then((result) => result).catch((error) => error);
  const reactEnvironment = process.env.REACT_APP_API_ENDPOINT || '';
  const login = (username, password) => fetch(`${reactEnvironment}/api/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username,
      password,
    }),
    credentials: 'include',
  }).then(handleResult);

  const logout = async () => {
    // eslint-disable-next-line no-undef
    if (!navigator.onLine) {
      handleOfflineUserLogout();
    } else {
      const environment = process.env.REACT_APP_API_ENDPOINT || '';
      try {
        const response = await fetch(
          `${environment}/api/logout`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          },
        );
        handleResult(response);
        handleOfflineUserLogout();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error logging out', error);
      }
    }
  };

  // Generic GET. Probably shouldn't be used.
  const get = (url, params) => {
    let urlValue = url;
    if (params) {
      urlValue += (urlValue.indexOf('?') === -1 ? '?' : '&') + queryParams(params);
    }

    return fetch(urlValue, {
      method: 'GET',
      credentials: 'include',
    }).then(handleResult).catch((err) => err);
  };

  // Generic POST. Probably shouldn't be used.
  const post = (url, body, params) => {
    let urlValue = url;
    if (params) {
      urlValue += (urlValue.indexOf('?') === -1 ? '?' : '&') + queryParams(params);
    }

    return fetch(urlValue, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }).then(handleResult).catch((err) => err);
  };

  const getWebService = () => (webservice);
  const getGqlClient = () => (webservice);

  return (
    <WebserviceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getWebService,
        getGqlClient,
        mutate,
        post,
        get,
        login,
        logout,
      }}
    >
      {children}
    </WebserviceContext.Provider>
  );
}

WebserviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WebserviceProvider;
