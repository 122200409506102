import React, { useState, useEffect } from 'react';
import useWebService from '../../hooks/useWebService';
import { getUsersAndData, createUserQuery } from './queries';
import './UserAdminForm.css';

const inputMap = {
  consultantId: 'consultantId',
  email: 'email',
  roleId: 'roleId',
  password: 'password',
  clientId: 'clientId',
};

function UserAdminForm() {
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState('');
  const [consultantId, setConsultantId] = useState('');
  const [clientId, setClientId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({ consultantId: false, email: false, roleId: false });

  const { getGqlClient } = useWebService();
  const gqlClient = getGqlClient();

  const onInputChange = (newValue, mapValue) => {
    switch (mapValue) {
      case inputMap.consultantId:
        setConsultantId(newValue);
        break;
      case inputMap.email:
        if (data.data.getSmaUsers.find((user) => user.email === newValue)) {
          setErrors({ ...errors, email: true });
        } else if (errors.email) {
          setErrors({ ...errors, email: false });
        }
        setEmail(newValue);
        break;
      case inputMap.password:
        setPassword(newValue);
        break;
      case inputMap.clientId:
        setClientId(newValue);
        break;
      case inputMap.roleId:
        setRoleId(newValue);
        break;
      default:
    }
  };

  const renderTextField = ({ label, value, mapValue }) => (
    <tr className="textArea">
      <td>
        <span className="textLabel">{label}</span>
        <input
          className="textInput"
          value={value}
          onChange={(event) => onInputChange(event.target.value, mapValue)}
        />
      </td>
    </tr>
  );

  const clearData = () => {
    setEmail('');
    setRoleId('');
    setConsultantId('');
    setPassword('');
    setClientId('');
  };

  const onSubmitForm = async () => {
    const response = await gqlClient.mutate(
      createUserQuery,
      {
        email,
        password,
        role_id: parseInt(roleId, 10),
        consultant_id: consultantId,
        client_id: clientId,
      },
    );
    if (!response) {
      setErrorMessage('Failed to create site');
    } else {
      clearData();
    }
  };

  const onRoleChange = (value) => {
    if (value === '3') {
      setConsultantId('');
    } else {
      setClientId('');
    }
    setRoleId(value);
  };

  const fetchUserCreationData = async () => {
    const dataFromQuery = await gqlClient.query(
      getUsersAndData,
      {},
      { fetchPolicy: 'no-cache' },
    );
    setData(dataFromQuery);
  };

  useEffect(() => {
    fetchUserCreationData();
  }, []);

  // const getConsultantById = (id) => data.data?.getConsultants?
  // .find((consultant) => consultant.id === id);

  return (
    <>
      <div className="UserAdminForm">
        <h3 className="Header">User Form</h3>
        <table>
          <thead>
            <tr>
              <th className="LabelCol">Label</th>
              <th className="inputCol">Input</th>
            </tr>
          </thead>
          <tbody>
            <tr className="projectArea">
              <td>
                <span className="projectLabel">Select User Consultant: </span>
              </td>
              <td>
                <select className="SelectInput" value={consultantId} onChange={(event) => onInputChange(event.target.value, inputMap.consultantId)}>
                  <option value="NONE">Unselected</option>
                  {data.data?.getConsultants?.map((consultant) => (
                    <option key={consultant.id} value={consultant.id}>{consultant.name}</option>
                  ))}
                </select>
              </td>
            </tr>
            {renderTextField({ label: 'Email: ', value: email, mapValue: inputMap.email })}
            {renderTextField({ label: 'Role Id: ', value: roleId, mapValue: inputMap.roleId })}
            {renderTextField({ label: 'Password: ', value: password, mapValue: inputMap.password })}
          </tbody>
        </table>
        <button type="button" onClick={onSubmitForm} disabled={errors.email || errors.consultantId || errors.roleId}>Create</button>
        <span>{errorMessage}</span>
      </div>
      <span>Users</span>
      <table className="userList">
        <thead>
          <tr>
            <th className="LabelCol">Id</th>
            <th className="InputCol">Email</th>
            <th className="InputCol">Role Id</th>
            <th className="InputCol">Consultant</th>
          </tr>
        </thead>
        <tbody>
          {
          roleId !== '3' && (
            <tr className="projectArea">
              <td>
                <span className="projectLabel">Select User Consultant: </span>
              </td>
              <td>
                <select className="SelectInput" value={consultantId} onChange={(event) => onInputChange(event.target.value, inputMap.consultantId)}>
                  <option value="NONE">Unselected</option>
                  {data.data?.getConsultants?.map((consultant) => (
                    <option key={consultant.id} value={consultant.id}>{consultant.name}</option>))}
                </select>
              </td>
            </tr>
          )
        }
          {
          roleId === '3' && (
            <tr className="clientArea">
              <td>
                <span className="clientLabel">Select a client for this user to be associated with: </span>
              </td>
              <td>
                <select className="SelectInput" value={clientId} onChange={(event) => onInputChange(event.target.value, inputMap.clientId)}>
                  <option value="NONE">Unselected</option>
                  {data.data?.getClients?.map((client) => (
                    <option key={client.id} value={client.id}>{client.name}</option>))}
                </select>
              </td>
            </tr>
          )
        }
          {renderTextField({ label: 'Email: ', value: email, mapValue: inputMap.email })}
          <tr className="textArea">
            <td>
              <span className="textLabel">Role Id:</span>
              <input
                className="textInput"
                value={roleId}
                onChange={(event) => onRoleChange(event.target.value)}
              />
            </td>
          </tr>
          {renderTextField({ label: 'Password: ', value: password, mapValue: inputMap.password })}
        </tbody>
      </table>
      <button type="button" onClick={onSubmitForm} disabled={!errors.email && (errors.consultantId || errors.roleId)}>Create</button>
      <span>{errorMessage}</span>
    </>
  );
}

export default UserAdminForm;
