/* eslint-disable no-await-in-loop */
import buildIntervalForSubmission from './buildIntervalForSubmission';
import {
  mottleQuery,
  updateMottleQuery,
  deleteMottlesNotInList,
} from './queries';

export default async ({
  intervals,
  isSubmissionEdit,
  mutate,
  intervalQuery,
  updateIntervalQuery,
  smaSubId,
}) => {
  const intervalIds = [];

  for (let i = 0; i < intervals.length; i += 1) {
    const intervalSubVar = buildIntervalForSubmission(intervals[i], smaSubId);

    const intervalResult = isSubmissionEdit && intervals[i].id
      ? await mutate(updateIntervalQuery, { ...intervalSubVar, id: intervals[i].id })
      : await mutate(intervalQuery, intervalSubVar);

    const intervalId = isSubmissionEdit && intervals[i].id
      ? intervalResult.data.updateInterval
      : intervalResult.data.createInterval;
    if (!intervalId) {
      throw new Error('Interval submission failed');
    }
    intervalIds.push(intervalId);
    const mottleIds = [];

    for (let j = 0; j < intervals[i].mottles.length; j += 1) {
      const currentInterval = intervals[i];
      const currentMottle = currentInterval.mottles[j];

      const mottleVars = {
        abundance: currentMottle.abundance,
        size: currentMottle.size,
        color: currentMottle.colour,
        contrast: currentMottle.contrast,
        type: currentMottle.type,
        intervalId,
      };
      const isExistingMottle = isSubmissionEdit && currentMottle.id;
      const mottleResult = isExistingMottle
        ? await mutate(updateMottleQuery, { ...mottleVars, id: currentMottle.id })
        : await mutate(mottleQuery, mottleVars);

      const mottleId = isSubmissionEdit && currentMottle.id
        ? mottleResult.data.updateMottle
        : mottleResult.data.createMottle;
      mottleIds.push(mottleId);
    }
    await mutate(deleteMottlesNotInList, { ids: mottleIds, intervalId });
  }

  return intervalIds;
};
