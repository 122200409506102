import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ErrorsContext = createContext({
  getFormErrors: () => 'context not defined',
  addFormError: () => 'context not defined',
  clearFormErrors: () => 'context not defined',
  removeFormError: () => 'context not defined',
  getSubmssionError: () => 'context not defined',
  setSubmissionError: () => 'context not defined',
});

function ErrorsProvider({ children }) {
  const [formErrors, setFormErrors] = useState([]);
  const [submissionError, setSubmissionError] = useState('');

  const getSubmssionError = () => submissionError;

  const getFormErrors = () => formErrors;

  const addFormError = (key, value) => {
    const newFormErrors = [...formErrors];
    newFormErrors.push({ name: key, message: value });
    setFormErrors(newFormErrors);
  };

  const clearFormErrors = () => setFormErrors([]);

  const removeFormError = (fieldName) => {
    let newFormErrors = [...formErrors];
    newFormErrors = formErrors.filter((error) => error.name !== fieldName);
    setFormErrors(newFormErrors);
  };

  return (
    <ErrorsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getFormErrors,
        addFormError,
        clearFormErrors,
        removeFormError,
        setSubmissionError,
        getSubmssionError,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  );
}

ErrorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorsProvider;
