/**
 * Stores static information for the app.
 * Used mostly for dropdown menus forms and the like.
 */

export const SchemaType = [
  'schema_uscs',
  'schema_cssc',
  'schema_custom1',
];

export const SchemaTypeName = {
  schema_uscs: 'USCS',
  schema_cssc: 'CSSC',
  schema_custom1: 'Custom 1',
};

export const SampleMatrixList = [
  'Water',
  'Soil',
];

export const DrillMethods = [
  'Hand Auger',
  'Hollow Stem Auger',
  'Solid Stem Auger',
  'Air Rotary',
  'Mud Rotary',
  'Core Sampling',
  'Split Spoon Sampling',
  'Sonic',
  'Direct Push',
  'Test Pit',
];

export const WeatherDescriptions = [
  'Sunny',
  'Overcast',
  'Raining',
  'Snowing',
  'Partly Cloudy',
];

export const WindDirections = [
  'N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW',
];

export const WindSpeeds = [
  '<10', '10-20', '>20',
];

export const PrecipitationValues = [
  '<2', '2-5', '>5',
];

export const TemperatureValues = [
  -40, -35, -30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35,
];

export const WaterTypes = [
  { name: 'Deionized Water', key: 'WaterAAA1' },
  { name: 'Organic Free Water', key: 'WaterAAA2' },
];

export const QAQCTypes = [
  { name: 'Spike', key: 'QAQC_SPIKE' },
  { name: 'Field Blank', key: 'QAQC_FIELD_BLANK' },
  { name: 'Trip Blank', key: 'QAQC_TRIP_BLANK' },
  { name: 'Equipment Blank', key: 'QAQC_EQUIPMENT_BLANK' },
];

export const IntervalDescriptors = {
  fragment_shape: ['Rounded', 'Subrounded', 'Angular', 'Irregular'],
  fragment_modifier: ['<5%', '5% - 10%', '10% - 20%', '>20%'],
  fragment_fragment: ['Bouldery', 'Stony', 'Cobbly', 'Gravelly', 'Sandy'],
  fragment_adjective: ['Very Fine', 'Fine', 'Medium', 'Coarse', 'Very Coarse'],
  fragment_adjective_gravelly: ['Fine', 'Medium', 'Coarse'],
  soil_horizon: [
    'Subsoil',
    'TopSoil',
    'A - Surface Horizon',
    'L - Limnic Horizon',
    'F - Faunal accumulations Horizon',
    'H - Accumulated organic matter Horizon',
    'O - Organic Horizon',
    'B - Subsoil Horizon',
    'C - Substratum Horizon',
    'R - Bedrock Horizon',
  ],
  structure: ['Blocky', 'Sub-Angular Blocky', 'Single Grain', 'Massive', 'Granular', 'Platey', 'Prismatic', 'Columnar'],
  fragment_texture: ['Clay', 'Clay Loam', 'Heavy Clay', 'Loam', 'Loamy Sand', 'Organic', 'Sand', 'Sandy Clay', 'Sandy Clay Loam', 'Sandy Loam', 'Silt', 'Silty Clay Loam', 'Silty Loam', 'Silty Clay'],
  mottle_abundance: ['Few', 'Common', 'Many'],
  mottle_size: ['Fine', 'Medium', 'Coarse'],
  mottle_colour: ['Pink', 'Red', 'Orange', 'Yellow', 'Brown', 'Green', 'Blue', 'White', 'Grey', 'Black'],
  mottle_contrast: ['Faint', 'Distinct', 'Prominent'],
  mottle_type: ['Mottle', 'Precipitate', 'Inclusion', 'Oxidation'],
  deposition: ['Till (Morainal)', 'Lacustrine', 'Eolian', 'Fluvial', 'Colluvial', 'Organic', 'Residual', 'Disturbed (Fill)', 'Undisturbed (Natural)'],
  plasticity: ['Non-plastic', 'Slightly Plastic', 'Plastic', 'Very Plastic'],
  moisture: ['Dry', 'Moist', 'Wet'],
  colour1: ['Light', 'Dark'],
  colour2: ['Pinkish', 'Reddish', 'Yellowish', 'Brownish', 'Greenish', 'Bluish', 'Greyish'],
  colour3: ['Pink', 'Red', 'Orange', 'Yellow', 'Brown', 'Green', 'Blue', 'White', 'Grey', 'Black'],
  consistence: ['Non-sticky', 'Slightly Sticky', 'Sticky', 'Very Sticky', 'Very Friable', 'Friable', 'Firm', 'Very Firm', 'Loose', 'Soft', 'Slightly Hard', 'Hard', 'Very Hard', 'Extremely Hard', 'Rigid'],
};

export const USCDescriptors = {
  constituent: ['Boulder', 'Cobble', 'Gravel', 'Sand', 'Silt', 'Clay', 'Organic'],
  primary_size_sand: ['Fine-Grained', 'Medium-Grained', 'Coarse-Grained'],
  primary_size_gravel: ['Fine-Grained', 'Coarse-Grained'],
  secondary_proportion: ['Trace (<10%)', 'Some (10-20%)', 'Adj-y (20-35%)', 'And (>35%)'],
  grading: ['Poorly Graded', 'Well Graded'],
  angularity: ['Angular', 'Subangular', 'Subrounded', 'Rounded'],
  plasticity: ['Non-Plastic', 'Low Plastic', 'Medium Plastic', 'High Plastic'],
  colour: ['Grey', 'Brown', 'Black', 'Tan', 'Yellow', 'Olive', 'Red', 'Rusty'],
  odour: ['Organic', 'Hydrocarbon', 'Chemical'],
  moisture: ['Wet', 'Moist', 'Dry'],
  consistency_plastic: ['Very Soft', 'Soft', 'Firm', 'Hard', 'Very Hard'],
  consistency_gss: ['Very Loose', 'Loose', 'Compact', 'Dense', 'Very Dense'],
  primary_soil: ['Stratified', 'Laminated', 'Fissured', 'Lensed', 'Slickensided', 'Blocky', 'Homogeneous'],
  observation: ['Cementation', 'Salt Crystals', 'Grey Staining', 'Green Staining', 'Black Staining', 'Rusty Staining', 'Other Staining', 'Free Product'],
  genetic_terms: ['Fill', 'Glacial Till', 'Lacustrine', 'Fluvial', 'Eolian', 'Bedrock'],
};

export const Custom1Descriptors = {
  texture: ['S', 'L', 'Si', 'LS', 'CL', 'SiL', 'SL', 'C', 'SiCL', 'SCL', 'HC', 'SiC', 'SC', 'Org'],
  structure: ['Single Grain', 'Amorphous', 'Blocky', 'Subangular Blocky', 'Granular', 'Platy', 'Prismatic', 'Columnar'],
  colour: ['Grey', 'Light Brown', 'Brown', 'Dark Brown', 'Black', 'Tan', 'Yellow', 'Olive', 'Red', 'Rusty'],
  moisture: ['Wet', 'Moist', 'Dry'],
  wet_consistency: ['Non-plastic', 'Slightly Plastic', 'Plastic', 'Very Plastic'],
  moist_consistency: ['Loose', 'Very Friable', 'Friable', 'Firm', 'Very Firm'],
  dry_consistency: ['Loose', 'Soft', 'Slightly Hard', 'Hard', 'Very Hard'],
  mottle_abundance: ['Few', 'Common', 'Many'],
  mottle_size: ['Fine', 'Medium', 'Coarse'],
  mottle_select: ['G', 'M'],
  mottle_contrast: ['Faint', 'Distinct', 'Prominent'],
  // groundwater_level:[],
  soil_fractures: ['Slight', 'Moderate', 'Many'],
  soil_fragments: ['Gravel', 'Cobble', 'Stone', 'Boulder'],
  soil_fragments_modifier: ['Few', 'Many'], // not shown when no selected
  soil_disturbances: ['Disturbed', 'Undisturbed'],
};

export const FragmentTextureColors = {
  Sand: '#1B1B3A',
  'Loamy Sand': '#A06CD5',
  'Sandy Loam': '#6247AA',
  Loam: '#102B3F',
  'Silty Loam': '#062726',
  Silt: '#59A5D8',
  'Silt Clay Loam': '#133C55',
  'Clay Loam': '#84D2F6',
  'Sandy Clay': '#91E5F6',
  'Sandy Clay Loam': '#7DA1D5',
  'Silty Clay': '#48A9A6',
  Clay: '#D4B483',
  'Heavy Clay': '#C1666B',
  Organic: '#3E3F3E',
  'Silty Clay Loam': '#00FF00',
};

export const USCTextureColors = {
  Boulder: '#C1666B',
  Cobble: '#84D2F6',
  Gravel: '#48A9A6',
  Sand: '#1B1B3A',
  Silt: '#59A5D8',
  Clay: '#D4B483',
  Organic: '#133C55',
};
