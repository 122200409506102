import { useContext } from 'react';
import { ErrorsContext } from '../Providers/errorsProvider';

export default () => {
  const {
    getFormErrors,
    addFormError,
    clearFormErrors,
    removeFormError,
    setSubmissionError,
    getSubmssionError,
  } = useContext(ErrorsContext);
  return {
    getFormErrors,
    addFormError,
    clearFormErrors,
    removeFormError,
    setSubmissionError,
    getSubmssionError,
  };
};
