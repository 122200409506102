import { useContext } from 'react';
import { ReviewSiteContext } from '../Providers/reviewSiteProvider';

export default () => {
  const {
    getReviewSite,
    toggleReview,
  } = useContext(ReviewSiteContext);
  return {
    getReviewSite,
    toggleReview,
  };
};
