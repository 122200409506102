import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// eslint-disable-next-line
const AuthGuard = ({
  isAllowed,
  redirectPath = '/login',
  children,
}) => {
  if (!isAllowed) {
    return (<Navigate to={redirectPath} replace />);
  }

  return children;
};

AuthGuard.propTypes = {
  isAllowed: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
