/* eslint-disable no-param-reassign */
// A list that shows our interval depths.
import React from 'react';
import {
  ListGroup, ListGroupItem, Button,
} from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';

import { FragmentTextureColors, USCTextureColors, SchemaType } from '../../../assets/static/models';

function IntervalList({
  schemaType,
  duplicateFunc,
  genHexString,
  editMode,
  deleteFunc,
  editIndex,
  editFunc,
  intervalList,
}) {
  // USC Has its own descriptor function
  const getUSCIntervalDesc = (item) => {
    const descStore = [
      item.primarySize,
      item.primaryConstituent,
      item.secondaryProportion,
      item.secondaryConstituent,
      item.grading,
      item.angularity,
      item.plasticity,
      item.colour,
      item.odour,
      item.moisture,
      item.consistency,
      item.primary_soil,
      item.observation,
      item.geneticTerms,
    ];

    return (
      <span>
        {item.intervalTop}
        m to
        {' '}
        {item.intervalBottom}
        m:
        {' '}
        {' '}
        <span style={{ color: USCTextureColors[item.primaryConstituent] }}>
          <b>
            {descStore.join(' ')}
          </b>
        </span>
      </span>
    );
  };

  const getIntervalDesc = (item) => {
    const fragmentItems = [
      item.fragmentShape,
      item.fragmentModifier,
      item.fragmentFragment,
      item.fragmentAdjective,
      item.structure,
      item.fragmentTexture,
    ].filter((itemLocal) => !!itemLocal);

    const mottleItems = [];

    if (item.mottles) {
      item.mottles.forEach((value) => {
        const temp = [
          value.abundance,
          value.size,
          value.colour,
          value.contrast,
          (value.type ? `${value.type}s` : ''),
        ].filter((localValue) => !!localValue);
        mottleItems.push(temp);
      });
    }

    const otherItems = [
      item.deposition,
      item.plasticity,
      item.colour,
      item.moisture,
      item.consistence,
      item.colour1,
      item.colour2,
      item.colour3,
    ].filter((itemLocal) => !!itemLocal);

    return (
      <span>
        {item.intervalTop}
        m to
        {' '}
        {item.intervalBottom}
        m:
        {' '}
        {' '}
        <span style={{ color: FragmentTextureColors[item.fragmentTexture], textOverflow: 'wrap' }}>
          <b>
            {fragmentItems.join(' ')}
            {fragmentItems.length > 0 && mottleItems.length > 0 && '; '}
            {mottleItems.join(', ')}
            {mottleItems.length > 0 && otherItems.length > 0 && '; '}
            {fragmentItems.length > 0 && mottleItems.length === 0 && otherItems.length > 0 && '; '}
            {otherItems.join(', ')}
            {item.intervalComment ? `; ${item.intervalComment}` : ''}
          </b>
        </span>
      </span>
    );
  };

  const getCustom1Desc = (item) => {
    const fragmentItems = [
      item.texture,
      item.structure,
      item.colour,
      item.moisture,
      item.consitence,
    ].filter((itemLocal) => !!itemLocal);

    const mottleItems = [
      item.mottleAbundance,
      item.mottleSize,
      item.mottleSelect,
      item.mottleContrast,
    ].filter((itemLocal) => !!itemLocal);

    const soilItems = [
      item.soilFractures,
      item.soilFragments,
      item.soilFragmentsModifier,
      item.soilDisturbances,
    ].filter((itemLocal) => !!itemLocal);

    return (
      <span>
        {item.intervalTop}
        m to
        {' '}
        {item.intervalBottom}
        m:
        {' '}
        {' '}
        <span>
          <b>
            {fragmentItems.join(' ')}
            {fragmentItems.length > 0 && mottleItems.length > 0 && '; '}
            {mottleItems.join(' ')}
            {mottleItems.length > 0 && soilItems.length > 0 && '; '}
            {fragmentItems.length > 0 && mottleItems.length === 0 && soilItems.length > 0 && '; '}
            {soilItems.join(', ')}
          </b>
        </span>
      </span>
    );
  };

  const renderIntervalList = (item) => {
    if (!item.id && !item.tempId) {
      item.tempId = genHexString(20);
    }

    return (
      <ListGroupItem
        key={item.id || item.tempId}
        bsStyle={item.id || item.tempId === editIndex ? 'warning' : null}
        style={{ cursor: 'default' }}
        active={item?.tempId === editIndex || item?.id === editIndex}
        value={item.id || item.tempId}
      >
        <div style={{ float: 'right' }} hidden={editMode}>
          <Button
            bsStyle="link"
            className="intervalListButton"
            onClick={() => { deleteFunc(item.id || item.tempId); }}
          >
            <DeleteIcon style={{ color: 'black', cursor: 'pointer' }} />
          </Button>
        </div>
        <div style={{ float: 'right' }} hidden={editMode}>
          <Button
            bsStyle="link"
            className="intervalListButton"
            onClick={() => {
              editFunc(item.id || item.tempId);
            }}
          >
            <CreateIcon style={{ color: 'black', cursor: 'pointer' }} />
          </Button>
        </div>
        <div style={{ float: 'right' }} hidden={editMode}>
          <Button
            bsStyle="link"
            className="intervalListButton"
            onClick={() => { duplicateFunc(item.id || item.tempId); }}
          >
            <RefreshIcon style={{ color: 'black', cursor: 'pointer' }} />
          </Button>
        </div>
        {schemaType === 'schema_uscs' && getUSCIntervalDesc(item)}
        {schemaType === 'schema_cssc' && getIntervalDesc(item)}
        {schemaType === 'schema_custom1' && getCustom1Desc(item)}
      </ListGroupItem>
    );
  };

  return (
    <ListGroup style={{ maxHeight: 300, overflowY: 'auto' }}>
      {intervalList.map((item) => renderIntervalList(item))}
    </ListGroup>
  );
}

IntervalList.propTypes = {
  intervalList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteFunc: PropTypes.func.isRequired,
  schemaType: PropTypes.oneOf(SchemaType),
  duplicateFunc: PropTypes.func.isRequired,
  genHexString: PropTypes.func.isRequired,
  editMode: PropTypes.string.isRequired,
  editIndex: PropTypes.number.isRequired,
  editFunc: PropTypes.func.isRequired,
};

IntervalList.defaultProps = {
  schemaType: 'schema_cssc',
};

export default IntervalList;
