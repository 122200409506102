import { useContext } from 'react';
import { WebserviceContext } from '../Providers/webserviceProvider';

export default () => {
  const {
    getWebService,
    getGqlClient,
    mutate,
    post,
    get,
    login,
    logout,
  } = useContext(WebserviceContext);
  return {
    getWebService,
    getGqlClient,
    mutate,
    post,
    get,
    login,
    logout,
  };
};
