import { useContext } from 'react';
import { EditFormContext } from '../Providers/editFormProvider';

export default () => {
  const {
    getIsInFormEdit,
    toggleEdit,
  } = useContext(EditFormContext);
  return {
    getIsInFormEdit,
    toggleEdit,
  };
};
