import { useContext } from 'react';
import { BoreholeListContext } from '../Providers/boreholeListProvider';

export default () => {
  const {
    getBoreholeList,
    setBoreholeList,
    clearBoreholeList,
  } = useContext(BoreholeListContext);
  return {
    getBoreholeList,
    setBoreholeList,
    clearBoreholeList,
  };
};
