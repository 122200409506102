/**
 * Interface that handles submission to the GraphQL Endpoint.
 * Give it a saved form to submit, and it'll handle the rest.
 */
import LocalDbService from './LocalDbService';
import { submit } from './submittables/SampleLocation';

function SubmissionService(formKey, dbCache, mutate, gqlClient) {
  return LocalDbService.get(formKey).then((result) => {
    // eslint-disable-next-line no-console
    console.log('Got form info, creating submittable.');
    return submit(mutate, dbCache, result, gqlClient);
  });
}

export default SubmissionService;
