/**
 * Main application container. Stuff common to all views.
 */
import React, { useEffect } from 'react';
import Sidebar from 'react-sidebar';
import {
  Routes,
  Route,
} from 'react-router-dom';

import SidePanel from './components/SidePanel';

import useAuthProvider from './hooks/useAuthProvider';
import useSidePanelProvider from './hooks/useSidePanelOpenProvider';
import useOfflineServiceProvider from './hooks/useOfflineServiceProvider';

// Pages
import LoginPage from './components/LoginPage';
import FieldMeta from './components/field/FieldMeta';
import FieldReview from './components/field/Review';
import SubmissionPage from './components/submission/SubmissionPage';
import CoCPage from './components/submission/lab-coc/CoCPage';
import AdminPage from './components/admin/AdminPage';
import AuthGuard from './guards/AuthGuard';
// import LoadingSpinner from './components/common/LoadingSpinner';

function App() {
  const { toggleSidePanel, getSidePanelOpen } = useSidePanelProvider();
  const sidePanelOpen = getSidePanelOpen();
  const { getUserInfo } = useAuthProvider();
  const auth = getUserInfo();
  const { startSyncInterval } = useOfflineServiceProvider();

  useEffect(() => {
    if (auth?.authenticated === true) {
      startSyncInterval(); // Get that DB to syncing.
    }
  });

  const sidebarStyle = {
    sidebar: {
      background: '#FFFFFF',
      width: '250px',
      zIndex: 100,
    },
  };

  return (
    <div className="App">
      <Sidebar
        sidebar={<SidePanel />}
        open={sidePanelOpen}
        onSetOpen={() => toggleSidePanel()}
        styles={sidebarStyle}
        pullRight
      >
        <div className="App-body">
          <div className="children">
            <Routes>
              <Route
                path="/login"
                element={<LoginPage />}
              />
              <Route
                path="/"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.authenticated}
                  >
                    <FieldMeta />
                  </AuthGuard>
                )}
              />
              <Route
                path="/field"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.authenticated}
                  >
                    <FieldMeta />
                  </AuthGuard>
                )}
              />
              <Route
                path="/field/review"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.authenticated}
                  >
                    <FieldReview />
                  </AuthGuard>
                )}
              />
              <Route
                path="/submission"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.authenticated}
                  >
                    <SubmissionPage />
                  </AuthGuard>
                )}
              />
              <Route
                path="/coc"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.authenticated}
                  >
                    <CoCPage />
                  </AuthGuard>
                )}
              />
              <Route
                path="/admin"
                element={(
                  <AuthGuard
                    redirectPath="/login"
                    isAllowed={auth?.adminAuthenticated}
                  >
                    <AdminPage />
                  </AuthGuard>
                )}
              />
            </Routes>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default App;
