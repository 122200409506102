import React, { useState } from 'react';
import {
  Button, Modal, Row, Col,
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import useIntervalDescriptionFormProvider from '../../../hooks/useIntervalDescriptionFormProvider';
import { IntervalDescriptors } from '../../../assets/static/models';

function DescriptorDropdown(props) {
  const {
    label, items, value, setValue,
  } = props;

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Select
        className="selectFullWidth"
        value={value}
        disabled={items.length === 0}
        onChange={(event) => setValue(event.target.value)}
      >
        <MenuItem value="">N/A</MenuItem>
        {items.map((texture) => (
          <MenuItem key={texture} value={texture}>
            {texture}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

DescriptorDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

// This component is a button with a popup modal.
function NewMottle({
  editIndex,
  setEditIndex,
}) {
  const {
    changeForm,
    getFormValue,
  } = useIntervalDescriptionFormProvider();
  const [show, setShow] = useState(false);
  const [abundance, setAbundance] = useState(editIndex ? getFormValue('mottles')[editIndex].abundance : '');
  const [size, setSize] = useState(editIndex ? getFormValue('mottles')[editIndex].size : '');
  const [colour, setColour] = useState(editIndex ? getFormValue('mottles')[editIndex].colour : '');
  const [contrast, setContrast] = useState(editIndex ? getFormValue('mottles')[editIndex].contrast : '');
  const [type, setType] = useState(editIndex ? getFormValue('mottles')[editIndex].type : '');

  const onReset = () => {
    setAbundance('');
    setSize('');
    setColour('');
    setContrast('');
    setType('');
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const allowAdd = () => (abundance || size || colour || contrast || type);

  const addMottle = () => {
    const previousMottles = getFormValue('mottles');
    const newList = previousMottles ? [...getFormValue('mottles')] : [];
    newList.push({
      abundance,
      size,
      colour,
      contrast,
      type,
    });
    changeForm('mottles', newList);
    onReset();
    setEditIndex(null);

    setShow(false);
  };

  return (
    <div>
      <Button onClick={handleShow} bsStyle="primary" block style={{ width: '100%', height: '60px' }}>Add Mottle</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mottle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid className="nested-grid">
            <Row>
              <Col xs={12} sm={6} md={6}>
                <DescriptorDropdown items={IntervalDescriptors.mottle_abundance} value={abundance} setValue={setAbundance} label="Abundance" />
                <DescriptorDropdown items={IntervalDescriptors.mottle_size} value={size} setValue={setSize} label="Size" />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <DescriptorDropdown items={IntervalDescriptors.mottle_contrast} value={contrast} setValue={setContrast} label="Contrast" />
                <DescriptorDropdown items={IntervalDescriptors.mottle_colour} value={colour} setValue={setColour} label="Colour" />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <DescriptorDropdown items={IntervalDescriptors.mottle_type} value={type} setValue={setType} label="Type" />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
          >
            Cancel
          </Button>
          <Button
            bsStyle="primary"
            style={{ marginLeft: '5px', width: '150px', height: '50px' }}
            disabled={!allowAdd()}
            onClick={addMottle}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

NewMottle.propTypes = {
  editIndex: PropTypes.number,
  setEditIndex: PropTypes.func.isRequired,
};

NewMottle.defaultProps = {
  editIndex: null,
};

export default NewMottle;
