import { useContext } from 'react';
import { SidePanelOpenContext } from '../Providers/sidePanelOpenProvider';

export default () => {
  const {
    getSidePanelOpen,
    toggleSidePanel,
  } = useContext(SidePanelOpenContext);
  return {
    getSidePanelOpen,
    toggleSidePanel,
  };
};
