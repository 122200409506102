import { useContext } from 'react';
import { LoadingContext } from '../Providers/loadingProvider';

export default () => {
  const {
    getLoading,
    changeLoadingState,
  } = useContext(LoadingContext);
  return {
    getLoading,
    changeLoadingState,
  };
};
