/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import BackupIcon from '@mui/icons-material/Backup';
import {
  Box,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import SubmissionService from '../../../logic/SubmissionService';
import LocalDbService from '../../../logic/LocalDbService';
import BoreholePagination from './BoreholePagination';
import useSiteForReviewProvider from '../../../hooks/useSiteForReviewProvider';
import useWebService from '../../../hooks/useWebService';
import * as gqlQuery from './queries';
import useDbCacheProvider from '../../../hooks/useDbCacheProvider';

import './CoCPage.css';

function CoCPage({
  toggleLabCoC,
  siteName,
  boreholes,
  initialize,
  updateSubmissionList,
}) {
  const { clearSiteForReview } = useSiteForReviewProvider();
  const { getGqlClient, getWebService, mutate } = useWebService();
  const gqlClient = getGqlClient();
  const { getDbCache } = useDbCacheProvider();

  const turnaroundTimes = ['Seven days', 'Two days', 'One day', 'Immediately'];
  const [provinces, setProvinces] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errorMessge, setErrorMessage] = useState('');
  const [labs, setLabs] = useState([]);
  const [tests, setTests] = useState(JSON.parse(localStorage
    .getItem('smaSampleTests')) || []);
  const [testsForSamples, setTestForSamples] = useState([]);
  const [province, setProvince] = useState('');
  const [turnAroundTime, setTurnAroundTime] = useState(turnaroundTimes[0]);
  const [invoiceName, setInvoiceName] = useState('');
  const [reportName, setReportName] = useState('');
  const [additionalReportName, setAdditionalReportName] = useState('');
  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [reportAddress, setReportAddress] = useState('');
  const [additionalReportAddress, setAdditionalReportAddress] = useState('');
  const [invoicePhone, setInvoicePhone] = useState('');
  const [reportPhone, setReportPhone] = useState('');
  const [additionalReportPhone, setAdditionalReportPhone] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [reportEmail, setReportEmail] = useState('');
  const [additionalReportEmail, setAdditionalReportEmail] = useState('');
  const [currentLab, setCurrentLab] = useState();

  // TODO - get these from the database
  const returnToAllBoreholeLogs = () => {
    toggleLabCoC();
    clearSiteForReview();
  };

  const setSampleTest = (testList, sampleLocationId, top, bottom) => {
    let list = '';
    // eslint-disable-next-line no-return-assign
    testList.forEach((test) => list = list === ''
      ? list.concat(`${test.value}`)
      : list.concat(`,${test.value}`));

    const matchedT4S = testsForSamples.findIndex((testsForSample) => (
      testsForSample.boreholeId === sampleLocationId
      && testsForSample.top === top
      && bottom === testsForSample.bottom
    ));
    if (matchedT4S === -1) {
      testsForSamples.push({
        testList: list, boreholeId: sampleLocationId, top, bottom,
      });
    } else {
      testsForSamples[matchedT4S] = {
        testList: list, boreholeId: sampleLocationId, top, bottom,
      };
    }
    setTestForSamples([...testsForSamples]);
  };

  async function getCOCPageData() {
    return gqlClient.query(
      gqlQuery.getLabPageData,
      {},
      { fetchPolicy: 'no-cache' },
    );
  }

  const submitBorehole = (borehole) => SubmissionService(
    // eslint-disable-next-line no-underscore-dangle
    borehole._id,
    getDbCache(),
    mutate,
    gqlClient,
  ).then((result) => {
    // eslint-disable-next-line no-param-reassign
    borehole.submitted = new Date().toISOString();
    // eslint-disable-next-line no-param-reassign
    borehole.id = result;
    LocalDbService.saveWithRev(borehole).then(() => {
      updateSubmissionList();
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      setErrorMessage('Error saving locally. Do not retry submitting this report.');
    });
    // eslint-disable-next-line no-underscore-dangle
    return { id: result, _id: borehole._id };
  });

  const submitCoC = async () => {
    const boreholeIds = boreholes.map((borehole) => {
      if (borehole.submitted !== undefined && borehole.sampleLocationId !== '') {
        return borehole.sampleLocationId;
      }
      return submitBorehole(borehole);
    });

    Promise.all(boreholeIds).then(async (results) => {
      const ids = results.map((result) => result.id || result);
      gqlClient.query(
        gqlQuery.getSubmissionAndDataByIdList,
        { ids },
        { fetchPolicy: 'no-cache' },
      ).then((submissions) => {
        const testsAndIds = testsForSamples.map((t4s) => {
          let match = null;
          submissions.data.getSubmissionAndDataByLocationIdList.forEach((sub) => {
            const matchedSample = sub.fieldSamples.find((sample) => ((
              sample.sampleLocationId === t4s.boreholeId
                || sub.sampleLocation.sampleLocName === t4s.boreholeId)
                && parseFloat(t4s.top) === sample.depth_top));
            if (matchedSample) match = { id: matchedSample.id, testList: t4s.testList };
          });
          return match;
        });
        gqlClient.mutate(
          gqlQuery.createCocLab,
          {
            smaSamples: testsAndIds.map((pair) => pair.id),
            smaSampleTests: testsAndIds.map((pair) => pair.testList),
            invoiceName,
            reportName,
            additionalReportName,
            invoiceAddress,
            reportAddress,
            additionalReportAddress,
            invoicePhone,
            reportPhone,
            additionalReportPhone,
            invoiceEmail,
            reportEmail,
            additionalReportEmail,
          },
        ).then((response) => {
          if (response.data) {
            setErrorMessage('');
            returnToAllBoreholeLogs();
            return;
          }
          setErrorMessage('FAILED TO SUBMIT');
          window.scrollTo(0, 0);
        });
      });
    });
  };

  const fetchDataAndPopulate = () => {
    getCOCPageData().then((result) => {
      if (provinces.length === 0 && result.data.getProvinces.length > 0) {
        setProvinces(result.data.getProvinces);
      }
      const labCompanies = [];

      result.data.getSampleTests.forEach((sampleTest) => {
        if (!labCompanies.includes(sampleTest.company)) {
          labCompanies.push(sampleTest.company);
        }
      });
      setLabs(labCompanies);
      if (result.data.getSampleTests.length > 0) {
        setTests(result.data.getSampleTests);
      }
    });
  };

  useEffect(() => {
    fetchDataAndPopulate();
  }, []);

  return (
    <div className="CocPage">
      {errorMessge && (
        <div className="coclabErrorMessage">
          {errorMessge}
        </div>
      )}
      <Grid>
        <Row>
          <Col xs={6} span={6}>
            <Button
              onClick={returnToAllBoreholeLogs}
              startIcon={<ArrowBackIcon />}
              className="backButton"
            >
              Go Back to All Borehole Logs
            </Button>
          </Col>
        </Row>
        <Box style={{ padding: '10px', margin: '20px 0' }}>
          <Row>
            <Col md={5}>
              <h3 className="LabChainOfCustodyText">Lab Chain of Custody</h3>
              <h3 className="SiteNameHeaderText">{siteName}</h3>
            </Col>
            <Col md={4}>
              <div style={{ margin: '10px 10px 0 10px' }}>
                <Tooltip title={`NOTE: Submitting a coc will submit all borehole logs associated with ${siteName}`}>
                  <Button
                    onClick={submitCoC}
                    endIcon={<BackupIcon />}
                    variant="contained"
                    size="large"
                    disabled={!testsForSamples.length
                      || !testsForSamples.find((t4s) => t4s.testList.length > 0)}
                  >
                    Submit CoC
                  </Button>
                </Tooltip>
              </div>
            </Col>
            <Col md={2} style={{ margin: '10px 10px 0 10px' }} />
          </Row>
          <Box>
            <Row style={{ marginTop: '10px' }}>
              <Col md={3}>
                <h4 className="LabMetadataText">Lab metadata</h4>
              </Col>
              <Col md={3}>
                <InputLabel id="province-select-label">Province</InputLabel>
                <Select
                  className="selectFullWidth"
                  labelId="province-select-label"
                  id="province-select"
                  value={province}
                  onChange={(event) => setProvince(event.target.value)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    provinces.map((provinceFromProvices) => (
                      <MenuItem
                        key={provinceFromProvices.id}
                        value={provinceFromProvices.id}
                      >
                        {provinceFromProvices.provinceName}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Col>
              <Col md={3}>
                <InputLabel id="lab-select-label">Lab</InputLabel>
                <Select
                  className="selectFullWidth"
                  id="lab-select"
                  labelId="lab-select-label"
                  onChange={(event) => setCurrentLab(event.target.value)}
                  value={currentLab}
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    labs.map((lab) => (
                      <MenuItem key={lab} value={lab}>{lab}</MenuItem>
                    ))
                  }
                </Select>
              </Col>
              <Col md={3}>
                <InputLabel id="turnaround-select-label">Turnaround Time</InputLabel>
                <Select
                  className="selectFullWidth"
                  value={turnAroundTime}
                  label="Turnaround Time"
                  id="turnaround-select"
                  onChange={(event) => setTurnAroundTime(event.target.value)}
                  labelId="province-select-label"
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    turnaroundTimes.map((time) => (
                      <MenuItem key={time} value={time}>{time}</MenuItem>
                    ))
                  }
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="cocLabInput"
                  label="Invoice Name"
                  classes="cocLabInput"
                  value={invoiceName}
                  onChange={(event) => setInvoiceName(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportName"
                  label="Report Name"
                  classes="cocLabInput"
                  value={reportName}
                  onChange={(event) => setReportName(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportName"
                  label="Additional Report Name"
                  classes="cocLabInput"
                  value={additionalReportName}
                  onChange={(event) => setAdditionalReportName(event.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoiceAddress"
                  label="Invoice Address"
                  classes="cocLabInput"
                  value={invoiceAddress}
                  onChange={(event) => setInvoiceAddress(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportAddress"
                  label="Report Address"
                  classes="cocLabInput"
                  value={reportAddress}
                  onChange={(event) => setReportAddress(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportAddress"
                  label="Additional Report Address"
                  classes="cocLabInput"
                  value={additionalReportAddress}
                  onChange={(event) => setAdditionalReportAddress(event.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoicePhone"
                  label="Invoice Phone"
                  classes="cocLabInput"
                  value={invoicePhone}
                  onChange={(event) => setInvoicePhone(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportPhone"
                  label="Report Phone"
                  classes="cocLabInput"
                  value={reportPhone}
                  onChange={(event) => setReportPhone(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportPhone"
                  label="Additional Report Phone"
                  classes="cocLabInput"
                  value={additionalReportPhone}
                  onChange={(event) => setAdditionalReportPhone(event.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoiceEmail"
                  label="Invoice Email"
                  classes="cocLabInput"
                  value={invoiceEmail}
                  onChange={(event) => setInvoiceEmail(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportEmail"
                  label="Report Email"
                  classes="cocLabInput"
                  value={reportEmail}
                  onChange={(event) => setReportEmail(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportEmail"
                  label="Additional Report Email"
                  classes="cocLabInput"
                  value={additionalReportEmail}
                  onChange={(event) => setAdditionalReportEmail(event.target.value)}
                />
              </Col>
            </Row>
          </Box>
          <BoreholePagination
            className="boreholePagination"
            boreholes={boreholes}
            siteName={siteName}
            initialize={initialize}
            tests={tests.filter((test) => test.company === currentLab)}
            setSampleTest={setSampleTest}
            testsForSamples={testsForSamples}
          />
        </Box>
      </Grid>
    </div>
  );
}

CoCPage.propTypes = {
  toggleLabCoC: PropTypes.func.isRequired,
  updateSubmissionList: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
  boreholes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CoCPage;
