import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LocationContext = createContext({
  getLocation: () => 'context not defined',
  locationUpdateLatLon: () => 'context not defined',
  locationUpdateSatellintes: () => 'context not defined',
});

function LocationProvider({ children }) {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
    count: 0,
    usedInFix: 0,
  });

  const getLocation = () => location;

  const locationUpdateLatLon = (lat, lon, accuracy) => setLocation({
    ...location,
    latitude: lat,
    longitude: lon,
    accuracy,
  });

  const locationUpdateSatellintes = (count, usedInFix) => setLocation({
    ...location,
    count,
    usedInFix,
  });

  return (
    <LocationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getLocation,
        locationUpdateLatLon,
        locationUpdateSatellintes,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocationProvider;
