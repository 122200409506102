import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import NewAtComment from '../at-comments/NewAtComment';
import EditAtComment from '../at-comments/EditAtComment';
import IntervalListComponent from '../intervals/IntervalListComponent';
import AtCommentListComponent from '../at-comments/AtCommentListComponent';
import './intervalControls.css';

function IntervalControls({
  intervalList,
  classSchema,
  deleteAllIntervals,
  enableIntervalEditMode,
  duplicateInterval,
  editMode,
  editIndex,
  onDeleteInterval,
  onDeleteAtComments,
  atCommentList,
  deleteAllComments,
  onNewIntervalForm,
  showForm,
  genHexString,
}) {
  const [editedAtComment, setEditedAtComment] = useState(null);

  return (
    <div className="intervalControls">
      <Box>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <span>Intervals</span>
          <Button
            bsStyle="danger"
            disabled={!intervalList || intervalList.length < 1}
            onClick={deleteAllIntervals}
            className="deleteAllIntervalsButton"
            style={{ float: 'right' }}
          >
            Delete All
          </Button>
        </div>
        <IntervalListComponent
          schemaType={classSchema}
          intervalList={intervalList || []}
          deleteFunc={onDeleteInterval}
          editFunc={enableIntervalEditMode}
          duplicateFunc={duplicateInterval}
          editMode={editMode}
          editIndex={editIndex}
          genHexString={genHexString}
        />
        <button
          disabled={showForm}
          className="addNewIntervalButton"
          onClick={onNewIntervalForm}
          type="button"
        >
          Add New Interval
        </button>
      </Box>
      <Box>
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <span>@ Comments</span>
          <Button
            bsStyle="danger"
            disabled={!atCommentList || atCommentList.length === 0}
            onClick={deleteAllComments}
            style={{ float: 'right' }}
            className="deleteAllAtCommentsButton"
          >
            Delete All
          </Button>
        </Box>
        <AtCommentListComponent
          atCommentList={atCommentList || []}
          deleteFunc={onDeleteAtComments}
          onEdit={setEditedAtComment}
        />
        <Box>
          {editedAtComment !== null && (
            <EditAtComment
              currentAtComment={editedAtComment}
              setEditedAtComment={setEditedAtComment}
              atCommentList={atCommentList}
            />
          )}
          <NewAtComment />
        </Box>
      </Box>
    </div>
  );
}

IntervalControls.propTypes = {
  intervalList: PropTypes.arrayOf(PropTypes.shape({})),
  classSchema: PropTypes.string.isRequired,
  deleteAllIntervals: PropTypes.func.isRequired,
  enableIntervalEditMode: PropTypes.func.isRequired,
  duplicateInterval: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  editIndex: PropTypes.func.isRequired,
  onDeleteInterval: PropTypes.func.isRequired,
  onDeleteAtComments: PropTypes.func.isRequired,
  atCommentList: PropTypes.arrayOf(PropTypes.shape({})),
  deleteAllComments: PropTypes.func.isRequired,
  onNewIntervalForm: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
  genHexString: PropTypes.func.isRequired,
};

IntervalControls.defaultProps = {
  intervalList: [],
  atCommentList: [],
};

export default IntervalControls;
