import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SiteForReviewContext = createContext({
  getSiteForReview: () => 'context not defined',
  setSiteForReview: () => 'context not defined',
  clearSiteForReview: () => 'context not defined',
});

function siteForReviewProvider({ children }) {
  const [siteForReview, setSFR] = useState({ id: '', name: '' });

  const getSiteForReview = () => siteForReview;

  const setSiteForReview = (newSite) => setSFR(newSite);

  const clearSiteForReview = () => setSFR({ id: '', name: '' });

  return (
    <SiteForReviewContext.Provider value={{
      getSiteForReview,
      setSiteForReview,
      clearSiteForReview,
    }}
    >
      {children}
    </SiteForReviewContext.Provider>
  );
}

siteForReviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default siteForReviewProvider;
