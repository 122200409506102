/**
 * Submits Sample Location with Samples, Intervals, etc.
 * The query hooks these up to their relevant objects in the API.
 */
import {
  getSiteQuery,
  sampleLocationQuery,
  updateSampleLocationQuery,
  updateSmaSubmissionQuery,
  smaSubmissionQuery,
  intervalQuery,
  updateIntervalQuery,
  createSiteQuery,
  atCommentQuery,
  updateAtCommentQuery,
  fieldSampleQuery,
  deleteFieldSamplesNotInList,
  deleteAtCommentsNotInList,
  sampleQuery,
  updateSampleQuery,
  updateFieldSampleQuery,
  createApecQuery,
  getApecQuery,
} from './queries';

import submitIntervals from './submitIntervals';
import submitFieldSamples from './submitFieldSamples';

const formKey = 'field';

export const serializeFromState = (formState) => {
  // const formVals = getFormValues(this.formKey)(store.getState());

  if (formState.classSchema === 'schema_cssc') {
    // TODO: Explicitly specify which fields we're grabbing.
    return {
      ...formState,
      logger: formState.logger,
      name: formState.borehole,
      submittable_type: formKey,
      date_time: new Date().toJSON(),
      sample_loc_datetime: new Date(formState.sample_loc_datetime).toJSON(),
      units: formState.units,
      clientid: formState.client,
      projectid: formState.project,
      siteid: formState.site || '',
      workcontext_comment: formState.workcontext_comment,
      user_site: formState.userSite || '',
      manual: formState.userSite !== '',
      apecid: formState.apec || '7b08a054-0017-4ead-b887-92d040a702d5',
      apecname: formState.apecName || 'Unknown APEC',
      user_apec: formState.userApec || '',
      manual_apec: formState.userApec !== '',
      class_schema: formState.classSchema,
      latitude: parseFloat(formState.latitude),
      // NOTE: We default submitted longitude values to negative as long as we're in NA.
      // If this stops being true we may need to revise.
      longitude: parseFloat(formState.longitude > 0
        ? -formState.longitude : formState.longitude),
      borehole_comment: formState.boreholeComment,
      drilling_method: formState.drillingMethod,
      drilling_name: formState.drillingName,
      impacted: formState.impacted,
      weather_description: formState.weatherDescription,
      wind_direction: formState.windDirection,
      wind_speed: formState.wind_speed,
      precipitation: formState.precipitation,
      temperature: formState.temperature,
      interval_depth_units: formState.intervalDepthUnits,
      intervals: formState.intervals.map((interval) => ({
        id: interval.id,
        invervalUnits: formState.intervalUnits,
        intervalTop: parseFloat(interval.intervalTop),
        intervalBottom: parseFloat(interval.intervalBottom),
        classSchema: formState.classSchema,

        // Shared
        plasticity: interval.plasticity,
        colour: interval.colour,
        colour1: interval.colour1,
        colour2: interval.colour2,
        colour3: interval.colour3,
        moisture: interval.moisture,
        consistence: interval.consistence,
        intervalComment: interval.intervalComment,

        // CSSC
        fragmentShape: interval.fragmentShape,
        fragmentModifier: interval.fragmentmodifier,
        fragmentFragment: interval.fragmentFragment,
        fragmentAdjective: interval.fragmentAdjective,
        fragmentTexture: interval.fragmentTexture,
        soilHorizon: interval.soilHorizon,
        structure: interval.structure,
        mottleAbundance: interval.mottleAbundance,
        mottleSize: interval.mottleSize,
        mottleColour: interval.mottleColour,
        mottleContrast: interval.mottleContrast,
        mottleType: interval.mottleType,
        deposition: interval.deposition,

        mottles: interval.mottles ? interval.mottles.map((mottle) => ({
          id: mottle.id,
          abundance: mottle.abundance,
          size: mottle.size,
          colour: mottle.colour,
          contrast: mottle.contrast,
          type: mottle.type,
        })) : [],
      })) || [],
      at_comments: formState.atComments || [],
      samples: formState.samples || [],
      fieldMeasurements: formState.fieldMeasurements || [],
      sample_collection: formState.sampleCollection || [],
    };
  }

  if (formState.class_schema === 'schema_uscs') {
    // TODO: Explicitly specify which fields we're grabbing.
    return {
      ...formState,
      logger: formState.logger,
      name: formState.borehole,
      submittable_type: formKey,
      date_time: new Date().toJSON(),
      sample_loc_datetime: new Date(formState.sampleLocDatetime).toJSON(),
      units: formState.units,
      clientid: formState.client,
      projectid: formState.project,
      siteid: formState.site || '',
      sitename: formState.siteName || '',
      workcontext_comment: formState.workContextComment,
      user_site: formState.userSite || '',
      manual: formState.userSite !== '',
      apecid: formState.apec || '7b08a054-0017-4ead-b887-92d040a702d5',
      apecname: formState.apecName || 'Unknown APEC',
      user_apec: formState.userApec || '',
      manual_apec: formState.userApec !== '',
      class_schema: formState.classSchema,
      latitude: parseFloat(formState.latitude),
      // NOTE: We default submitted longitude values to negative as long as we're in NA.
      // If this stops being true we may need to revise.
      longitude: parseFloat(formState.longitude > 0
        ? -formState.longitude : formState.longitude),
      borehole_comment: formState.boreholeComment,
      drilling_method: formState.drillingMethod,
      drilling_name: formState.drillingName,
      weather_description: formState.weatherDescription,
      wind_direction: formState.windDirection,
      wind_speed: formState.windSpeed,
      precipitation: formState.precipitation,
      temperature: formState.temperature,
      interval_depth_units: formState.intervalDepthUnits,
      intervals: formState.intervals.map((interval) => ({
        id: interval.id,
        inverval_units: formState.intervalUnits,
        intervalTop: parseFloat(interval.intervalTop),
        intervalBottom: parseFloat(interval.intervalBottom),
        class_schema: formState.classSchema,

        // Shared
        plasticity: interval.plasticity,
        colour: interval.colour,
        colour1: interval.colour1,
        colour2: interval.colour2,
        colour3: interval.colour3,
        moisture: interval.moisture,
        consistence: interval.consistence,
        intervalComment: interval.intervalComment,

        // USCS
        primary_constituent: interval.primaryConstituent,
        primary_size: interval.primarySizeDescriptor,
        secondary_constituent: interval.secondaryConstituent,
        secondary_proportion: interval.secondaryProportion,
        tertiary_constituent: interval.tertiaryConstituent,
        tertiary_proportion: interval.tertiaryProportion,
        grading: interval.grading,
        angularity: interval.angularity,
        odour: interval.odour,
        primary_soil: interval.primarySoil,
        observation: interval.observation,
        genetic_terms: interval.geneticTerms,

      })) || [],
      at_comments: formState.atComments || [],
      samples: formState.samples || [],
      fieldMeasurements: formState.fieldMeasurements || [],
      sample_collection: formState.sampleCollection || [],
    };
  }

  if (formState.class_schema === 'schema_custom1') {
    // TODO: Explicitly specify which fields we're grabbing.
    return {
      ...formState,
      logger: formState.logger,
      name: formState.borehole,
      submittable_type: formKey,
      date_time: new Date().toJSON(),
      sample_loc_datetime: new Date(formState.sample_loc_datetime).toJSON(),
      units: formState.units,
      clientid: formState.client,
      projectid: formState.project,
      siteid: formState.site || '',
      sitename: formState.siteName || '',
      workcontext_comment: formState.workcontext_comment,
      user_site: formState.userSite || '',
      manual: formState.userSite !== '',
      apecid: formState.apec || '7b08a054-0017-4ead-b887-92d040a702d5',
      apecname: formState.apecName || 'Unknown APEC',
      user_apec: formState.userApec || '',
      manual_apec: formState.userApec !== '',
      class_schema: formState.class_schema,
      latitude: parseFloat(formState.latitude),
      // NOTE: We default submitted longitude values to negative as long as we're in NA.
      // If this stops being true we may need to revise.
      longitude: parseFloat(formState.longitude > 0
        ? -formState.longitude : formState.longitude),
      borehole_comment: formState.borehole_comment,
      drilling_method: formState.drilling_method,
      drilling_name: formState.drilling_name,
      weather_description: formState.weather_description,
      wind_direction: formState.wind_direction,
      wind_speed: formState.wind_speed,
      precipitation: formState.precipitation,
      temperature: formState.temperature,
      interval_depth_units: formState.interval_depth_units,
      intervals: formState.intervals.map((interval) => ({
        id: interval.id,
        inverval_units: formState.interval_units,
        intervalTop: parseFloat(interval.intervalTop),
        intervalBottom: parseFloat(interval.intervalBottom),
        class_schema: formState.class_schema,

        // Shared
        plasticity: interval.plasticity,
        colour: interval.colour,
        colour1: interval.colour1,
        colour2: interval.colour2,
        colour3: interval.colour3,
        moisture: interval.moisture,
        consistence: interval.consistence,
        intervalComment: interval.intervalComment,

        // Custom1
        texture: interval.texture,
        structure: interval.structure,
        mottle_abundance: interval.mottle_abundance,
        mottle_size: interval.mottle_size,
        mottle_select: interval.mottle_select,
        mottle_contrast: interval.mottle_contrast,
        soil_fractures: interval.soil_fractures,
        soil_fragments: interval.soil_fragments,
        soil_fragments_modifier: interval.soil_fragments_modifier,
        soil_disturbances: interval.soil_disturbances,

      })) || [],
      at_comments: formState.atComments || [],
      samples: formState.samples || [],
      fieldMeasurements: formState.fieldMeasurements || [],
      sample_collection: formState.sample_collection || [],
    };
  }
  return null;
};

export const submit = async (mutate, dbCache, jsonDataIn, gqlClient) => {
  const jsonData = { ...jsonDataIn };
  // eslint-disable-next-line camelcase
  const manual_apec = ''; // WHERE DID THIS COME FROM????

  // Function to get the id from apec
  async function getApecId(data) {
    if (data.user_apec === '' && data.apecid === '') {
      return null;
    }
    if (data.user_apec !== '') {
      const apecs = await gqlClient.query(getApecQuery, { name: data.user_apec }, { fetchPolicy: 'no-cache' });
      if (apecs.data.getApec === null) {
        const newApec = await mutate(createApecQuery, { name: data.user_apec, manual: true });
        return newApec.data.createApec;
      }
      return apecs.data.getApec.id;
    }
    return data.apecid;
  }

  const getSiteId = async (data) => {
    if (data.user_site !== '') {
      let sites = null;
      sites = await gqlClient.query(getSiteQuery, { id: data.user_site }, { fetchPolicy: 'no-cache' });
      if (!sites?.data?.getSite) {
        sites = await gqlClient.query(getSiteQuery, { name: data.user_site }, { fetchPolicy: 'no-cache' });
      }
      // this site does not exist in our system so we must create it and link it to the project
      if (sites.data.getSite === null) {
      // get site from cache in db
        // const manualSite = dbCache.manualSites.data.find((x) => x.name === data.siteName);
        // eslint-disable-next-line no-undef
        const manualSite = JSON.parse(localStorage.getItem('manualSites'))
          .find((x) => x.name === data.siteName);
        // make a new site
        const newSite = await mutate(
          createSiteQuery,
          {
            projectId: data.projectid,
            name: manualSite.name,
            manual: true,
            latitude: parseFloat(data.latitude),
            longitude: parseFloat(data.longitude > 0 ? -data.longitude : data.longitude),
            wellLicense: '',
            uwi: manualSite.uwi,
            comments: '',
          },
        );
        return newSite.data.createSite;
      }
      return sites.data.getSite.id;
    }
    return data.siteid;
  };

  if (!jsonData.fieldMeasurements) jsonData.fieldMeasurements = [];
  if (!jsonData.at_comments) jsonData.at_comments = [];
  if (!jsonData.samples) jsonData.samples = [];
  if (!jsonData.intervals) jsonData.intervals = [];
  if (!jsonData.sample_collection) jsonData.sample_collection = [];
  const { isSubmissionEdit } = jsonData;

  const gpsMetadata = JSON.stringify({
    accuracy: jsonData.gpsAccuracy,
    usedInFix: jsonData.gpsUsedInFix,
    count: jsonData.gpsCount,
    source: jsonData.gpsSource,
  });

  const variables = {
    logger: jsonData.logger,
    name: jsonData.name,
    gpsMetadata,
    latitude: parseFloat(jsonData.latitude),
    longitude: parseFloat(jsonData.longitude > 0 ? -jsonData.longitude : jsonData.longitude),
    dateTime: jsonData.date_time,
    sample_loc_datetime: jsonData.sample_loc_datetime,
    drillingName: jsonData.drillingName,
    drillingMethod: jsonData.drillingMethod,
    weatherDescription: jsonData.weatherDescription || undefined,
    windDirection: jsonData.wind_direction || undefined,
    impacted: jsonData.impacted === 'on',
    windSpeed: jsonData.windSpeed || undefined,
    precipitation: jsonData.precipitation || undefined,
    temperature: jsonData.temperature
      ? jsonData.temperature.toString()
      : null,
    user_site: jsonData.user_site,
    workContextComment: jsonData.workContextComment,
    boreholeComment: jsonData.boreholeComment,
    manual: jsonData.manual,
    apecid: jsonData.apecid,
    apecname: jsonData.apec_name,
    user_apec: jsonData.user_apec,
    // eslint-disable-next-line camelcase
    manual_apec,
  };

  const result = isSubmissionEdit
    ? await mutate(updateSampleLocationQuery, { ...variables, id: jsonData.sampleLocationId })
    : await mutate(sampleLocationQuery, variables);

  const locationId = isSubmissionEdit
    ? result.data.updateSampleLocation
    : result.data.createSampleLocation;

  const siteId = await getSiteId(jsonData);
  const apecId = await getApecId(jsonData);

  const smaSubVar = {
    sampleLocationId: locationId,
    clientId: jsonData.clientid,
    projectId: jsonData.projectid,
    siteId,
    apecId,
  };

  const newSmaSubmission = isSubmissionEdit
    ? await mutate(
      updateSmaSubmissionQuery,
      { ...smaSubVar, id: jsonData.submissionId },
    )
    : await mutate(smaSubmissionQuery, smaSubVar);

  const smaSubId = isSubmissionEdit
    ? newSmaSubmission.data.updateSmaSubmission
    : newSmaSubmission.data.createSmaSubmission;

  await submitIntervals({
    intervals: jsonData.intervals,
    isSubmissionEdit,
    mutate,
    intervalQuery,
    updateIntervalQuery,
    smaSubId,
  });

  // Submit @-Comments
  // note that at_comments represent what was loaded and atComments is
  // what is currently in the data
  const atCommentIds = [];
  jsonData.atComments.forEach(async (atComment) => {
    const commentVariables = {
      depth: parseFloat(atComment.depth),
      comment: atComment.comment,
      sampleLocationId: locationId,
    };

    const atCommentResponse = jsonData.isSubmissionEdit && atComment.id
      ? await mutate(updateAtCommentQuery, { ...commentVariables, id: atComment.id })
      : await mutate(atCommentQuery, commentVariables);
    if (jsonData.isSubmissionEdit && atComment.id) {
      atCommentIds.push(atCommentResponse.data.updateAtComment);
    } else {
      atCommentIds.push(atCommentResponse.data.createAtComment);
    }
  });

  await mutate(
    deleteAtCommentsNotInList,
    { ids: atCommentIds, sampleLocationId: locationId },
  );

  // Submit Samples.
  jsonData.samples.forEach(async (sample) => {
    const sampleVariables = {
      sampleLocationId: locationId,
      labParameterId: sample.labParameterIds[0],
      sampleId: sample.sample_id,
      dateTime: sample.dateTime,
      depth: parseFloat(sample.depth),
      hold: sample.hold,
      filtered: sample.filtered,
      preserved: sample.preserved,
    };
    // Add the sample.
    if (jsonData.isSubmissionEdit && sample.id) {
      await mutate(updateSampleQuery, { ...sampleVariables, id: sample.id });
    } else {
      await mutate(sampleQuery, sampleVariables);
    }
  });
  submitFieldSamples({
    sampleLocations: jsonData.sample_collection,
    locationId,
    mutate,
    isSubmissionEdit,
    deleteFieldSamplesNotInList,
    updateFieldSampleQuery,
    fieldSampleQuery,
  });

  return locationId;
};
