/* eslint-disable no-undef */
import React from 'react';
import { Button } from 'react-bootstrap';
import RefreshIcon from '@mui/icons-material/Refresh';
import OnlineNotifier from './common/OnlineNotifier';
import useGetDropDownOptions from '../hooks/useGetDropDownOptions';
import LoadingThrobber from './common/LoadingSpinner';
import Logout from './Logout';

import useAuthProvider from '../hooks/useAuthProvider';
import useDbCacheProvider from '../hooks/useDbCacheProvider';
import useLoadingProvider from '../hooks/useLoadingProvider';

import pjson from '../../package.json';
// import useOfflineServiceProvider from '../hooks/useOfflineServiceProvider';

function SidePanel() {
  const {
    getClientsOptions,
    getProjectsOptions,
    getSitesOptions,
    getAPECsOptions,
  } = useGetDropDownOptions();
  const { getLoading } = useLoadingProvider();
  const loading = getLoading();
  const { getDbCache, changeSyncInfo } = useDbCacheProvider();
  const dbCache = getDbCache();
  const { getUserInfo } = useAuthProvider();
  const auth = getUserInfo();
  // const { cacheAll } = useOfflineServiceProvider();

  const pullFunc = () => {
    localStorage.setItem('smaLastSync', new Date().toLocaleString());
    getClientsOptions(navigator);
    getProjectsOptions(navigator);
    getSitesOptions(navigator);
    getAPECsOptions(navigator);
    changeSyncInfo({ lastSync: new Date().toLocaleString() });
  };
  const { lastSync } = dbCache;
  const pulling = loading;

  return (
    <div className="SidePanel">
      <h3>Settings</h3>
      <OnlineNotifier />
      <hr />
      <h4>Current User</h4>
      {navigator.onLine
        ? (
          <p className="SidePanel-Subtitle">
            <Logout />
          </p>
        )
        : <p className="SidePanel-Subtitle">You are offline</p>}

      <hr />

      {/* Database Sync Status */}
      <div className="SidePanel-RightSide">
        {pulling
          ? <LoadingThrobber />
          : (
            <Button
              onClick={pullFunc}
              disabled={pulling || !navigator.onLine || !auth?.authenticated}
            >
              <RefreshIcon />
            </Button>
          )}
      </div>
      <h4>Remote Database</h4>
      <p style={{ color: (lastSync === 'N/A' ? 'red' : 'black') }} className="SidePanel-Subtitle">
        {`Last Sync: ${new Date(lastSync).toLocaleString()}`}
      </p>
      <hr />
      <p className="SidePanel-Subtitle">
        {`Sample Management App ${pjson.version}`}
      </p>
    </div>
  );
}

export default SidePanel;
