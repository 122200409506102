import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoadingContext = createContext({
  getLoading: () => 'context not defined',
  changeLoadingState: () => 'context not defined',
});

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const getLoading = () => loading;
  const changeLoadingState = (loadingFlag) => setLoading(loadingFlag);

  return (
    <LoadingContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getLoading,
        changeLoadingState,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadingProvider;
