/**
 * Offline First Caching Service.
 *
 * All this does is periodically update the Redux state tree with up-to-date
 * information about projects and clients and sites and parameters and such.
 */
import React, {
  useState,
  createContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import WebService from '../logic/WebService';
import LocalDbService from '../logic/LocalDbService';
import {
  sitesMetadataQuery,
  getLabParamsQuery,
  getContainerTypesQuery,
  getFieldParamsQuery,
  getApecsByQuery,
} from '../logic/queries';
import useDbCacheProvider from '../hooks/useDbCacheProvider';

export const OfflineServiceContext = createContext({
  cache: () => 'context not defined',
  cacheUserSites: () => 'context not defined',
  cacheSitesAndProjects: () => 'context not defined',
  cacheAll: () => 'context not defined',
  cacheAllThrottled: () => 'context not defined',
  stopSyncInterval: () => 'context not defined',
  startSyncInterval: () => 'context not defined',
  saveLoginInformation: () => 'context not defined',
  saveOrUpdateSubmittable: () => 'context not defined',
  getValue: () => 'context not defined',
  getUserSites: () => 'context not defined',
});

function OfflineServiceProvider({ children }) {
  const intervalRef = useRef(null);
  const [localWebservice] = useState(LocalDbService);
  const [webService] = useState(WebService);

  useEffect(() => {
    localWebservice.init();
  }, []);

  const queries = {
    labParams: getLabParamsQuery,
    containerTypes: getContainerTypesQuery,
    fieldParams: getFieldParamsQuery,
    apecs: getApecsByQuery,
  };
  const { getDbCache, changeSyncInfo, cacheOffline } = useDbCacheProvider();

  const getValue = async (key) => {
    try {
      const keyValue = await localWebservice.get(key);
      return keyValue;
    } catch (err) {
      return [];
    }
  };

  const saveOrUpdateSubmittable = (serialized, isEdit) => {
    if (isEdit) {
      return localWebservice.editSubmittable(serialized);
    }
    return localWebservice.saveSubmittable(serialized);
  };

  const saveLoginInformation = (userInfo) => {
    localWebservice.saveLoginInformation(userInfo);
  };

  // eslint-disable-next-line class-methods-use-this
  const cacheSitesAndProjects = async () => {
    // cacheOffline({ key: 'clients', loadingState: true });
    const result = await webService.query(sitesMetadataQuery, {}, { fetchPolicy: 'no-cache' });
    // Transform into a nested structure.
    result.data.getClients.forEach((client) => {
      // eslint-disable-next-line no-param-reassign
      client.projects = [];
    });
    result.data.getProjects.forEach((project) => {
      if (!project.clientId) return; // Some projects don't have clients????? Ignore these.
      const matchingSites = result.data.getSites.filter((site) => site.projectId === project.id);
      // eslint-disable-next-line no-param-reassign
      project.sites = matchingSites;
      const matchingClient = result.data.getClients.find((x) => x.id === project.clientId);
      if (matchingClient) {
        matchingClient.projects.push(project);
      } else {
        result.data.getClients.push({
          ...project.client,
          projects: [project],
        });
      }
    });
    // cacheOffline({
    //   key: 'clients',
    //   value: { clients: result.data.getClients },
    //   loadingState: false,
    // });
  };

  // Caches a single query.
  const cache = (queryKey) => {
    cacheOffline({ key: queryKey, loadingState: true });

    return webService
      .query(queries[queryKey], {}, { fetchPolicy: 'no-cache' })
      .then((result) => {
        cacheOffline({ key: queryKey, value: result.data, loadingState: false });
      }).catch((err) => {
      // eslint-disable-next-line no-console
        console.error(err);
        cacheOffline({ key: queryKey, loadingState: false });
      });
  };

  const cacheUserSites = (sites) => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('manualSites', JSON.stringify(sites));
    cacheOffline({ key: 'manualSites', value: sites, loadingState: false });
  };

  const getUserSites = () => {
    // eslint-disable-next-line no-undef
    const userSites = JSON.parse(localStorage.getItem('manualSites')) || [];
    return userSites;
  };

  // Caches all our queries.
  const cacheAll = async () => {
    await cacheSitesAndProjects();
    const keyList = Object.keys(queries);
    keyList.forEach(async (key) => {
      await cache(key);
    });
    changeSyncInfo({ loadingState: false, lastSync: new Date().toLocaleString() });
  };

  const stopSyncInterval = () => {
    clearInterval(intervalRef.current);
  };

  // Throttle so we're not doing things offline, and we check hourly for updates.
  const cacheAllThrottled = async () => {
    // eslint-disable-next-line no-undef
    if (navigator.onLine) {
      const lastSync = new Date(getDbCache().lastSync);

      // Check if our last sync was more than an hour ago.
      const updateExpire = new Date();
      updateExpire.setHours(updateExpire.getHours() - 1);

      // Check the last-update time from the API.
      let lastUpdate;
      try {
        // let result = await webService.query(lastUpdateQuery, {}, {fetchPolicy: 'no-cache'})
        // lastUpdate = new Date(result.data.lastUpdate)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      // If we haven't synced, OR we haven't synced in the last hour,
      // OR the server says there's been an update, run the sync.
      if (!lastSync || lastSync <= updateExpire || lastSync <= lastUpdate) {
        cacheAll().catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      }
    }
  };

  const startSyncInterval = async () => {
    if (intervalRef.current) return; // Don't set two sync invervals.
    try {
      await cacheAll(); // Always cache on application start.
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error while syncing', err);
    } finally {
      intervalRef.current = setInterval(cacheAllThrottled, 10);
    }
  };

  return (
    <OfflineServiceContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        cache,
        cacheUserSites,
        cacheSitesAndProjects,
        cacheAll,
        cacheAllThrottled,
        stopSyncInterval,
        startSyncInterval,
        saveLoginInformation,
        saveOrUpdateSubmittable,
        getValue,
        getUserSites,
      }}
    >
      {children}
    </OfflineServiceContext.Provider>
  );
}

OfflineServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OfflineServiceProvider;
