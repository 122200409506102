import { useContext } from 'react';
import { EditIntervalContext } from '../Providers/editIntervalProvider';

export default () => {
  const {
    getIsEditInterval,
    toggleIntervalEdit,
  } = useContext(EditIntervalContext);
  return {
    getIsEditInterval,
    toggleIntervalEdit,
  };
};
