/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import { getSiteAndData, updateSite, createSiteQuery } from './queries';
import useWebService from '../../hooks/useWebService';
import './SiteAdminForm.css';

function SiteAdminForm() {
  const [data, setData] = useState({});
  const [projectId, setProjectId] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');
  const [uwi, setUwi] = useState('');
  const [latitude, setLatitude] = useState(50);
  const [longitude, setLongitude] = useState(-114);
  const [wellLicense, setWellLicense] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isCreateMode, setIsCreateMode] = useState(true);
  const [underEditSite, setUnderEditSite] = useState({});

  const { getGqlClient } = useWebService();
  const webservice = getGqlClient();

  const fetchSiteCreationData = async () => {
    const sites = webservice.query(
      getSiteAndData,
      {},
      { fetchPolicy: 'no-cache' },
    );
    setData(sites);
  };

  const clearData = () => {
    setUnderEditSite({});
    setProjectId('');
    setIsActive(false);
    setIsManual(false);
    setName('');
    setUwi('');
    setComments('');
    setLatitude(50);
    setLongitude(-114);
    setWellLicense('');
    setIsCreateMode(false);
  };

  const onProjectChange = (event) => {
    if (event.target.value !== projectId) setProjectId(event.target.value);
  };

  const onActiveChange = () => {
    setIsActive(!isActive);
  };

  const onManualChange = () => {
    setIsManual(!isManual);
  };

  const onNameChange = (event) => {
    if (event.target.value !== name) setName(event.target.value);
  };

  const onWellLicenseChange = (event) => {
    if (event.target.value !== wellLicense) setWellLicense(event.target.value);
  };

  const onUwiChange = (event) => {
    if (event.target.value !== uwi) setUwi(event.target.value);
  };

  const onCommentsChange = (event) => {
    if (event.target.value !== comments) setComments(event.target.value);
  };

  const onLatChange = (event) => {
    if (event.target.value !== latitude && (!Number.isNaN(event.target.value) || event.target.value === '')) {
      setLatitude(event.target.value);
    }
  };

  const onLonChange = (event) => {
    if (event.target.value !== longitude && (!Number.isNaN(event.target.value) || event.target.value === '')) {
      setLongitude(event.target.value);
    }
  };

  const onSubmitForm = async () => {
    if (isCreateMode) {
      const response = await webservice.mutate(
        createSiteQuery,
        {
          projectId,
          siteStatus: isActive ? 'ACTIVE' : 'INACTIVE',
          isManual,
          name,
          uwi,
          comments,
          latitude,
          longitude,
          wellLicense,
        },
      );
      if (!response) {
        setErrorMessage('Failed to create site');
      } else {
        clearData();
      }
    } else {
      const response = await webservice.mutate(
        updateSite,
        {
          id: underEditSite.id,
          projectId,
          siteStatus: isActive ? 'ACTIVE' : 'INACTIVE',
          isManual,
          name,
          uwi,
          comments,
          latitude,
          longitude,
          wellLicense,
        },
      );
      if (!response) {
        setErrorMessage('Failed to update site');
      } else {
        clearData();
      }
    }
  };

  const onSetSiteActive = (event) => {
    if (event.target.value === 'create') {
      clearData();
    } else if (event.target.value !== underEditSite) {
      const site = data.data?.getSites.find((siteFromList) => event.target.value
        === siteFromList.id);
      setUnderEditSite(site);
      setProjectId(site.projectId);
      setIsActive(site.siteStatus);
      setIsManual(site.manual);
      setName(site.name);
      setUwi(site.uwi);
      setComments(site.comments);
      setLatitude(site.latitude);
      setLongitude(site.longitude);
      setWellLicense(site.wellLicense);
      setIsCreateMode(false);
    }
  };

  useEffect(() => {
    fetchSiteCreationData();
  }, []);

  return (
    <div className="SiteAdminForm">
      <div className="existingSiteArea">
        <span className="existingSiteLabel">Form use:</span>
        <select className="existingSiteList" onChange={onSetSiteActive}>
          <option value="create">Create</option>
          {data.data?.getSites?.map((site) => (
            <option key={site.id} value={site.id}>{site.name}</option>
          ))}
        </select>
      </div>
      <h3 className="Header">Site Form</h3>
      <table>
        <thead>
          <tr>
            <th className="labelCol">Label</th>
            <th className="inputCol">Input</th>
          </tr>
        </thead>
        <tbody>
          <tr className="projectArea">
            <td>
              <span className="projectLabel">Select Site Project: </span>
            </td>
            <td>
              <select className="ProjectSelect" value={projectId} onChange={onProjectChange}>
                <option value="NONE">Unselected</option>
                {data.data?.getProjects?.map((project) => (
                  <option key={project.id} value={project.id}>{project.name}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr className="siteAdminFormCheckboxArea">
            <td className="siteActiveArea">
              <span className="siteActiveLabel">Site Active:</span>
              <Checkbox checked={isActive} value={isActive} onChange={onActiveChange} />
            </td>
            <td className="ManualArea">
              <span className="siteManualLabel">Site Is Manual:</span>
              <Checkbox checked={isManual} value={isManual} onChange={onManualChange} />
            </td>
          </tr>
          <tr className="nameArea">
            <td>
              <span className="nameLabel">Site Name:</span>
            </td>
            <td>
              <input className="nameInput" value={name} onChange={onNameChange} />
            </td>
          </tr>
          <tr className="wellLicenseArea">
            <td>
              <span className="wellLicenseLabel">Well License:</span>
            </td>
            <td>
              <input className="wellLicenseInput" value={wellLicense} onChange={onWellLicenseChange} />
            </td>
          </tr>
          <tr className="comments">
            <td>
              <span className="commentsLabel">Comments</span>
            </td>
            <td>
              <input className="commentsInput" value={comments} onChange={onCommentsChange} />
            </td>
          </tr>
          <tr className="uwi">
            <td>
              <span className="uwiLabel">UWI</span>
            </td>
            <td>
              <input className="uwiInput" value={uwi} onChange={onUwiChange} />
            </td>
          </tr>
          <tr className="latLonArea">
            <td className="latArea">
              <span className="latLabel">Lat</span>
              <input className="latInput" value={latitude} onChange={onLatChange} />
            </td>
            <td className="lonArea">
              <span className="lonLabel">Lon</span>
              <input className="latInput" value={longitude} onChange={onLonChange} />
            </td>
          </tr>
        </tbody>
      </table>
      <button className="submitButtonSiteAdminForm" type="button" onClick={onSubmitForm}>
        {isCreateMode ? 'Create' : 'Save'}
      </button>
      <span>{errorMessage}</span>
    </div>
  );
}

export default SiteAdminForm;
