/* eslint-disable no-param-reassign */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const IntervalDescriptionFormContext = createContext({
  getForm: () => 'context not defined',
  changeForm: () => 'context not defined',
  getFormValue: () => 'context not defined',
  clearForm: () => 'context not defined',
  setIntervalForm: () => 'context not defined',
  validateDescriptionForm: () => 'context not defined',
  isInAnotherRange: () => 'context not defined',
  getErrors: () => 'context not defined',
});

function IntervalDescriptionForm({ children }) {
  const defaultForm = {
    texture: '',
    structure: '',
    colour: '',
    moisture: '',
    primarySizeDescriptors: '',
    secondaryProportionDescriptors: '',
    tertiaryProportionDescriptors: '',
    gradingDescriptors: '',
    angularityDescriptors: '',
    plasticityDescriptors: '',
    consistencyDescriptors: '',
    mottles: [],
    fragmentTexture: '',
    fragmentShape: '',
    soilHorizon: '',
    fragmentFragment: '',
    modifier: '',
    fragmentAdjective: '',
    plasticity: '',
    consistence: '',
    colour1: '',
    colour2: '',
    colour3: '',
    soilFragments: '',
    units: 'metric',
    intervalBottom: 0,
    intervalTop: 0,
    intervalComment: '',
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const clearForm = () => setForm(defaultForm);

  const isInAnotherRange = (
    intervals,
    intervalIndex,
    value,
    isEdit,
  ) => {
    if (!value) return false;
    const newValue = parseFloat(value);
    const faultyIntervals = intervals.find((interval, i) => (!isEdit || intervalIndex !== i)
      && (newValue > parseFloat(interval.intervalTop)
      && newValue < parseFloat(interval.intervalBottom)));
    return faultyIntervals;
  };

  const validateDepthForm = (newErrors) => {
    if (!form.intervalTop || form.intervalTop === undefined) {
      newErrors.intervalTop = 'Interval Top';
    }
    if (!form.intervalBottom || form.intervalBottom === undefined) {
      newErrors.intervalBottom = 'Interval Bottom';
    }
    setErrors(errors);
  };

  const validateDescriptionForm = () => {
    const newErrors = { ...errors };
    if ((!form.fragment_texture || form.fragmentTexture === undefined)
      && !form.intervalComment) {
      newErrors.fragmentTexture = 'Texture';
    }
    validateDepthForm(newErrors);
    setErrors(newErrors);
  };

  const getErrors = () => errors;

  const getForm = () => form;
  const changeForm = (field, newVal) => {
    const newForm = { ...form };
    newForm[field] = newVal;
    setForm(newForm);
  };
  const getFormValue = (field) => form[field];
  const setIntervalForm = (inForm) => setForm(inForm);

  return (
    <IntervalDescriptionFormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        clearForm,
        getForm,
        changeForm,
        getFormValue,
        setIntervalForm,
        validateDescriptionForm,
        isInAnotherRange,
        getErrors,
      }}
    >
      {children}
    </IntervalDescriptionFormContext.Provider>
  );
}

IntervalDescriptionForm.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntervalDescriptionForm;
