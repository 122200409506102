import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const HashContext = createContext({
  getHash: () => 'context not defined',
  hashUser: () => 'context not defined',
  clearHash: () => 'context not defined',
});

function HashProvider({ children }) {
  const [hash, setHash] = useState({ hash: '', user: {} });

  const getHash = () => hash;
  const hashUser = (newHash, body) => setHash({ hash: newHash, user: body });
  const clearHash = () => setHash({ hash: '', user: {} });

  return (
    <HashContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getHash,
        hashUser,
        clearHash,
      }}
    >
      {children}
    </HashContext.Provider>
  );
}

HashProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HashProvider;
