import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart, Area, Label, ReferenceLine, ReferenceDot, YAxis,
} from 'recharts';
import { FragmentTextureColors } from '../../../assets/static/models';

function ReviewIntervalChart({
  intervalList,
  maxDepth,
}) {
  // If we don't have data, don't render. Easy.
  if (!intervalList || intervalList.length < 1) return (<div />);

  /**
   * The data we have is constructed as a single object rather than array of them.
   * This is the only way that stacked bar charts are supported.
   * So, we're feeding the BarChart a list with one object and a bunch of index-like keys.
   */
  const data = {};
  const refs = [];
  const refsTop = [];
  intervalList.forEach((val, idx) => {
    data[idx] = val.value;
    refs.push(val.top);
    refs.push(val.bottom);
    refsTop.push({ val: ((val.bottom - val.top) / 2) + val.top, texture: val.texture });
  });

  const areas = intervalList.map((item, idx) => {
    // Get the color assigned to this texture.
    const fillColor = FragmentTextureColors[item.texture];

    const idkey = Math.random();
    // Create the area.
    return (
      <Area
        key={idkey}
        name={item.texture}
        dataKey={idx}
        fill={fillColor}
        stoke={fillColor}
        dot={false}
        baseLine={8}
        isAnimationActive={false}
      />
    );
  });

  const refLines = refs.map((val) => {
    const idkey = Math.random();
    const labelText = `${0 - val} m`;
    return (
      <ReferenceLine key={idkey} y={val} stokeWidth={1} stroke="red">
        <Label value={labelText} fill="red" fontSize={16} position="bottom" />
      </ReferenceLine>
    );
  });

  const refTextureLines = refsTop.map((val) => {
    const idkey = Math.random();
    return (
      <ReferenceDot key={idkey} x={0} y={val.val} r={0.1} strokeWidth={0} ifOverflow="extendDomain">
        <Label value={val.texture} fill="red" fontSize={12} position="right" />
      </ReferenceDot>
    );
  });

  return (
    <div>
      {/*   Have to duplicate data in order to fill the colour of the intervals */}
      <AreaChart
        width={80}
        height={1000}
        data={[data, data]}
        margin={{
          top: 20, right: 0, bottom: 20, left: 0,
        }}
      >
        {maxDepth !== 0
        && (
          <YAxis
            width={0}
            padding={{
              top: 0, bottom: 0, left: 0, right: 0,
            }}
            tick={false}
            domain={[maxDepth, 0]}
          />
        )}
        {areas}
        {refLines}
        {refTextureLines}
      </AreaChart>
    </div>
  );
}

ReviewIntervalChart.propTypes = {
  intervalList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxDepth: PropTypes.number.isRequired,
};

export default ReviewIntervalChart;
